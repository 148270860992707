import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  shop_total: localStorage.getItem("shop_total")
    ? JSON.parse(localStorage.getItem("shop_total"))
        .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
        .filter((data) => data.shop_form_status === 3)
    : [],
  approved_shops: localStorage.getItem("shop_total")
    ? JSON.parse(localStorage.getItem("shop_total"))
        .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
        .filter((data) => data.shopStatusText === "Approve")
    : [],
  not_approved_shops: localStorage.getItem("shop_total")
    ? JSON.parse(localStorage.getItem("shop_total"))
        .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
        .filter((data) => data.shopStatusText === "Not Approve")
    : [],
  rejected_shops: localStorage.getItem("shop_total")
    ? JSON.parse(localStorage.getItem("shop_total"))
        .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
        .filter((data) => data.shopStatusText === "Rejected")
    : [],
  isShopAvailable: localStorage.getItem("shop_total") ? true : false,
  shopLoading: true,
  ordersList: [],
  ordersListFor30Days: [],
  ordersListFor10Days: [],
};

// Nishitha
//  to get order by using ShopId

export const getAllOrderByShopIdLast30Days = createAsyncThunk(
  "shop/getAllOrderByShopIdLast30Days",
  async (ShopId, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/order/orderbyshopidlast30days/${ShopId}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("ShopNumber is doesn't exist");
    }
  }
);
export const getAllOrderByShopIdLast10Days = createAsyncThunk(
  "shop/getAllOrderByShopIdLast10Days",
  async (ShopId, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/order/orderbyshopidlast10days/${ShopId}`;

      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("ShopNumber is doesn't exist");
    }
  }
);

export const getAllorderByshopId = createAsyncThunk(
  "shop/getAllorderByshopId",
  async (ShopId, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/order/orderbyshopid/${ShopId}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("ShopNumber is doesn't exist");
    }
  }
);

export const getShop = createAsyncThunk("shop/getShop", async (thunkAPI) => {
  try {
    const url = `${Baseurl}/api/v1/shop/all`;
    const resp = await axios(url);
    return resp.data.shop;
  } catch (error) {
    return thunkAPI.rejectWithValue("404 Not Found");
  }
});

export const shopUpdate = createAsyncThunk(
  "shop/shopUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/shop/updateby/${formData.id}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Not create");
    }
  }
);

export const send_approve_sms = createAsyncThunk(
  "shop/send_approve_sms",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/admin/send-approved-sms`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("brand Not create");
    }
  }
);

const ShopSlice = createSlice({
  name: "shop",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getShop.pending, (state) => {
        state.shopLoading = true;
      })
      .addCase(getShop.fulfilled, (state, action) => {
        state.shop_total = action.payload
          .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
          .filter((data) => data.shop_form_status === 3);
        state.approved_shops = state.shop_total.filter(
          (data) => data.shopStatusText === "Approve"
        );
        state.not_approved_shops = state.shop_total.filter(
          (data) => data.shopStatusText === "Not Approve"
        );
        state.rejected_shops = state.shop_total.filter(
          (data) => data.shopStatusText === "Rejected"
        );
        localStorage.setItem("shop_total", JSON.stringify(state.shop_total));
        state.shopLoading = false;
        state.isShopAvailable = true;
      })
      .addCase(getShop.rejected, (state) => {
        state.shopLoading = true;
      });

    builder
      .addCase(shopUpdate.pending, (state) => {
        state.shopLoading = true;
      })
      .addCase(shopUpdate.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.shop_total = state.shop_total
            .filter((shop) => shop._id !== action.payload.shop._id)
            .filter((data) => data.shop_form_status === 3);
          state.shop_total = [...state.shop_total, action.payload.shop].sort(
            (a, b) => (a.createdAt > b.createdAt ? 1 : -1)
          );

          state.approved_shops = state.shop_total.filter(
            (data) => data.shopStatusText === "Approve"
          );
          state.not_approved_shops = state.shop_total.filter(
            (data) => data.shopStatusText === "Not Approve"
          );
          state.rejected_shops = state.shop_total.filter(
            (data) => data.shopStatusText === "Rejected"
          );

          localStorage.setItem("shop_total", JSON.stringify(state.shop_total));
        }

        state.shopLoading = false;
      })
      .addCase(shopUpdate.rejected, (state) => {
        state.shopLoading = true;
      });
    // Nishitha
    builder
      .addCase(getAllorderByshopId.pending, (state) => {
        state.shopLoading = true;
      })
      .addCase(getAllorderByshopId.fulfilled, (state, action) => {
        state.shopLoading = false;
        state.ordersList = action.payload;
      })
      .addCase(getAllorderByshopId.rejected, (state, action) => {
        state.shopLoading = true;
      });
    builder
      .addCase(getAllOrderByShopIdLast30Days.pending, (state) => {
        state.shopLoading = true;
      })
      .addCase(getAllOrderByShopIdLast30Days.fulfilled, (state, action) => {
        state.shopLoading = false;
        state.ordersListFor30Days = action.payload;
      })
      .addCase(getAllOrderByShopIdLast30Days.rejected, (state) => {
        state.shopLoading = true;
      });
    builder
      .addCase(getAllOrderByShopIdLast10Days.pending, (state) => {
        state.shopLoading = true;
      })
      .addCase(getAllOrderByShopIdLast10Days.fulfilled, (state, action) => {
        state.shopLoading = false;
        state.ordersListFor10Days = action.payload;
      })
      .addCase(getAllOrderByShopIdLast10Days.rejected, (state) => {
        state.shopLoading = true;
      });
  },
});

export const {} = ShopSlice.actions;
export default ShopSlice.reducer;
