import React, { useState, useEffect } from "react";
import { Flex, Form, Select, Col, Row, Input } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { MdOutlineErrorOutline } from "react-icons/md";
import { getOneFieldStatus } from "../../../../../redux/fieldStatus/FieldStatusSlice";
const { Option } = Select;


export const HomeandFurnitureSuperCat = ({
    subcategoryid,
    categoryid,
    supercategoryid,
    categoryTagid,
    buttonPress,
    getPropDetails,
    resetield,
}) => {

    const [distinctfield, setdistinctfield] = useState([]);
    const [distinctfieldValidate, setdistinctfieldValidate] = useState([]);
    const dispatch = useDispatch();

    // resetield
    useEffect(() => {
        if (resetield === true) {
        }
    }, [resetield]);

    useEffect(() => {
        if (buttonPress === true) {
            if (distinctfieldValidate.every((field) => field.value !== "")) {
                let finalarray = [];
                for (let index = 0; index < distinctfieldValidate.length; index++) {
                    let element = distinctfieldValidate[index];
                    let obj = { [element.field]: element.value };
                    finalarray = [...finalarray, obj];
                }
                finalarray = finalarray;

                const singleObject = finalarray.reduce((acc, obj) => {
                    return { ...acc, ...obj };
                }, {});
                getPropDetails(singleObject, true);
            } else {
            }
        }
    }, [buttonPress, distinctfieldValidate]);

    useEffect(() => {

        if (categoryTagid) {
            const getdata = async () => {
                const formData = {
                    superCategoryId: supercategoryid,
                    categoryId: categoryid,
                    SubCategoryId: subcategoryid,
                    CategoryTagId: categoryTagid,
                };
                console.log(formData, "formData");

                const data = await dispatch(getOneFieldStatus(formData));
                console.log(data.payload, "data.payload");

                if (data.payload.success === true) {
                    const resp = data.payload.field;
                    const filterShow = resp.selectedFields.filter(
                        (data) => data.status === true
                    );
                    const distinctNames = [
                        ...new Set(filterShow.map((item) => item.field_Name)),
                    ];
                    let distValfielda = [];
                    for (let i = 0; i < filterShow.length; i++) {
                        let element = filterShow[i];
                        let obje2 = { field: element.field_Name, value: "" };
                        distValfielda = [...distValfielda, obje2];
                    }
                    distValfielda = distValfielda;
                    setdistinctfield(distinctNames);
                    setdistinctfieldValidate(distValfielda);
                }
            };
            getdata();
        }
    }, [subcategoryid, categoryid, supercategoryid, categoryTagid]);
    // updatedvalues
    const updateValues = (fieldname, value) => {
        const index = distinctfieldValidate.findIndex(
            (item) => item.field === fieldname
        );
        let updateValue = distinctfieldValidate;
        updateValue[index].value = value;

        setdistinctfieldValidate([...updateValue]);
    };

    // mapping values from redux
    const {
        yes_r_no,
        Pack_of,
        Width,
        length1,
        Depth,
        Ideal_for,
        Size,
        Color,
        Videorecordingresolution,
        Material,
        Net_quantity,
        Number_of_scrubs,
        Capacity,
        PanType,
        Diameter,
        Lid_locking_mechanism,
        Shade,
        Handled_features,
        Base_thickness,
        Body_material,
        Trivet_material,
        Burner_material,
        Number_of_burners,
        Suitable_for,
        Burner_type,
        Shape,
        Ignition_system,
        Gas_inlet_nozzle_type,
        Control_features,
        Support_mechanism,
        Opacity,
        Handle_material,
        Head_material,
        Number_of_contents_in_sales_package,
        Series,
        Charcter,
        Leak_resistance,
        Model_series_name,
        Bed_size,
        Delivery_condition,
        Primary_color,
        Recommended_mattress_length,
        Recommended_mattress_breadth,
        Bed_material,
        Bed_material_sub_type,
        Headboard_material,
        Headboar_material_subtype,
        Finish_type,
        Finish_color,
        Storage_type,
        Headboard_type,
        Floor_clearance,
        Warranty,
        Number_of_shelves,
        Enclosure_type,
        Mount_type,
        Number_of_rods,
        Support_layer,
        Cabinet_type,
        Comfort_layer,
        Mattress_top,
        Towel_rack,
        Number_of_tools,
        Magazine_capacity,
        Fastener_gauge,
        Fastener_length,
        Fastener_type,
        Dial_color,
        Number_of_battires,
        Battery,
        Pillow_cover_length,
        Pillow_cover_width,
        Pattern,
        Flat_sheet_length,
        Net_width,
        Net_length,
        Reversible,
        Blub_used,
        Blub_type,
        Light_color,
        Number_of_blubs,
        Light_used,
        Light_modes,
        Indicators,
        Charging_time,
        Maximum_number_of_recharges,
        Battery_capacity,
        Pet_type,
        Activity_type,
        Chewable,
        Led_toy,
        Collor_type,
        Harness_type,
        Retractable,
        Mattress_features,
        Comfort_levels,
        Upholstery_material,
        Removable_cover,
        Thickness,
        Secondary_material,
        Seconday_material_subtype,
        Configuration,
        Upholstery_material_subtype,
        Upholstery_color,
        Filling_material,
        Seating_capacity,
        Mechanism_type,
        Upholstery_type,
        Mattress_type,
        Removable_leg,
        Frame_material,
        Frame_material_subtype,
        Origin_of_manufacture,
        With_door,
        Number_of_drawers,
        Style,
        Maximum_tv_size,
        Unit_material,
        Unit_material_sub_type,
        Number_of_chairs,
        Table_shape,
        Extendabale,
        Foldable,
        Table_frame_material,
        Table_frame_materical_type,
        Chair_material,
        Chair_width,
        Chair_depth,
        Chair_height,
        Chair_weight,
        Table_width,
        Table_height,
        Table_depth,
        Table_weight,
        Table_Top_Material_sub_type,
        Table_Top_Material,
        Power_source,
        Wall_mount_support,
        Number_of_shoe_pairs,
        Material_subtype,
        Primary_material_subtype,
        Primaray_material,
        Rollout_bed,
        Built_in_ladder,
        Cover_type,
        Filling_type,
        Lining,
        Transparency,
        External_material,
        Filling_amount,
        Number_of_bedsheets,
        Thread_count,
        Collection,
        Fabric_care,
        Flat_sheet_width,
        Ideal_usage,
        Top_rating,
        GSM,
        Weave_type,
        Outer_material,
        Design,
        Theme,
        Usage,
        Brand_color,
        Key_features,
        Mobile_capability,
        Lens_size,
        Angular_view,
        Mounting_type,
        Camera_type,
        Video_output,
        Recording_modes,
        Image_sensor,
        IR_distance,
        Number_of_IR_LEDS,
        Motion_triggered_recording,
        Audio_output,
        Battery_type,
        Finish,
        Functions,
        Compatible_devices,
        Connective_type,
        Frame_color,
        Wall_mount,
        Number_of_batteries,
        Mount_mechanism,
        Scratch_resistant,
        Decal_color,
        Sticker_type,
        Art_form_type,
        Sub_category,
        Rated_life,
        Blub_shape,
        Shatter_proof,
        Pack_of_lamps,
        Card_length,
    } = useSelector((store) => store.Messurents)



    // Nishitha.....

    // input , dropdown  and error
    const [pack_of, setPack_of] = useState("");
    const [pack_of_error, setPack_of_error] = useState("");

    const [brand, setBrand] = useState("");
    const [brand_error, setBrand_error] = useState("");
    const [height, setHeight] = useState("");
    const [height_error, setHeight_error] = useState("");

    const [weightt, setWeightt] = useState("");
    const [weightt_error, setWeightt_error] = useState("");

    const [model_number, setModel_number] = useState("");
    const [model_number_error, setModel_number_error] = useState("");

    const [model_name, setModel_name] = useState("");
    const [model_name_error, setModel_name_error] = useState("");

    const [type, setType] = useState("");
    const [type_error, setType_error] = useState("");

    const [size, setSize] = useState("");
    const [size_error, setSize_error] = useState("");

    const [color, setColor] = useState("");
    const [color_error, setColor_error] = useState("");

    const [material, setMaterial] = useState("");
    const [material_error, setMaterial_error] = useState("");

    const [other_features, setOther_features] = useState("");
    const [other_features_error, setOther_features_error] = useState("");


    const [number_of_scrubs, setNumber_of_scrubs] = useState("");
    const [number_of_scrubs_error, setNumber_of_scrubs_error] = useState("");

    const [net_quantity, setNet_quantity] = useState("");
    const [net_quantity_error, setNet_quantity_error] = useState("");

    const [sales_package, setSales_package] = useState("");
    const [sales_package_error, setSales_package_error] = useState("");

    const [capacity, setCapacity] = useState("");
    const [capacity_error, setCapacity_error] = useState("");

    const [pan_type, setPan_type] = useState("");
    const [pan_type_error, setPan_type_error] = useState("");

    const [induction_bottom, setInduction_bottom] = useState("");
    const [induction_bottom_error, setInduction_bottom_error] = useState("");

    const [lid_included, setLid_included] = useState("");
    const [lid_included_error, setLid_included_error] = useState("");

    const [non_stick, setNon_stick] = useState("");
    const [non_stick_error, setNon_stick_error] = useState("");

    const [dish_washer_safe, setDish_washer_safe] = useState("");
    const [dish_washer_safe_error, setDish_washer_safe_error] = useState("");

    const [diameter, setDiameter] = useState("");
    const [diameter_error, setDiameter_error] = useState("");


    const [lid_locking_mechanism, setLid_locking_mechanism] = useState("");
    const [lid_locking_mechanism_error, setLid_locking_mechanism_error] = useState("");

    const [other_body_and_design_features, setOther_body_and_design_features] = useState("");
    const [other_body_and_design_features_error, setOther_body_and_design_features_error] = useState("");

    const [shade, setShade] = useState("");
    const [shade_error, setShade_error] = useState("");

    const [suitable_for, setSuitable_for] = useState("");
    const [suitable_for_error, setSuitable_for_error] = useState("");

    const [handled_features, setHandled_features] = useState("");
    const [handled_features_error, setHandled_features_error] = useState("");

    const [base_thickness, setBase_thickness] = useState("");
    const [base_thickness_error, setBase_thickness_error] = useState("");

    const [covered_in_warranty, setCovered_in_warranty] = useState("");
    const [covered_in_warranty_error, setCovered_in_warranty_error] = useState("");

    const [warranty_summary, setWarranty_summary] = useState("");
    const [warranty_summary_error, setWarranty_summary_error] = useState("");

    const [not_covered_in_warranty, setNot_covered_in_warranty] = useState("");
    const [not_covered_in_warranty_error, setNot_covered_in_warranty_error] = useState("");

    const [warranty_service_type, setWarranty_service_type] = useState("");
    const [warranty_service_type_error, setWarranty_service_type_error] = useState("");

    const [domestic_warranty, setDomestic_warranty] = useState("");
    const [domestic_warranty_error, setDomestic_warranty_error] = useState("");

    const [in_the_box, setIn_the_box] = useState("");
    const [in_the_box_error, setIn_the_box_error] = useState("");

    const [body_material, setBody_material] = useState("");
    const [body_material_error, setBody_material_error] = useState("");

    const [trivet_material, setTrivet_material] = useState("");
    const [trivet_material_error, setTrivet_material_error] = useState("");

    const [burner_material, setBurner_material] = useState("");
    const [burner_material_error, setBurner_material_error] = useState("");

    const [number_of_burners, setNumber_of_burners] = useState("");
    const [number_of_burners_error, setNumber_of_burners_error] = useState("");

    const [burner_type, setBurner_type] = useState("");
    const [burner_type_error, setBurner_type_error] = useState("");

    const [ignition_system, setIgnition_system] = useState("");
    const [ignition_system_error, setIgnition_system_error] = useState("");

    const [gas_inlet_nozzle_tyle, setGas_inlet_nozzle_tyle] = useState("");
    const [gas_inlet_nozzle_tyle_error, setGas_inlet_nozzle_tyle_error] = useState("");

    const [control_features, setControl_features] = useState("");
    const [control_features_error, setControl_features_error] = useState("");

    const [spill_tray_included, setSpill_tray_included] = useState("");
    const [spill_tray_included_error, setSpill_tray_included_error] = useState("");

    const [wear_resistant, setWear_resistant] = useState("");
    const [wear_resistant_error, setWear_resistant_error] = useState("");

    const [timer, setTimer] = useState("");
    const [timer_error, setTimer_error] = useState("");

    const [rust_proof, setRust_proof] = useState("");
    const [rust_proof_error, setRust_proof_error] = useState("");

    const [support_mechanism, setSupport_mechanism] = useState("");
    const [support_mechanism_error, setSupport_mechanism_error] = useState("");

    const [safety_features, setSafety_features] = useState("");
    const [safety_features_error, setSafety_features_error] = useState("");

    const [certification, setCertification] = useState("");
    const [certification_error, setCertification_error] = useState("");

    const [width, setWidth] = useState("");
    const [width_error, setWidth_error] = useState("");

    const [depth, setDepth] = useState("");
    const [depth_error, setDepth_error] = useState("");

    const [service_type, setService_type] = useState("");
    const [service_type_error, setService_type_error] = useState("");

    const [opacity, setOpacity] = useState("");
    const [opacity_error, setOpacity_error] = useState("");

    const [freezer_safe, setFreezer_safe] = useState("");
    const [freezer_safe_error, setFreezer_safe_error] = useState("");

    const [ideal_for, setIdeal_for] = useState("");
    const [ideal_for_error, setIdeal_for_error] = useState("");

    const [with_handle, setWith_handle] = useState("");
    const [with_handle_error, setWith_handle_error] = useState("");

    const [handle_material, setHandle_material] = useState("");
    const [handle_material_error, setHandle_material_error] = useState("");

    const [widthxheightxdepth, setWidthxheightxdepth] = useState("");
    const [widthxheightxdepth_error, setWidthxheightxdepth_error] = useState("");

    const [head_material, setHead_material] = useState("");
    const [head_material_error, setHead_material_error] = useState("");

    const [number_of_contents_in_sales_package, setNumber_of_contents_in_sales_package] = useState("");
    const [number_of_contents_in_sales_package_error, setNumber_of_contents_in_sales_package_error] = useState("");

    const [handle_with_care, setHandle_with_care] = useState("");
    const [handle_with_care_error, setHandle_with_care_error] = useState("");

    const [bpa_free, setBpa_free] = useState("");
    const [bpa_free_error, setBpa_free_error] = useState("");

    const [leak_proof, setLeak_proof] = useState("");
    const [leak_proof_error, setLeak_proof_error] = useState("");

    const [series, setSeries] = useState("");
    const [series_error, setSeries_error] = useState("");

    const [character, setCharacter] = useState("");
    const [character_error, setCharacter_error] = useState("");

    const [leak_resistant, setLeak_resistant] = useState("");
    const [leak_resistant_error, setLeak_resistant_error] = useState("");

    const [other_lunch_box_features, setOther_lunch_box_features] = useState("");
    const [other_lunch_box_features_error, setOther_lunch_box_features_error] = useState("");

    const [international_warranty, setInternational_warranty] = useState("");
    const [international_warranty_error, setInternational_warranty_error] = useState("");

    const [model_series_name, setModel_series_name] = useState("");
    const [model_series_name_error, setModel_series_name_error] = useState("");

    const [bed_size, setBed_size] = useState("");
    const [bed_size_error, setBed_size_error] = useState("");

    const [delivery_condition, setDelivery_condition] = useState("");
    const [delivery_condition_error, setDelivery_condition_error] = useState("");

    const [primary_color, setPrimary_color] = useState("");
    const [primary_color_error, setPrimary_color_error] = useState("");


    const [with_mattress, setWith_mattress] = useState("");
    const [with_mattress_error, setWith_mattress_error] = useState("");

    const [recommended_mattress_length, setRecommended_mattress_length] = useState("");
    const [recommended_mattress_length_error, setRecommended_mattress_length_error] = useState("");

    const [recommended_mattress_breadth, setRecommended_mattress_breadth] = useState("");
    const [recommended_mattress_breadth_error, setRecommended_mattress_breadth_error] = useState("");

    const [shape, setShape] = useState("");
    const [shape_error, setShape_error] = useState("");

    const [bed_material, setBed_material] = useState("");
    const [bed_material_error, setBed_material_error] = useState("");

    const [bed_material_subtype, setBed_material_subtype] = useState("");
    const [bed_material_subtype_error, setBed_material_subtype_error] = useState("");

    const [headboard_material, setHeadboard_material] = useState("");
    const [headboard_material_error, setHeadboard_material_error] = useState("");

    const [headboard_material_subtype, setHeadboard_material_subtype] = useState("");
    const [headboard_material_subtype_error, setHeadboard_material_subtype_error] = useState("");

    const [upholstery_included, setUpholstery_included] = useState("");
    const [upholstery_included_error, setUpholstery_included_error] = useState("");

    const [finish_type, setFinish_type] = useState("");
    const [finish_type_error, setFinish_type_error] = useState("");

    const [finish_color, setFinish_color] = useState("");
    const [finish_color_error, setFinish_color_error] = useState("");

    const [storage_type, setStorage_type] = useState("");
    const [storage_type_error, setStorage_type_error] = useState("");

    const [headboard_included, setHeadboard_included] = useState("");
    const [headboard_included_error, setHeadboard_included_error] = useState("");

    const [floor_clearance, setFloor_clearance] = useState("");
    const [floor_clearance_error, setFloor_clearance_error] = useState("");

    const [installation_and_demo_details, setInstallation_and_demo_details] = useState("");
    const [installation_and_demo_details_error, setInstallation_and_demo_details_error] = useState("");

    const [number_of_shelves, setNumber_of_shelves] = useState("");
    const [number_of_shelves_error, setNumber_of_shelves_error] = useState("");

    const [rod_included, setRod_included] = useState("");
    const [rod_included_error, setRod_included_error] = useState("");

    const [bush_included, setBush_included] = useState("");
    const [bush_included_error, setBush_included_error] = useState("");

    const [enclosure_type, setEnclosure_type] = useState("");
    const [enclosure_type_error, setEnclosure_type_error] = useState("");

    const [mount_type, setMount_type] = useState("");
    const [mount_type_error, setMount_type_error] = useState("");

    const [number_of_rods, setNumber_of_rods] = useState("");
    const [number_of_rods_error, setNumber_of_rods_error] = useState("");

    const [cabinet_type, setCabinet_type] = useState("");
    const [cabinet_type_error, setCabinet_type_error] = useState("");

    const [support_layer, setSupport_layer] = useState("");
    const [support_layer_error, setSupport_layer_error] = useState("");

    const [comfort_layer, setComfort_layer] = useState("");
    const [comfort_layer_error, setComfort_layer_error] = useState("");

    const [mattress_top, setMattress_top] = useState("");
    const [mattress_top_error, setMattress_top_error] = useState("");

    const [mattress_features, setMattress_features] = useState("");
    const [mattress_features_error, setMattress_features_error] = useState("");

    const [comfort_levels, setComfort_levels] = useState("");
    const [comfort_levels_error, setComfort_levels_error] = useState("");

    const [upholstery_material, setUpholstery_material] = useState("");
    const [upholstery_material_error, setUpholstery_material_error] = useState("");

    const [removable_cover, setRemovable_cover] = useState("");
    const [removable_cover_error, setRemovable_cover_error] = useState("");

    const [reinforced_edges, setReinforced_edges] = useState("");
    const [reinforced_edges_error, setReinforced_edges_error] = useState("");

    const [thickness, setThickness] = useState("");
    const [thickness_error, setThickness_error] = useState("");

    const [length, setLength] = useState("");
    const [length_error, setLength_error] = useState("");

    const [secondary_material, setSecondary_material] = useState("");
    const [secondary_material_error, setSecondary_material_error] = useState("");

    const [secondary_material_subtype, setSecondary_material_subtype] = useState("");
    const [secondary_material_subtype_error, setSecondary_material_subtype_error] = useState("");

    const [configuration, setConfiguration] = useState("");
    const [configuration_error, setConfiguration_error] = useState("");

    const [upholstery_material_subtype, setUpholstery_material_subtype] = useState("");
    const [upholstery_material_subtype_error, setUpholstery_material_subtype_error] = useState("");

    const [upholstery_material_color, setUpholstery_material_color] = useState("");
    const [upholstery_material_color_error, setUpholstery_material_color_error] = useState("");

    const [filling_material, setFilling_material] = useState("");
    const [filling_material_error, setFilling_material_error] = useState("");

    const [seating_capacity, setSeating_capacity] = useState("");
    const [seating_capacity_error, setSeating_capacity_error] = useState("");

    const [mattress_included, setMattress_included] = useState("");
    const [mattress_included_error, setMattress_included_error] = useState("");

    const [mechanism_type, setMechanism_type] = useState("");
    const [mechanism_type_error, setMechanism_type_error] = useState("");

    const [upholstery_type, setUpholstery_type] = useState("");
    const [upholstery_type_error, setUpholstery_type_error] = useState("");

    const [mattress_type, setMattress_type] = useState("");
    const [mattress_type_error, setMattress_type_error] = useState("");

    const [removable_leg, setRemovable_leg] = useState("");
    const [removable_leg_error, setRemovable_leg_error] = useState("");

    const [frame_material, setFrame_material] = useState("");
    const [frame_material_error, setFrame_material_error] = useState("");

    const [frame_material_subtype, setFrame_material_subtype] = useState("");
    const [frame_material_subtype_error, setFrame_material_subtype_error] = useState("");

    const [accessories_included, setAccessories_included] = useState("");
    const [accessories_included_error, setAccessories_included_error] = useState("");

    const [origin_of_manufacture, setOrigin_of_manufacture] = useState("");
    const [origin_of_manufacture_error, setOrigin_of_manufacture_error] = useState("");

    const [with_door, setWith_door] = useState("");
    const [with_door_error, setWith_door_error] = useState("");

    const [number_of_drawers, setNumber_of_drawers] = useState("");
    const [number_of_drawers_error, setNumber_of_drawers_error] = useState("");

    const [style, setStyle] = useState("");
    const [style_error, setStyle_error] = useState("");

    const [maximum_tv_size, setMaximum_tv_size] = useState("");
    const [maximum_tv_size_error, setMaximum_tv_size_error] = useState("");

    const [unit_material, setUnit_material] = useState("");
    const [unit_material_error, setUnit_material_error] = useState("");

    const [unit_material_type, setUnit_material_type] = useState("");
    const [unit_material_type_error, setUnit_material_type_error] = useState("");

    const [number_of_chairs, setNumber_of_chairs] = useState("");
    const [number_of_chairs_error, setNumber_of_chairs_error] = useState("");

    const [table_shape, setTable_shape] = useState("");
    const [table_shape_error, setTable_shape_error] = useState("");

    const [wheels_included, setWheels_included] = useState("");
    const [wheels_included_error, setWheels_included_error] = useState("");

    const [extendable, setExtendable] = useState("");
    const [extendable_error, setExtendable_error] = useState("");

    const [foldable, setFoldable] = useState("");
    const [foldable_error, setFoldable_error] = useState("");

    const [table_frame_material, setTable_frame_material] = useState("");
    const [table_frame_material_error, setTable_frame_material_error] = useState("");

    const [table_frame_material_subtype, setTable_frame_material_subtype] = useState("");
    const [table_frame_material_subtype_error, setTable_frame_material_subtype_error] = useState("");

    const [chair_material, setChair_material] = useState("");
    const [chair_material_error, setChair_material_error] = useState("");

    const [chair_width, setChair_width] = useState("");
    const [chair_width_error, setChair_width_error] = useState("");

    const [chair_height, setChair_height] = useState("");
    const [chair_height_error, setChair_height_error] = useState("");

    const [chair_depth, setChair_depth] = useState("");
    const [chair_depth_error, setChair_depth_error] = useState("");

    const [chair_weight, setChair_weight] = useState("");
    const [chair_weight_error, setChair_weight_error] = useState("");

    const [table_width, setTable_width] = useState("");
    const [table_width_error, setTable_width_error] = useState("");

    const [table_height, setTable_height] = useState("");
    const [table_height_error, setTable_height_error] = useState("");

    const [table_depth, setTable_depth] = useState("");
    const [table_depth_error, setTable_depth_error] = useState("");

    const [table_weight, setTable_weight] = useState("");
    const [table_weight_error, setTable_weight_error] = useState("");

    const [table_top_material, setTable_top_material] = useState("");
    const [table_top_material_error, setTable_top_material_error] = useState("");

    const [table_top_material_subtype, setTable_top_material_subtype] = useState("");
    const [table_top_material_subtype_error, setTable_top_material_subtype_error] = useState("");

    const [lock_provided, setLock_provided] = useState("");
    const [lock_provided_error, setLock_provided_error] = useState("");

    const [wall_mount_support, setWall_mount_support] = useState("");
    const [wall_mount_support_error, setWall_mount_support_error] = useState("");

    const [number_of_shoe_pairs, setNumber_of_shoe_pairs] = useState("");
    const [number_of_shoe_pairs_error, setNumber_of_shoe_pairs_error] = useState("");

    const [material_subtype, setMaterial_subtype] = useState("");
    const [material_subtype_error, setMaterial_subtype_error] = useState("");

    const [safety_instructions, setSafety_instructions] = useState("");
    const [safety_instructions_error, setSafety_instructions_error] = useState("");

    const [primary_material, setPrimary_material] = useState("");
    const [primary_material_error, setPrimary_material_error] = useState("");

    const [primary_material_subtype, setPrimary_material_subtype] = useState("");
    const [primary_material_subtype_error, setPrimary_material_subtype_error] = useState("");
    const [rollout_bed, setRollout_bed] = useState("");
    const [rollout_bed_error, setRollout_bed_error] = useState("");

    const [book_shelf_included, setBook_shelf_included] = useState("");
    const [book_shelf_included_error, setBook_shelf_included_error] = useState("");

    const [built_in_ladder, setBuilt_in_ladder] = useState("");
    const [built_in_ladder_error, setBuilt_in_ladder_error] = useState("");

    const [study_desk_available, setStudy_desk_available] = useState("");
    const [study_desk_available_error, setStudy_desk_available_error] = useState("");

    const [tent_included, setTent_included] = useState("");
    const [tent_included_error, setTent_included_error] = useState("");

    const [slide_included, setSlide_included] = useState("");
    const [slide_included_error, setSlide_included_error] = useState("");

    const [railing_included, setRailing_included] = useState("");
    const [railing_included_error, setRailing_included_error] = useState("");

    const [model, setModel] = useState("");
    const [model_error, setModel_error] = useState("");

    const [cover_type, setCover_type] = useState("");
    const [cover_type_error, setCover_type_error] = useState("");

    const [filling_type, setFilling_type] = useState("");
    const [filling_type_error, setFilling_type_error] = useState("");

    const [filling_amount, setFilling_amount] = useState("");
    const [filling_amount_error, setFilling_amount_error] = useState("");

    const [flat_sheet_length, setFlat_sheet_length] = useState("");
    const [flat_sheet_length_error, setFlat_sheet_length_error] = useState("");

    const [number_of_bedsheets, setNumber_of_bedsheets] = useState("");
    const [number_of_bedsheets_error, setNumber_of_bedsheets_error] = useState("");

    const [thread_count, setThread_count] = useState("");
    const [thread_count_error, setThread_count_error] = useState("");

    const [model_id, setModel_id] = useState("");
    const [model_id_error, setModel_id_error] = useState("");

    const [collection, setCollection] = useState("");
    const [collection_error, setCollection_error] = useState("");

    const [storage_included, setStorage_included] = useState("");
    const [storage_included_error, setStorage_included_error] = useState("");

    const [fabric_care, setFabric_care] = useState("");
    const [fabric_care_error, setFabric_care_error] = useState("");

    const [machine_washable, setMachine_washable] = useState("");
    const [machine_washable_error, setMachine_washable_error] = useState("");

    const [lining, setLining] = useState("");
    const [lining_error, setLining_error] = useState("");

    const [transparency, setTransparency] = useState("");
    const [transparency_error, setTransparency_error] = useState("");

    const [external_material, setExternal_material] = useState("");
    const [external_material_error, setExternal_material_error] = useState("");

    const [with_pillow_cover, setWith_pillow_cover] = useState("");
    const [with_pillow_cover_error, setWith_pillow_cover_error] = useState("");

    const [hand_washable, setHand_washable] = useState("");
    const [hand_washable_error, setHand_washable_error] = useState("");

    const [warranty, setWarranty] = useState("");
    const [warranty_error, setWarranty_error] = useState("");

    const [ideal_usage, setIdeal_usage] = useState("");
    const [ideal_usage_error, setIdeal_usage_error] = useState("");

    const [top_rating, setTop_rating] = useState("");
    const [top_rating_error, setTop_rating_error] = useState("");

    const [gsm, setGsm] = useState("");
    const [gsm_error, setGsm_error] = useState("");

    const [weave_type, setWeave_type] = useState("");
    const [weave_type_error, setWeave_type_error] = useState("");

    const [outer_material, setOuter_material] = useState("");
    const [outer_material_error, setOuter_material_error] = useState("");

    const [design, setDesign] = useState("");
    const [design_error, setDesign_error] = useState("");

    const [description, setDescription] = useState("");
    const [description_error, setDescription_error] = useState("");

    const [pattern, setPattern] = useState("");
    const [pattern_error, setPattern_error] = useState("");

    const [theme, setTheme] = useState("");
    const [theme_error, setTheme_error] = useState("");

    const [usage, setUsage] = useState("");
    const [usage_error, setUsage_error] = useState("");

    const [brand_color, setBrand_color] = useState("");
    const [brand_color_error, setBrand_color_error] = useState("");

    const [features, setFeatures] = useState("");
    const [features_error, setFeatures_error] = useState("");

    const [key_features, setKey_features] = useState("");
    const [key_features_error, setKey_features_error] = useState("");

    const [camera_type, setCamera_type] = useState("");
    const [camera_type_error, setCamera_type_error] = useState("");

    const [video_output, setVideo_output] = useState("");
    const [video_output_error, setVideo_output_error] = useState("");

    const [video_recording_resolution, setVideo_recording_resolution] = useState("");
    const [video_recording_resolution_error, setVideo_recording_resolution_error] = useState("");

    const [video_recording_frame_rate, setVideo_recording_frame_rate] = useState("");
    const [video_recording_frame_rate_error, setVideo_recording_frame_rate_error] = useState("");

    const [mobile_compatibility, setMobile_compatibility] = useState("");
    const [mobile_compatibility_error, setMobile_compatibility_error] = useState("");

    const [lens_size, setLens_size] = useState("");
    const [lens_size_error, setLens_size_error] = useState("");

    const [angle_of_view, setAngle_of_view] = useState("");
    const [angle_of_view_error, setAngle_of_view_error] = useState("");

    const [mounting_type, setMounting_type] = useState("");
    const [mounting_type_error, setMounting_type_error] = useState("");

    const [records_modes, setRecords_modes] = useState("");
    const [records_modes_error, setRecords_modes_error] = useState("");

    const [image_sensor, setImage_sensor] = useState("");
    const [image_sensor_error, setImage_sensor_error] = useState("");

    const [ir_distance, setIr_distance] = useState("");
    const [ir_distance_error, setIr_distance_error] = useState("");

    const [number_of_ir_leds, setNumber_of_ir_leds] = useState("");
    const [number_of_ir_leds_error, setNumber_of_ir_leds_error] = useState("");

    const [hdd_available, setHdd_available] = useState("");
    const [hdd_available_error, setHdd_available_error] = useState("");

    const [motion_triggered_recording, setMotion_triggered_recording] = useState("");
    const [motion_triggered_recording_error, setMotion_triggered_recording_error] = useState("");

    const [power_source, setPower_source] = useState("");
    const [power_source_error, setPower_source_error] = useState("");

    const [audio_output, setAudio_output] = useState("");
    const [audio_output_error, setAudio_output_error] = useState("");

    const [usb_port_present, setUsb_port_present] = useState("");
    const [usb_port_present_error, setUsb_port_present_error] = useState("");

    const [battery_type, setBattery_type] = useState("");
    const [battery_type_error, setBattery_type_error] = useState("");

    const [other_battery_features, setOther_battery_features] = useState("");
    const [other_battery_features_error, setOther_battery_features_error] = useState("");

    const [finish, setFinish] = useState("");
    const [finish_error, setFinish_error] = useState("");

    const [functions, setFunctions] = useState("");
    const [functions_error, setFunctions_error] = useState("");

    const [compatible_devices, setCompatible_devices] = useState("");
    const [compatible_devices_error, setCompatible_devices_error] = useState("");

    const [connectivity_type, setConnectivity_type] = useState("");
    const [connectivity_type_error, setConnectivity_type_error] = useState("");

    const [number_of_batteries, setNumber_of_batteries] = useState("");
    const [number_of_batteries_error, setNumber_of_batteries_error] = useState("");

    const [frame_color, setFrame_color] = useState("");
    const [frame_color_error, setFrame_color_error] = useState("");

    const [wall_mount, setWall_mount] = useState("");
    const [wall_mount_error, setWall_mount_error] = useState("");

    const [frame_included, setFrame_included] = useState("");
    const [frame_included_error, setFrame_included_error] = useState("");

    const [mount_mechanism, setMount_mechanism] = useState("");
    const [mount_mechanism_error, setMount_mechanism_error] = useState("");

    const [ideal_use, setIdeal_use] = useState("");
    const [ideal_use_error, setIdeal_use_error] = useState("");

    const [scratch_resistant, setScratch_resistant] = useState("");
    const [scratch_resistant_error, setScratch_resistant_error] = useState("");

    const [decal_color, setDecal_color] = useState("");
    const [decal_color_error, setDecal_color_error] = useState("");

    const [sticker_type, setSticker_type] = useState("");
    const [sticker_type_error, setSticker_type_error] = useState("");

    const [regional_speciality, setRegional_speciality] = useState("");
    const [regional_speciality_error, setRegional_speciality_error] = useState("");

    const [art_form_type, setArt_form_type] = useState("");
    const [art_form_type_error, setArt_form_type_error] = useState("");

    const [sub_category, setSub_category] = useState("");
    const [sub_category_error, setSub_category_error] = useState("");

    const [pack_of_lamps, setPack_of_lamps] = useState("");
    const [pack_of_lamps_error, setPack_of_lamps_error] = useState("");

    const [blub_included, setBlub_included] = useState("");
    const [blub_included_error, setBlub_included_error] = useState("");

    const [blub_used, setBlub_used] = useState("");
    const [blub_used_error, setBlub_used_error] = useState("");

    const [adjustable, setAdjustable] = useState("");
    const [adjustable_error, setAdjustable_error] = useState("");

    const [blub_type, setBlub_type] = useState("");
    const [blub_type_error, setBlub_type_error] = useState("");

    const [with_shade, setWith_shade] = useState("");
    const [with_shade_error, setWith_shade_error] = useState("");

    const [light_color, setLight_color] = useState("");
    const [light_color_error, setLight_color_error] = useState("");

    const [blub_shape, setBlub_shape] = useState("");
    const [blub_shape_error, setBlub_shape_error] = useState("");

    const [energy_saving, setEnergy_saving] = useState("");
    const [energy_saving_error, setEnergy_saving_error] = useState("");

    const [shatter_proof, setShatter_proof] = useState("");
    const [shatter_proof_error, setShatter_proof_error] = useState("");

    const [card_length, setCard_length] = useState("");
    const [card_length_error, setCard_length_error] = useState("");

    const [number_of_bulb, setNumber_of_bulb] = useState("");
    const [number_of_bulb_error, setNumber_of_bulb_error] = useState("");

    const [light_used, setLight_used] = useState("");
    const [light_used_error, setLight_used_error] = useState("");

    const [light_mode, setLight_mode] = useState("");
    const [light_mode_error, setLight_mode_error] = useState("");

    const [indicators, setIndicators] = useState("");
    const [indicators_error, setIndicators_error] = useState("");
    const [charging_time, setCharging_time] = useState("");
    const [charging_time_error, setCharging_time_error] = useState("");

    const [maximum_number_of_recharges, setMaximum_number_of_recharges] = useState("");
    const [maximum_number_of_recharges_error, setMaximum_number_of_recharges_error] = useState("");

    const [battery_capacity, setBattery_capacity] = useState("");
    const [battery_capacity_error, setBattery_capacity_error] = useState("");

    const [power_consumption, setPower_consumption] = useState("");
    const [power_consumption_error, setPower_consumption_error] = useState("");

    const [power_requirement, setPower_requirement] = useState("");
    const [power_requirement_error, setPower_requirement_error] = useState("");

    const [pet_type, setPet_type] = useState("");
    const [pet_type_error, setPet_type_error] = useState("");

    const [activity_type, setActivity_type] = useState("");
    const [activity_type_error, setActivity_type_error] = useState("");

    const [chewable, setChewable] = useState("");
    const [chewable_error, setChewable_error] = useState("");

    const [led_toy, setLed_toy] = useState("");
    const [led_toy_error, setLed_toy_error] = useState("");

    const [usage_instructions, setUsage_instructions] = useState("");
    const [usage_instructions_error, setUsage_instructions_error] = useState("");

    const [collar_type, setCollar_type] = useState("");
    const [collar_type_error, setCollar_type_error] = useState("");

    const [harness_type, setHarness_type] = useState("");
    const [harness_type_error, setHarness_type_error] = useState("");

    const [retractable, setRetractable] = useState("");
    const [retractable_error, setRetractable_error] = useState("");

    const [reversible, setReversible] = useState("");
    const [reversible_error, setReversible_error] = useState("");

    const [net_length, setNet_length] = useState("");
    const [net_length_error, setNet_length_error] = useState("");

    const [net_width, setNet_width] = useState("");
    const [net_width_error, setNet_width_error] = useState("");

    const [flat_sheet_width, setFlat_sheet_width] = useState("");
    const [flat_sheet_width_error, setFlat_sheet_width_error] = useState("");

    const [pillow_cover_width, setPillow_cover_width] = useState("");
    const [pillow_cover_width_error, setPillow_cover_width_error] = useState("");

    const [pillow_cover_length, setPillow_cover_length] = useState("");
    const [pillow_cover_length_error, setPillow_cover_length_error] = useState("");

    const [with_frame, setWith_frame] = useState("");
    const [with_frame_error, setWith_frame_error] = useState("");

    const [mounting_handware_included, setMounting_handware_included] = useState("");
    const [mounting_handware_included_error, setMounting_handware_included_error] = useState("");

    const [water_proof, setWater_proof] = useState("");
    const [water_proof_error, setWater_proof_error] = useState("");

    const [battery, setBattery] = useState("");
    const [battery_error, setBattery_error] = useState("");

    const [dial_color, setDial_color] = useState("");
    const [dial_color_error, setDial_color_error] = useState("");

    const [fastener_type, setFastener_type] = useState("");
    const [fastener_type_error, setFastener_type_error] = useState("");

    const [fastener_gauge, setFastener_gauge] = useState("");
    const [fastener_gauge_error, setFastener_gauge_error] = useState("");

    const [fastener_length, setFastener_length] = useState("");
    const [fastener_length_error, setFastener_length_error] = useState("");

    const [magazine_capacity, setMagazine_capacity] = useState("");
    const [magazine_capacity_error, setMagazine_capacity_error] = useState("");

    const [assembly_required, setAssembly_required] = useState("");
    const [assembly_required_error, setAssembly_required_error] = useState("");

    const [number_of_tools, setNumber_of_tools] = useState("");
    const [number_of_tools_error, setNumber_of_tools_error] = useState("");

    const [towel_rack, setTowel_rack] = useState("");
    const [towel_rack_error, setTowel_rack_error] = useState("");



    // errors for that
    useEffect(() => {
        if (buttonPress === true) {
            if (pack_of === "") {
                setPack_of_error("Required");
            } else {
                setPack_of_error("");
            }

            if (brand === "") {
                setBrand_error("Required");
            } else {
                setBrand_error("");
            }

            if (model_number === "") {
                setModel_number_error("Required");
            } else {
                setModel_number_error("");
            }

            if (model_name === "") {
                setModel_name_error("Required");
            } else {
                setModel_name_error("");
            }
            if (type === "") {
                setType_error("Required");
            } else {
                setType_error("");
            }

            if (size === "") {
                setSize_error("Required");
            } else {
                setSize_error("");
            }

            if (color === "") {
                setColor_error("Required");
            } else {
                setColor_error("");
            }
            if (weightt === "") {
                setWeightt_error("Required")

            } else {
                setWeightt_error("")
            }

            if (material === "") {
                setMaterial_error("Required");
            } else {
                setMaterial_error("");
            }

            if (other_features === "") {
                setOther_features_error("Required");
            } else {
                setOther_features_error("");
            }

            if (number_of_scrubs === "") {
                setNumber_of_scrubs_error("Required");
            } else {
                setNumber_of_scrubs_error("");
            }

            if (net_quantity === "") {
                setNet_quantity_error("Required");
            } else {
                setNet_quantity_error("");
            }

            if (sales_package === "") {
                setSales_package_error("Required");
            } else {
                setSales_package_error("");
            }

            if (capacity === "") {
                setCapacity_error("Required");
            } else {
                setCapacity_error("");
            }

            if (pan_type === "") {
                setPan_type_error("Required");
            } else {
                setPan_type_error("");
            }

            if (induction_bottom === "") {
                setInduction_bottom_error("Required");
            } else {
                setInduction_bottom_error("");
            }

            if (lid_included === "") {
                setLid_included_error("Required");
            } else {
                setLid_included_error("");
            }

            if (non_stick === "") {
                setNon_stick_error("Required");
            } else {
                setNon_stick_error("");
            }

            if (dish_washer_safe === "") {
                setDish_washer_safe_error("Required");
            } else {
                setDish_washer_safe_error("");
            }

            if (diameter === "") {
                setDiameter_error("Required");
            } else {
                setDiameter_error("");
            }

            if (lid_locking_mechanism === "") {
                setLid_locking_mechanism_error("Required");
            } else {
                setLid_locking_mechanism_error("");
            }

            if (other_body_and_design_features === "") {
                setOther_body_and_design_features_error("Required");
            } else {
                setOther_body_and_design_features_error("");
            }

            if (shade === "") {
                setShade_error("Required");
            } else {
                setShade_error("");
            }

            if (suitable_for === "") {
                setSuitable_for_error("Required");
            } else {
                setSuitable_for_error("");
            }

            if (handled_features === "") {
                setHandled_features_error("Required");
            } else {
                setHandled_features_error("");
            }

            if (base_thickness === "") {
                setBase_thickness_error("Required");
            } else {
                setBase_thickness_error("");
            }

            if (covered_in_warranty === "") {
                setCovered_in_warranty_error("Required");
            } else {
                setCovered_in_warranty_error("");
            }

            if (warranty_summary === "") {
                setWarranty_summary_error("Required");
            } else {
                setWarranty_summary_error("");
            }

            if (not_covered_in_warranty === "") {
                setNot_covered_in_warranty_error("Required");
            } else {
                setNot_covered_in_warranty_error("");
            }

            if (warranty_service_type === "") {
                setWarranty_service_type_error("Required");
            } else {
                setWarranty_service_type_error("");
            }

            if (domestic_warranty === "") {
                setDomestic_warranty_error("Required");
            } else {
                setDomestic_warranty_error("");
            }

            if (in_the_box === "") {
                setIn_the_box_error("Required");
            } else {
                setIn_the_box_error("");
            }

            if (body_material === "") {
                setBody_material_error("Required");
            } else {
                setBody_material_error("");
            }
            if (trivet_material === "") {
                setTrivet_material_error("Required");
            } else {
                setTrivet_material_error("");
            }

            if (burner_material === "") {
                setBurner_material_error("Required");
            } else {
                setBurner_material_error("");
            }

            if (number_of_burners === "") {
                setNumber_of_burners_error("Required");
            } else {
                setNumber_of_burners_error("");
            }

            if (burner_type === "") {
                setBurner_type_error("Required");
            } else {
                setBurner_type_error("");
            }

            if (ignition_system === "") {
                setIgnition_system_error("Required");
            } else {
                setIgnition_system_error("");
            }

            if (gas_inlet_nozzle_tyle === "") {
                setGas_inlet_nozzle_tyle_error("Required");
            } else {
                setGas_inlet_nozzle_tyle_error("");
            }

            if (control_features === "") {
                setControl_features_error("Required");
            } else {
                setControl_features_error("");
            }

            if (spill_tray_included === "") {
                setSpill_tray_included_error("Required");
            } else {
                setSpill_tray_included_error("");
            }

            if (wear_resistant === "") {
                setWear_resistant_error("Required");
            } else {
                setWear_resistant_error("");
            }

            if (timer === "") {
                setTimer_error("Required");
            } else {
                setTimer_error("");
            }

            if (rust_proof === "") {
                setRust_proof_error("Required");
            } else {
                setRust_proof_error("");
            }

            if (support_mechanism === "") {
                setSupport_mechanism_error("Required");
            } else {
                setSupport_mechanism_error("");
            }

            if (safety_features === "") {
                setSafety_features_error("Required");
            } else {
                setSafety_features_error("");
            }

            if (certification === "") {
                setCertification_error("Required");
            } else {
                setCertification_error("");
            }

            if (width === "") {
                setWidth_error("Required");
            } else {
                setWidth_error("");
            }

            if (depth === "") {
                setDepth_error("Required");
            } else {
                setDepth_error("");
            }

            if (service_type === "") {
                setService_type_error("Required");
            } else {
                setService_type_error("");
            }

            if (opacity === "") {
                setOpacity_error("Required");
            } else {
                setOpacity_error("");
            }

            if (freezer_safe === "") {
                setFreezer_safe_error("Required");
            } else {
                setFreezer_safe_error("");
            }

            if (ideal_for === "") {
                setIdeal_for_error("Required");
            } else {
                setIdeal_for_error("");
            }

            if (with_handle === "") {
                setWith_handle_error("Required");
            } else {
                setWith_handle_error("");
            }

            if (handle_material === "") {
                setHandle_material_error("Required");
            } else {
                setHandle_material_error("");
            }

            if (widthxheightxdepth === "") {
                setWidthxheightxdepth_error("Required");
            } else {
                setWidthxheightxdepth_error("");
            }

            if (head_material === "") {
                setHead_material_error("Required");
            } else {
                setHead_material_error("");
            }

            if (number_of_contents_in_sales_package === "") {
                setNumber_of_contents_in_sales_package_error("Required");
            } else {
                setNumber_of_contents_in_sales_package_error("");
            }

            if (handle_with_care === "") {
                setHandle_with_care_error("Required");
            } else {
                setHandle_with_care_error("");
            }

            if (bpa_free === "") {
                setBpa_free_error("Required");
            } else {
                setBpa_free_error("");
            }

            if (leak_proof === "") {
                setLeak_proof_error("Required");
            } else {
                setLeak_proof_error("");
            }

            if (series === "") {
                setSeries_error("Required");
            } else {
                setSeries_error("");
            }

            if (character === "") {
                setCharacter_error("Required");
            } else {
                setCharacter_error("");
            }

            if (leak_resistant === "") {
                setLeak_resistant_error("Required");
            } else {
                setLeak_resistant_error("");
            }

            if (other_lunch_box_features === "") {
                setOther_lunch_box_features_error("Required");
            } else {
                setOther_lunch_box_features_error("");
            }

            if (international_warranty === "") {
                setInternational_warranty_error("Required");
            } else {
                setInternational_warranty_error("");
            }

            if (model_series_name === "") {
                setModel_series_name_error("Required");
            } else {
                setModel_series_name_error("");
            }

            if (bed_size === "") {
                setBed_size_error("Required");
            } else {
                setBed_size_error("");
            }

            if (delivery_condition === "") {
                setDelivery_condition_error("Required");
            } else {
                setDelivery_condition_error("");
            }

            if (primary_color === "") {
                setPrimary_color_error("Required");
            } else {
                setPrimary_color_error("");
            }

            if (with_mattress === "") {
                setWith_mattress_error("Required");
            } else {
                setWith_mattress_error("");
            }

            if (recommended_mattress_length === "") {
                setRecommended_mattress_length_error("Required");
            } else {
                setRecommended_mattress_length_error("");
            }

            if (recommended_mattress_breadth === "") {
                setRecommended_mattress_breadth_error("Required");
            } else {
                setRecommended_mattress_breadth_error("");
            }

            if (shape === "") {
                setShape_error("Required");
            } else {
                setShape_error("");
            }

            if (bed_material === "") {
                setBed_material_error("Required");
            } else {
                setBed_material_error("");
            }

            if (bed_material_subtype === "") {
                setBed_material_subtype_error("Required");
            } else {
                setBed_material_subtype_error("");
            }

            if (headboard_material === "") {
                setHeadboard_material_error("Required");
            } else {
                setHeadboard_material_error("");
            }

            if (headboard_material_subtype === "") {
                setHeadboard_material_subtype_error("Required");
            } else {
                setHeadboard_material_subtype_error("");
            }

            if (upholstery_included === "") {
                setUpholstery_included_error("Required");
            } else {
                setUpholstery_included_error("");
            }

            if (finish_type === "") {
                setFinish_type_error("Required");
            } else {
                setFinish_type_error("");
            }

            if (finish_color === "") {
                setFinish_color_error("Required");
            } else {
                setFinish_color_error("");
            }

            if (storage_type === "") {
                setStorage_type_error("Required");
            } else {
                setStorage_type_error("");
            }

            if (headboard_included === "") {
                setHeadboard_included_error("Required");
            } else {
                setHeadboard_included_error("");
            }

            if (floor_clearance === "") {
                setFloor_clearance_error("Required");
            } else {
                setFloor_clearance_error("");
            }

            if (installation_and_demo_details === "") {
                setInstallation_and_demo_details_error("Required");
            } else {
                setInstallation_and_demo_details_error("");
            }

            if (number_of_shelves === "") {
                setNumber_of_shelves_error("Required");
            } else {
                setNumber_of_shelves_error("");
            }

            if (rod_included === "") {
                setRod_included_error("Required");
            } else {
                setRod_included_error("");
            }

            if (bush_included === "") {
                setBush_included_error("Required");
            } else {
                setBush_included_error("");
            }

            if (enclosure_type === "") {
                setEnclosure_type_error("Required");
            } else {
                setEnclosure_type_error("");
            }

            if (mount_type === "") {
                setMount_type_error("Required");
            } else {
                setMount_type_error("");
            }

            if (number_of_rods === "") {
                setNumber_of_rods_error("Required");
            } else {
                setNumber_of_rods_error("");
            }
            if (cabinet_type === "") {
                setCabinet_type_error("Required");
            } else {
                setCabinet_type_error("");
            }
            if (support_layer === "") {
                setSupport_layer_error("Required");
            } else {
                setSupport_layer_error("");
            }
            if (comfort_layer === "") {
                setComfort_layer_error("Required");
            } else {
                setComfort_layer_error("");
            }
            if (mattress_top === "") {
                setMattress_top_error("Required");
            } else {
                setMattress_top_error("");
            }
            if (mattress_features === "") {
                setMattress_features_error("Required");
            } else {
                setMattress_features_error("");
            }
            if (comfort_levels === "") {
                setComfort_levels_error("Required");
            } else {
                setComfort_levels_error("");
            }

            if (upholstery_material === "") {
                setUpholstery_material_error("Required");
            } else {
                setUpholstery_material_error("");
            }

            if (removable_cover === "") {
                setRemovable_cover_error("Required");
            } else {
                setRemovable_cover_error("");
            }

            if (reinforced_edges === "") {
                setReinforced_edges_error("Required");
            } else {
                setReinforced_edges_error("");
            }

            if (thickness === "") {
                setThickness_error("Required");
            } else {
                setThickness_error("");
            }

            if (length === "") {
                setLength_error("Required");
            } else {
                setLength_error("");
            }

            if (secondary_material === "") {
                setSecondary_material_error("Required");
            } else {
                setSecondary_material_error("");
            }

            if (secondary_material_subtype === "") {
                setSecondary_material_subtype_error("Required");
            } else {
                setSecondary_material_subtype_error("");
            }

            if (configuration === "") {
                setConfiguration_error("Required");
            } else {
                setConfiguration_error("");
            }

            if (upholstery_material_subtype === "") {
                setUpholstery_material_subtype_error("Required");
            } else {
                setUpholstery_material_subtype_error("");
            }

            if (upholstery_material_color === "") {
                setUpholstery_material_color_error("Required");
            } else {
                setUpholstery_material_color_error("");
            }

            if (filling_material === "") {
                setFilling_material_error("Required");
            } else {
                setFilling_material_error("");
            }

            if (seating_capacity === "") {
                setSeating_capacity_error("Required");
            } else {
                setSeating_capacity_error("");
            }

            if (mattress_included === "") {
                setMattress_included_error("Required");
            } else {
                setMattress_included_error("");
            }

            if (mechanism_type === "") {
                setMechanism_type_error("Required");
            } else {
                setMechanism_type_error("");
            }

            if (upholstery_type === "") {
                setUpholstery_type_error("Required");
            } else {
                setUpholstery_type_error("");
            }

            if (mattress_type === "") {
                setMattress_type_error("Required");
            } else {
                setMattress_type_error("");
            }

            if (removable_leg === "") {
                setRemovable_leg_error("Required");
            } else {
                setRemovable_leg_error("");
            }

            if (frame_material === "") {
                setFrame_material_error("Required");
            } else {
                setFrame_material_error("");
            }

            if (frame_material_subtype === "") {
                setFrame_material_subtype_error("Required");
            } else {
                setFrame_material_subtype_error("");
            }

            if (accessories_included === "") {
                setAccessories_included_error("Required");
            } else {
                setAccessories_included_error("");
            }

            if (origin_of_manufacture === "") {
                setOrigin_of_manufacture_error("Required");
            } else {
                setOrigin_of_manufacture_error("");
            }

            if (with_door === "") {
                setWith_door_error("Required");
            } else {
                setWith_door_error("");
            }

            if (number_of_drawers === "") {
                setNumber_of_drawers_error("Required");
            } else {
                setNumber_of_drawers_error("");
            }

            if (style === "") {
                setStyle_error("Required");
            } else {
                setStyle_error("");
            }

            if (maximum_tv_size === "") {
                setMaximum_tv_size_error("Required");
            } else {
                setMaximum_tv_size_error("");
            }

            if (unit_material === "") {
                setUnit_material_error("Required");
            } else {
                setUnit_material_error("");
            }

            if (unit_material_type === "") {
                setUnit_material_type_error("Required");
            } else {
                setUnit_material_type_error("");
            }

            if (number_of_chairs === "") {
                setNumber_of_chairs_error("Required");
            } else {
                setNumber_of_chairs_error("");
            }

            if (table_shape === "") {
                setTable_shape_error("Required");
            } else {
                setTable_shape_error("");
            }

            if (wheels_included === "") {
                setWheels_included_error("Required");
            } else {
                setWheels_included_error("");
            }

            if (extendable === "") {
                setExtendable_error("Required");
            } else {
                setExtendable_error("");
            }

            if (foldable === "") {
                setFoldable_error("Required");
            } else {
                setFoldable_error("");
            }

            if (table_frame_material === "") {
                setTable_frame_material_error("Required");
            } else {
                setTable_frame_material_error("");
            }

            if (table_frame_material_subtype === "") {
                setTable_frame_material_subtype_error("Required");
            } else {
                setTable_frame_material_subtype_error("");
            }

            if (chair_material === "") {
                setChair_material_error("Required");
            } else {
                setChair_material_error("");
            }

            if (chair_width === "") {
                setChair_width_error("Required");
            } else {
                setChair_width_error("");
            }

            if (chair_height === "") {
                setChair_height_error("Required");
            } else {
                setChair_height_error("");
            }

            if (chair_depth === "") {
                setChair_depth_error("Required");
            } else {
                setChair_depth_error("");
            }

            if (chair_weight === "") {
                setChair_weight_error("Required");
            } else {
                setChair_weight_error("");
            }

            if (table_width === "") {
                setTable_width_error("Required");
            } else {
                setTable_width_error("");
            }

            if (table_height === "") {
                setTable_height_error("Required");
            } else {
                setTable_height_error("");
            }

            if (table_depth === "") {
                setTable_depth_error("Required");
            } else {
                setTable_depth_error("");
            }

            if (table_weight === "") {
                setTable_weight_error("Required");
            } else {
                setTable_weight_error("");
            }

            if (table_top_material === "") {
                setTable_top_material_error("Required");
            } else {
                setTable_top_material_error("");
            }

            if (table_top_material_subtype === "") {
                setTable_top_material_subtype_error("Required");
            } else {
                setTable_top_material_subtype_error("");
            }

            if (lock_provided === "") {
                setLock_provided_error("Required");
            } else {
                setLock_provided_error("");
            }

            if (wall_mount_support === "") {
                setWall_mount_support_error("Required");
            } else {
                setWall_mount_support_error("");
            }

            if (number_of_shoe_pairs === "") {
                setNumber_of_shoe_pairs_error("Required");
            } else {
                setNumber_of_shoe_pairs_error("");
            }
            if (material_subtype === "") {
                setMaterial_subtype_error("Required");
            } else {
                setMaterial_subtype_error("");
            }

            if (safety_instructions === "") {
                setSafety_instructions_error("Required");
            } else {
                setSafety_instructions_error("");
            }

            if (primary_material === "") {
                setPrimary_material_error("Required");
            } else {
                setPrimary_material_error("");
            }

            if (primary_material_subtype === "") {
                setPrimary_material_subtype_error("Required");
            } else {
                setPrimary_material_subtype_error("");
            }

            if (rollout_bed === "") {
                setRollout_bed_error("Required");
            } else {
                setRollout_bed_error("");
            }

            if (book_shelf_included === "") {
                setBook_shelf_included_error("Required");
            } else {
                setBook_shelf_included_error("");
            }

            if (built_in_ladder === "") {
                setBuilt_in_ladder_error("Required");
            } else {
                setBuilt_in_ladder_error("");
            }

            if (study_desk_available === "") {
                setStudy_desk_available_error("Required");
            } else {
                setStudy_desk_available_error("");
            }

            if (tent_included === "") {
                setTent_included_error("Required");
            } else {
                setTent_included_error("");
            }

            if (slide_included === "") {
                setSlide_included_error("Required");
            } else {
                setSlide_included_error("");
            }

            if (railing_included === "") {
                setRailing_included_error("Required");
            } else {
                setRailing_included_error("");
            }

            if (model === "") {
                setModel_error("Required");
            } else {
                setModel_error("");
            }

            if (cover_type === "") {
                setCover_type_error("Required");
            } else {
                setCover_type_error("");
            }

            if (filling_type === "") {
                setFilling_type_error("Required");
            } else {
                setFilling_type_error("");
            }

            if (filling_amount === "") {
                setFilling_amount_error("Required");
            } else {
                setFilling_amount_error("");
            }

            if (flat_sheet_length === "") {
                setFlat_sheet_length_error("Required");
            } else {
                setFlat_sheet_length_error("");
            }

            if (number_of_bedsheets === "") {
                setNumber_of_bedsheets_error("Required");
            } else {
                setNumber_of_bedsheets_error("");
            }

            if (thread_count === "") {
                setThread_count_error("Required");
            } else {
                setThread_count_error("");
            }

            if (model_id === "") {
                setModel_id_error("Required");
            } else {
                setModel_id_error("");
            }

            if (collection === "") {
                setCollection_error("Required");
            } else {
                setCollection_error("");
            }

            if (storage_included === "") {
                setStorage_included_error("Required");
            } else {
                setStorage_included_error("");
            }

            if (fabric_care === "") {
                setFabric_care_error("Required");
            } else {
                setFabric_care_error("");
            }

            if (machine_washable === "") {
                setMachine_washable_error("Required");
            } else {
                setMachine_washable_error("");
            }

            if (lining === "") {
                setLining_error("Required");
            } else {
                setLining_error("");
            }

            if (transparency === "") {
                setTransparency_error("Required");
            } else {
                setTransparency_error("");
            }

            if (external_material === "") {
                setExternal_material_error("Required");
            } else {
                setExternal_material_error("");
            }

            if (with_pillow_cover === "") {
                setWith_pillow_cover_error("Required");
            } else {
                setWith_pillow_cover_error("");
            }

            if (hand_washable === "") {
                setHand_washable_error("Required");
            } else {
                setHand_washable_error("");
            }

            if (warranty === "") {
                setWarranty_error("Required");
            } else {
                setWarranty_error("");
            }

            if (ideal_usage === "") {
                setIdeal_usage_error("Required");
            } else {
                setIdeal_usage_error("");
            }

            if (top_rating === "") {
                setTop_rating_error("Required");
            } else {
                setTop_rating_error("");
            }

            if (gsm === "") {
                setGsm_error("Required");
            } else {
                setGsm_error("");
            }

            if (weave_type === "") {
                setWeave_type_error("Required");
            } else {
                setWeave_type_error("");
            }

            if (outer_material === "") {
                setOuter_material_error("Required");
            } else {
                setOuter_material_error("");
            }

            if (design === "") {
                setDesign_error("Required");
            } else {
                setDesign_error("");
            }

            if (description === "") {
                setDescription_error("Required");
            } else {
                setDescription_error("");
            }
            if (pattern === "") {
                setPattern_error("Required");
            } else {
                setPattern_error("");
            }

            if (theme === "") {
                setTheme_error("Required");
            } else {
                setTheme_error("");
            }

            if (usage === "") {
                setUsage_error("Required");
            } else {
                setUsage_error("");
            }

            if (brand_color === "") {
                setBrand_color_error("Required");
            } else {
                setBrand_color_error("");
            }

            if (features === "") {
                setFeatures_error("Required");
            } else {
                setFeatures_error("");
            }

            if (key_features === "") {
                setKey_features_error("Required");
            } else {
                setKey_features_error("");
            }

            if (camera_type === "") {
                setCamera_type_error("Required");
            } else {
                setCamera_type_error("");
            }

            if (video_output === "") {
                setVideo_output_error("Required");
            } else {
                setVideo_output_error("");
            }

            if (video_recording_resolution === "") {
                setVideo_recording_resolution_error("Required");
            } else {
                setVideo_recording_resolution_error("");
            }

            if (video_recording_frame_rate === "") {
                setVideo_recording_frame_rate_error("Required");
            } else {
                setVideo_recording_frame_rate_error("");
            }

            if (mobile_compatibility === "") {
                setMobile_compatibility_error("Required");
            } else {
                setMobile_compatibility_error("");
            }

            if (lens_size === "") {
                setLens_size_error("Required");
            } else {
                setLens_size_error("");
            }

            if (angle_of_view === "") {
                setAngle_of_view_error("Required");
            } else {
                setAngle_of_view_error("");
            }

            if (mounting_type === "") {
                setMounting_type_error("Required");
            } else {
                setMounting_type_error("");
            }

            if (records_modes === "") {
                setRecords_modes_error("Required");
            } else {
                setRecords_modes_error("");
            }

            if (image_sensor === "") {
                setImage_sensor_error("Required");
            } else {
                setImage_sensor_error("");
            }

            if (ir_distance === "") {
                setIr_distance_error("Required");
            } else {
                setIr_distance_error("");
            }

            if (number_of_ir_leds === "") {
                setNumber_of_ir_leds_error("Required");
            } else {
                setNumber_of_ir_leds_error("");
            }

            if (hdd_available === "") {
                setHdd_available_error("Required");
            } else {
                setHdd_available_error("");
            }

            if (motion_triggered_recording === "") {
                setMotion_triggered_recording_error("Required");
            } else {
                setMotion_triggered_recording_error("");
            }

            if (power_source === "") {
                setPower_source_error("Required");
            } else {
                setPower_source_error("");
            }

            if (audio_output === "") {
                setAudio_output_error("Required");
            } else {
                setAudio_output_error("");
            }

            if (usb_port_present === "") {
                setUsb_port_present_error("Required");
            } else {
                setUsb_port_present_error("");
            }

            if (battery_type === "") {
                setBattery_type_error("Required");
            } else {
                setBattery_type_error("");
            }

            if (other_battery_features === "") {
                setOther_battery_features_error("Required");
            } else {
                setOther_battery_features_error("");
            }

            if (finish === "") {
                setFinish_error("Required");
            } else {
                setFinish_error("");
            }

            if (functions === "") {
                setFunctions_error("Required");
            } else {
                setFunctions_error("");
            }

            if (compatible_devices === "") {
                setCompatible_devices_error("Required");
            } else {
                setCompatible_devices_error("");
            }

            if (connectivity_type === "") {
                setConnectivity_type_error("Required");
            } else {
                setConnectivity_type_error("");
            }

            if (number_of_batteries === "") {
                setNumber_of_batteries_error("Required");
            } else {
                setNumber_of_batteries_error("");
            }

            if (frame_color === "") {
                setFrame_color_error("Required");
            } else {
                setFrame_color_error("");
            }

            if (wall_mount === "") {
                setWall_mount_error("Required");
            } else {
                setWall_mount_error("");
            }

            if (frame_included === "") {
                setFrame_included_error("Required");
            } else {
                setFrame_included_error("");
            }

            if (mount_mechanism === "") {
                setMount_mechanism_error("Required");
            } else {
                setMount_mechanism_error("");
            }

            if (ideal_use === "") {
                setIdeal_use_error("Required");
            } else {
                setIdeal_use_error("");
            }

            if (scratch_resistant === "") {
                setScratch_resistant_error("Required");
            } else {
                setScratch_resistant_error("");
            }
            if (decal_color === "") {
                setDecal_color_error("Required");
            } else {
                setDecal_color_error("");
            }

            if (sticker_type === "") {
                setSticker_type_error("Required");
            } else {
                setSticker_type_error("");
            }

            if (regional_speciality === "") {
                setRegional_speciality_error("Required");
            } else {
                setRegional_speciality_error("");
            }

            if (art_form_type === "") {
                setArt_form_type_error("Required");
            } else {
                setArt_form_type_error("");
            }

            if (sub_category === "") {
                setSub_category_error("Required");
            } else {
                setSub_category_error("");
            }

            if (pack_of_lamps === "") {
                setPack_of_lamps_error("Required");
            } else {
                setPack_of_lamps_error("");
            }

            if (blub_included === "") {
                setBlub_included_error("Required");
            } else {
                setBlub_included_error("");
            }

            if (blub_used === "") {
                setBlub_used_error("Required");
            } else {
                setBlub_used_error("");
            }

            if (adjustable === "") {
                setAdjustable_error("Required");
            } else {
                setAdjustable_error("");
            }

            if (blub_type === "") {
                setBlub_type_error("Required");
            } else {
                setBlub_type_error("");
            }

            if (with_shade === "") {
                setWith_shade_error("Required");
            } else {
                setWith_shade_error("");
            }

            if (light_color === "") {
                setLight_color_error("Required");
            } else {
                setLight_color_error("");
            }

            if (blub_shape === "") {
                setBlub_shape_error("Required");
            } else {
                setBlub_shape_error("");
            }

            if (energy_saving === "") {
                setEnergy_saving_error("Required");
            } else {
                setEnergy_saving_error("");
            }

            if (shatter_proof === "") {
                setShatter_proof_error("Required");
            } else {
                setShatter_proof_error("");
            }

            if (card_length === "") {
                setCard_length_error("Required");
            } else {
                setCard_length_error("");
            }

            if (number_of_bulb === "") {
                setNumber_of_bulb_error("Required");
            } else {
                setNumber_of_bulb_error("");
            }

            if (height === "") {
                setHeight_error("Required");
            } else {
                setHeight_error("");
            }


            if (light_used === "") {
                setLight_used_error("Required");
            } else {
                setLight_used_error("");
            }

            if (light_mode === "") {
                setLight_mode_error("Required");
            } else {
                setLight_mode_error("");
            }

            if (indicators === "") {
                setIndicators_error("Required");
            } else {
                setIndicators_error("");
            }

            if (charging_time === "") {
                setCharging_time_error("Required");
            } else {
                setCharging_time_error("");
            }

            if (maximum_number_of_recharges === "") {
                setMaximum_number_of_recharges_error("Required");
            } else {
                setMaximum_number_of_recharges_error("");
            }

            if (battery_capacity === "") {
                setBattery_capacity_error("Required");
            } else {
                setBattery_capacity_error("");
            }

            if (power_consumption === "") {
                setPower_consumption_error("Required");
            } else {
                setPower_consumption_error("");
            }

            if (power_requirement === "") {
                setPower_requirement_error("Required");
            } else {
                setPower_requirement_error("");
            }

            if (pet_type === "") {
                setPet_type_error("Required");
            } else {
                setPet_type_error("");
            }

            if (activity_type === "") {
                setActivity_type_error("Required");
            } else {
                setActivity_type_error("");
            }

            if (chewable === "") {
                setChewable_error("Required");
            } else {
                setChewable_error("");
            }
            if (led_toy === "") {
                setLed_toy_error("Required");
            } else {
                setLed_toy_error("");
            }

            if (usage_instructions === "") {
                setUsage_instructions_error("Required");
            } else {
                setUsage_instructions_error("");
            }

            if (collar_type === "") {
                setCollar_type_error("Required");
            } else {
                setCollar_type_error("");
            }

            if (harness_type === "") {
                setHarness_type_error("Required");
            } else {
                setHarness_type_error("");
            }

            if (retractable === "") {
                setRetractable_error("Required");
            } else {
                setRetractable_error("");
            }

            if (reversible === "") {
                setReversible_error("Required");
            } else {
                setReversible_error("");
            }

            if (net_length === "") {
                setNet_length_error("Required");
            } else {
                setNet_length_error("");
            }

            if (net_width === "") {
                setNet_width_error("Required");
            } else {
                setNet_width_error("");
            }

            if (flat_sheet_width === "") {
                setFlat_sheet_width_error("Required");
            } else {
                setFlat_sheet_width_error("");
            }

            if (pillow_cover_width === "") {
                setPillow_cover_width_error("Required");
            } else {
                setPillow_cover_width_error("");
            }

            if (pillow_cover_length === "") {
                setPillow_cover_length_error("Required");
            } else {
                setPillow_cover_length_error("");
            }

            if (with_frame === "") {
                setWith_frame_error("Required");
            } else {
                setWith_frame_error("");
            }

            if (mounting_handware_included === "") {
                setMounting_handware_included_error("Required");
            } else {
                setMounting_handware_included_error("");
            }

            if (water_proof === "") {
                setWater_proof_error("Required");
            } else {
                setWater_proof_error("");
            }

            if (battery === "") {
                setBattery_error("Required");
            } else {
                setBattery_error("");
            }

            if (dial_color === "") {
                setDial_color_error("Required");
            } else {
                setDial_color_error("");
            }

            if (fastener_type === "") {
                setFastener_type_error("Required");
            } else {
                setFastener_type_error("");
            }

            if (fastener_gauge === "") {
                setFastener_gauge_error("Required");
            } else {
                setFastener_gauge_error("");
            }

            if (fastener_length === "") {
                setFastener_length_error("Required");
            } else {
                setFastener_length_error("");
            }

            if (magazine_capacity === "") {
                setMagazine_capacity_error("Required");
            } else {
                setMagazine_capacity_error("");
            }

            if (assembly_required === "") {
                setAssembly_required_error("Required");
            } else {
                setAssembly_required_error("");
            }

            if (number_of_tools === "") {
                setNumber_of_tools_error("Required");
            } else {
                setNumber_of_tools_error("");
            }

            if (towel_rack === "") {
                setTowel_rack_error("Required");
            } else {
                setTowel_rack_error("");
            }

        }

    }, [
        buttonPress,
        distinctfieldValidate,
        pack_of,
        brand,
        model_number,
        model_name,
        type,
        size,
        color,
        material,
        other_features,
        number_of_scrubs,
        built_in_ladder,
        net_quantity,
        sales_package,
        capacity,
        pan_type,
        induction_bottom,
        lid_included, non_stick
        , dish_washer_safe, diameter,
        lid_locking_mechanism,
        other_body_and_design_features,
        shade,
        suitable_for,
        handled_features,
        base_thickness,
        covered_in_warranty,
        warranty_summary,
        not_covered_in_warranty,
        warranty_service_type,
        domestic_warranty,
        in_the_box,
        body_material,
        trivet_material,
        burner_material,
        number_of_burners,
        burner_type,
        ignition_system,
        gas_inlet_nozzle_tyle,
        control_features,
        spill_tray_included,
        wear_resistant,
        timer,
        rust_proof,
        support_mechanism,
        safety_features,
        certification,
        width,
        weightt,
        height,
        depth,
        service_type,
        opacity,
        freezer_safe,
        ideal_for,
        with_handle,
        handle_material,
        widthxheightxdepth,
        head_material,
        number_of_contents_in_sales_package,
        handle_with_care,
        bpa_free,
        leak_proof,
        series,
        character,
        leak_resistant,
        other_lunch_box_features,
        international_warranty,
        model_series_name,
        bed_size,
        delivery_condition,
        primary_color,
        storage_included,
        with_mattress,
        recommended_mattress_length,
        recommended_mattress_breadth,
        shape,
        bed_material,
        bed_material_subtype,
        headboard_material,
        headboard_material_subtype,
        upholstery_included,
        finish_type,
        finish_color,
        storage_type,
        headboard_included,
        floor_clearance,
        installation_and_demo_details,
        number_of_shelves,
        rod_included,
        bush_included,
        enclosure_type,
        mount_type,
        number_of_rods,
        cabinet_type,
        support_layer,
        comfort_layer,
        mattress_top,
        mattress_features,
        comfort_levels,
        upholstery_material,
        removable_cover,
        reinforced_edges,
        thickness,
        length,
        secondary_material,
        secondary_material_subtype,
        configuration,
        upholstery_material_subtype,
        upholstery_material_color,
        filling_material,
        seating_capacity,
        mattress_included,
        mechanism_type,
        upholstery_type,
        mattress_type,
        removable_leg,
        frame_material,
        frame_material_subtype,
        accessories_included,
        origin_of_manufacture,
        with_door,
        number_of_drawers,
        style,
        maximum_tv_size,
        unit_material,
        unit_material_type,
        number_of_chairs,
        table_shape,
        wheels_included,
        extendable,
        foldable,
        table_frame_material,
        table_frame_material_subtype,
        chair_material,
        chair_width,
        chair_height,
        chair_depth,
        chair_weight,
        table_width,
        table_height,
        table_depth,
        table_weight,
        table_top_material,
        table_top_material_subtype,
        lock_provided,
        wall_mount_support,
        number_of_shoe_pairs,
        material_subtype,
        safety_instructions,
        primary_material,
        primary_material_subtype,
        rollout_bed,
        book_shelf_included,
        study_desk_available,
        tent_included,
        slide_included,
        railing_included,
        model, cover_type,
        filling_type,
        filling_amount,
        flat_sheet_length,
        number_of_bedsheets,
        thread_count,
        model_id,
        collection,
        fabric_care,
        machine_washable,
        lining,
        reversible,
        external_material,
        with_pillow_cover,
        hand_washable,
        warranty,
        ideal_usage,
        top_rating,
        gsm,
        weave_type,
        outer_material,
        design,
        description,
        pattern,
        theme,
        usage,
        brand_color,
        features,
        key_features,
        camera_type,
        video_output,
        video_recording_resolution,
        video_recording_frame_rate,
        mobile_compatibility,
        lens_size,
        angle_of_view,
        mounting_type,
        records_modes,
        image_sensor,
        ir_distance,
        number_of_ir_leds,
        hdd_available,
        motion_triggered_recording,
        power_source,
        audio_output,
        usb_port_present,
        battery_type,
        other_battery_features,
        finish,
        functions,
        compatible_devices,
        connectivity_type,
        number_of_batteries,
        frame_color,
        wall_mount,
        frame_included,
        mount_mechanism,
        ideal_use,
        scratch_resistant,
        decal_color,
        sticker_type,
        regional_speciality,
        art_form_type,
        sub_category,
        pack_of_lamps,
        blub_included,
        blub_used,
        adjustable,
        blub_type,
        with_shade,
        light_color,
        blub_shape,
        energy_saving,
        shatter_proof,
        card_length,
        number_of_bulb,
        light_used,
        assembly_required,
        light_mode, indicators,
        charging_time,
        battery_capacity,
        power_consumption,
        power_requirement,
        pet_type,
        activity_type,
        chewable,
        led_toy,
        usage_instructions,
        pet_type,
        collar_type,
        harness_type,
        retractable,
        reversible,
        net_length,
        net_width,
        flat_sheet_width,
        pillow_cover_width,
        pillow_cover_length,
        with_frame,
        water_proof,
        battery,
        dial_color,
        fastener_type,
        fastener_gauge,
        fastener_length,
        magazine_capacity,
        assembly_required,
        number_of_tools,
        towel_rack
    ])


    return (
        <>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Flex wrap="wrap">
                    {distinctfield.includes("brand") && (
                        <Col className="gutter-row" span={12} >
                            <span>Brand :</span>
                            <Input
                                status={brand_error !== "" ? "error" : ""}
                                suffix={brand_error !== "" ? <MdOutlineErrorOutline /> : ""}
                                type="text"
                                placeholder="Enter brand"
                                value={brand}
                                onChange={(e) => {
                                    setBrand(e.target.value);
                                    updateValues("brand", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("weight") && (
                        <Col className="gutter-row" span={12}>
                            <span>Weight :</span>
                            <Input
                                status={weightt_error !== "" ? "error" : ""}
                                suffix={weightt_error !== "" ? <MdOutlineErrorOutline /> : ""}
                                type="text"
                                placeholder="Enter Weight"
                                value={weightt}
                                onChange={(e) => {
                                    setWeightt(e.target.value);
                                    updateValues("weight", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("model_number") && (
                        <Col className="gutter-row" span={12}>
                            <span>Model Number:</span>
                            <Input
                                status={model_number_error !== "" ? "error" : ""}
                                suffix={
                                    model_number_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Model Number"
                                value={model_number}
                                onChange={(e) => {
                                    setModel_number(e.target.value);
                                    updateValues("model_number", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("model_name") && (
                        <Col className="gutter-row" span={12}>
                            <span>Model Name:</span>
                            <Input
                                status={model_name_error !== "" ? "error" : ""}
                                suffix={
                                    model_name_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Model Name"
                                value={model_name}
                                onChange={(e) => {
                                    setModel_name(e.target.value);
                                    updateValues("model_name", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("other_features") && (
                        <Col className="gutter-row" span={12}>
                            <span>Other features:</span>
                            <Input
                                status={other_features_error !== "" ? "error" : ""}
                                suffix={
                                    other_features_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Other features"
                                value={other_features}
                                onChange={(e) => {
                                    setOther_features(e.target.value);
                                    updateValues("other_features", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("sales_package") && (
                        <Col className="gutter-row" span={12}>
                            <span>Sales Package:</span>
                            <Input
                                status={sales_package_error !== "" ? "error" : ""}
                                suffix={
                                    sales_package_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Sales Package"
                                value={sales_package}
                                onChange={(e) => {
                                    setSales_package(e.target.value);
                                    updateValues("sales_package", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>

                <Flex wrap="wrap">
                    {distinctfield.includes("covered_in_warranty") && (
                        <Col className="gutter-row" span={12}>
                            <span>Covered in warranty:</span>
                            <Input
                                status={covered_in_warranty_error !== "" ? "error" : ""}
                                suffix={
                                    covered_in_warranty_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Covered in warranty"
                                value={covered_in_warranty}
                                onChange={(e) => {
                                    setCovered_in_warranty(e.target.value);
                                    updateValues("covered_in_warranty", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("warranty_summary") && (
                        <Col className="gutter-row" span={12}>
                            <span>Warranty summary:</span>
                            <Input
                                status={warranty_summary_error !== "" ? "error" : ""}
                                suffix={
                                    warranty_summary_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Warranty summary"
                                value={warranty_summary}
                                onChange={(e) => {
                                    setWarranty_summary(e.target.value);
                                    updateValues("warranty_summary", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("domestic_warranty") && (
                        <Col className="gutter-row" span={12}>
                            <span>Domestic Warranty:</span>
                            <Input
                                status={domestic_warranty_error !== "" ? "error" : ""}
                                suffix={
                                    domestic_warranty_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Domestic Warranty"
                                value={domestic_warranty}
                                onChange={(e) => {
                                    setDomestic_warranty(e.target.value);
                                    updateValues("domestic_warranty", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("in_the_box") && (
                        <Col className="gutter-row" span={12}>
                            <span>In the box:</span>
                            <Input
                                status={in_the_box_error !== "" ? "error" : ""}
                                suffix={
                                    in_the_box_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter In the box"
                                value={in_the_box}
                                onChange={(e) => {
                                    setIn_the_box(e.target.value);
                                    updateValues("in_the_box", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("safety_features") && (
                        <Col className="gutter-row" span={12}>
                            <span>Safety Features:</span>
                            <Input
                                status={safety_features_error !== "" ? "error" : ""}
                                suffix={
                                    safety_features_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Safety Features"
                                value={safety_features}
                                onChange={(e) => {
                                    setSafety_features(e.target.value);
                                    updateValues("safety_features", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("service_type") && (
                        <Col className="gutter-row" span={12}>
                            <span>Service Type:</span>
                            <Input
                                status={service_type_error !== "" ? "error" : ""}
                                suffix={
                                    service_type_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Service Type"
                                value={service_type}
                                onChange={(e) => {
                                    setService_type(e.target.value);
                                    updateValues("service_type", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>

                <Flex wrap="wrap">
                    {distinctfield.includes("widthxheightxdepth") && (
                        <Col className="gutter-row" span={12}>
                            <span>widthxheightxdepth:</span>
                            <Input
                                status={widthxheightxdepth_error !== "" ? "error" : ""}
                                suffix={
                                    widthxheightxdepth_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter widthxheightxdepth"
                                value={widthxheightxdepth}
                                onChange={(e) => {
                                    setWidthxheightxdepth(e.target.value);
                                    updateValues("widthxheightxdepth", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("handle_with_care") && (
                        <Col className="gutter-row" span={12}>
                            <span>Handle With Care:</span>
                            <Input
                                status={handle_with_care_error !== "" ? "error" : ""}
                                suffix={
                                    handle_with_care_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Handle With Care"
                                value={handle_with_care}
                                onChange={(e) => {
                                    setHandle_with_care(e.target.value);
                                    updateValues("handle_with_care", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("other_lunch_box_features") && (
                        <Col className="gutter-row" span={12}>
                            <span>Other lunch box Features:</span>
                            <Input
                                status={other_lunch_box_features_error !== "" ? "error" : ""}
                                suffix={
                                    other_lunch_box_features_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Other lunch box Features"
                                value={other_lunch_box_features}
                                onChange={(e) => {
                                    setOther_lunch_box_features(e.target.value);
                                    updateValues("other_lunch_box_features", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("international_warranty") && (
                        <Col className="gutter-row" span={12}>
                            <span>International Warranty:</span>
                            <Input
                                status={international_warranty_error !== "" ? "error" : ""}
                                suffix={
                                    international_warranty_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter International Warranty"
                                value={international_warranty}
                                onChange={(e) => {
                                    setInternational_warranty(e.target.value);
                                    updateValues("international_warranty", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("height") && (
                        <Col className="gutter-row" span={12}>
                            <span>Height:</span>
                            <Input
                                status={height_error !== "" ? "error" : ""}
                                suffix={
                                    height_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Height"
                                value={height}
                                onChange={(e) => {
                                    setHeight(e.target.value);
                                    updateValues("height", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("installation_and_demo_details") && (
                        <Col className="gutter-row" span={12}>
                            <span>Installation and Demo Details:</span>
                            <Input
                                status={installation_and_demo_details_error !== "" ? "error" : ""}
                                suffix={
                                    installation_and_demo_details_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Installation and Demo Details"
                                value={installation_and_demo_details}
                                onChange={(e) => {
                                    setInstallation_and_demo_details(e.target.value);
                                    updateValues("installation_and_demo_details", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("safety_instructions") && (
                        <Col className="gutter-row" span={12}>
                            <span>Safety Instructions:</span>
                            <Input
                                status={safety_instructions_error !== "" ? "error" : ""}
                                suffix={
                                    safety_instructions_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Safety Instructions"
                                value={safety_instructions}
                                onChange={(e) => {
                                    setSafety_instructions(e.target.value);
                                    updateValues("safety_instructions", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("model") && (
                        <Col className="gutter-row" span={12}>
                            <span>Model:</span>
                            <Input
                                status={model_error !== "" ? "error" : ""}
                                suffix={
                                    model_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Model"
                                value={model}
                                onChange={(e) => {
                                    setModel(e.target.value);
                                    updateValues("model", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("model_id") && (
                        <Col className="gutter-row" span={12}>
                            <span>Model Id:</span>
                            <Input
                                status={model_id_error !== "" ? "error" : ""}
                                suffix={
                                    model_id_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Model Id"
                                value={model_id}
                                onChange={(e) => {
                                    setModel_id(e.target.value);
                                    updateValues("model_id", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("pack_of") && (
                        <Col className="gutter-row" span={12}>
                            <span>Pack of:</span>
                            <Input
                                status={pack_of_error !== "" ? "error" : ""}
                                suffix={
                                    pack_of_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Pack of"
                                value={pack_of}
                                onChange={(e) => {
                                    setPack_of(e.target.value);
                                    updateValues("pack_of", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("features") && (
                        <Col className="gutter-row" span={12}>
                            <span>Features:</span>
                            <Input
                                status={features_error !== "" ? "error" : ""}
                                suffix={
                                    features_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Features"
                                value={features}
                                onChange={(e) => {
                                    setFeatures(e.target.value);
                                    updateValues("features", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("regional_speciality") && (
                        <Col className="gutter-row" span={12}>
                            <span>Regional Speciality:</span>
                            <Input
                                status={regional_speciality_error !== "" ? "error" : ""}
                                suffix={
                                    regional_speciality_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Regional Speciality"
                                value={regional_speciality}
                                onChange={(e) => {
                                    setRegional_speciality(e.target.value);
                                    updateValues("regional_speciality", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("power_consumption") && (
                        <Col className="gutter-row" span={12}>
                            <span>Power Consumption:</span>
                            <Input
                                status={power_consumption_error !== "" ? "error" : ""}
                                suffix={
                                    power_consumption_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Power Consumption"
                                value={power_consumption}
                                onChange={(e) => {
                                    setPower_consumption(e.target.value);
                                    updateValues("power_consumption", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("power_requirement") && (
                        <Col className="gutter-row" span={12}>
                            <span>Power Requirement :</span>
                            <Input
                                status={power_requirement_error !== "" ? "error" : ""}
                                suffix={
                                    power_requirement_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Power Requirement"
                                value={power_requirement}
                                onChange={(e) => {
                                    setPower_requirement(e.target.value);
                                    updateValues("power_requirement", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("usage_instructions") && (
                        <Col className="gutter-row" span={12}>
                            <span>Usage Instructions:</span>
                            <Input
                                status={usage_instructions_error !== "" ? "error" : ""}
                                suffix={
                                    usage_instructions_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Usage Instructions"
                                value={usage_instructions}
                                onChange={(e) => {
                                    setUsage_instructions(e.target.value);
                                    updateValues("usage_instructions", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("other_body_and_design_features") && (
                        <Col className="gutter-row" span={12}>
                            <span>Other Body And Design Features:</span>
                            <Input
                                status={other_body_and_design_features_error !== "" ? "error" : ""}
                                suffix={
                                    other_body_and_design_features_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Other Body And Design Features"
                                value={other_body_and_design_features}
                                onChange={(e) => {
                                    setOther_body_and_design_features(e.target.value);
                                    updateValues("other_body_and_design_features", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("ideal_use") && (
                        <Col className="gutter-row" span={12}>
                            <span>Ideal Use:</span>
                            <Input
                                status={ideal_use_error !== "" ? "error" : ""}
                                suffix={
                                    ideal_use_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Ideal Use"
                                value={ideal_use}
                                onChange={(e) => {
                                    setIdeal_use(e.target.value);
                                    updateValues("ideal_use", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("other_battery_features") && (
                        <Col className="gutter-row" span={12}>
                            <span>Other Battery Features:</span>
                            <Input
                                status={other_battery_features_error !== "" ? "error" : ""}
                                suffix={
                                    other_battery_features_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Other Battery Features"
                                value={other_battery_features}
                                onChange={(e) => {
                                    setOther_battery_features(e.target.value);
                                    updateValues("other_battery_features", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("description") && (
                        <Col className="gutter-row" span={12}>
                            <span>Description:</span>
                            <Input
                                status={description_error !== "" ? "error" : ""}
                                suffix={
                                    description_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Description"
                                value={description}
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                    updateValues("description", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("warranty_service_type") && (
                        <Col className="gutter-row" span={12}>
                            <span>Warranty Service Type:</span>
                            <Input
                                status={warranty_service_type_error !== "" ? "error" : ""}
                                suffix={
                                    warranty_service_type_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Warranty Service Type"
                                value={warranty_service_type}
                                onChange={(e) => {
                                    setWarranty_service_type(e.target.value);
                                    updateValues("warranty_service_type", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("not_covered_in_warranty") && (
                        <Col className="gutter-row" span={12}>
                            <span>Not covered in warranty:</span>
                            <Input
                                status={not_covered_in_warranty_error !== "" ? "error" : ""}
                                suffix={
                                    not_covered_in_warranty_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Not covered in warranty"
                                value={not_covered_in_warranty}
                                onChange={(e) => {
                                    setNot_covered_in_warranty(e.target.value);
                                    updateValues("not_covered_in_warranty", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>

                <Flex wrap="wrap">
                    {distinctfield.includes("type") && (
                        <Col className="gutter-row" span={12}>
                            <span>Type:</span>
                            <Input
                                status={type_error !== "" ? "error" : ""}
                                suffix={
                                    type_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Type"
                                value={type}
                                onChange={(e) => {
                                    setType(e.target.value);
                                    updateValues("type", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>



                {/* above all are input */}

                <Flex wrap="wrap">
                    {distinctfield.includes("size") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Size"
                                hasFeedback
                                validateStatus={size_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Size"
                                    value={size}
                                    onChange={(value) => {
                                        setSize(value);
                                        updateValues("size", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Size.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={size === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("with_mattress") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="With Mattress"
                                hasFeedback
                                validateStatus={with_mattress_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select  With Mattress"
                                    value={with_mattress}
                                    onChange={(value) => {
                                        setWith_mattress(value);
                                        updateValues("with_mattress", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={size === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("color") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Color"
                                hasFeedback
                                validateStatus={color_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Color"
                                    value={color}
                                    onChange={(value) => {
                                        setColor(value);
                                        updateValues("color", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Color.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={color === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Material"
                                hasFeedback
                                validateStatus={material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Material"
                                    value={material}
                                    onChange={(value) => {
                                        setMaterial(value);
                                        updateValues("material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("number_of_scrubs") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Number of scrubs"
                                hasFeedback
                                validateStatus={number_of_scrubs_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Number of scrubs"
                                    value={number_of_scrubs}
                                    onChange={(value) => {
                                        setNumber_of_scrubs(value);
                                        updateValues("number_of_scrubs", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Number_of_scrubs.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={number_of_scrubs === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("net_quantity") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Net quantity"
                                hasFeedback
                                validateStatus={net_quantity_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Net quantity"
                                    value={net_quantity}
                                    onChange={(value) => {
                                        setNet_quantity(value);
                                        updateValues("net_quantity", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Net_quantity.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={net_quantity === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>

                <Flex wrap="wrap">
                    {distinctfield.includes("capacity") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Capacity"
                                hasFeedback
                                validateStatus={capacity_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Capacity"
                                    value={capacity}
                                    onChange={(value) => {
                                        setCapacity(value);
                                        updateValues("capacity", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Capacity.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={capacity === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("pan_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Pan Type"
                                hasFeedback
                                validateStatus={pan_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Pan Type"
                                    value={pan_type}
                                    onChange={(value) => {
                                        setPan_type(value);
                                        updateValues("pan_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {PanType.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={pan_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("induction_bottom") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Induction Bottom"
                                hasFeedback
                                validateStatus={induction_bottom_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Induction Bottom"
                                    value={induction_bottom}
                                    onChange={(value) => {
                                        setInduction_bottom(value);
                                        updateValues("induction_bottom", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={induction_bottom === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("lid_included") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Lid Included"
                                hasFeedback
                                validateStatus={lid_included_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Lid Included"
                                    value={lid_included}
                                    onChange={(value) => {
                                        setLid_included(value);
                                        updateValues("lid_included", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={lid_included === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("non_stick") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Non Stick"
                                hasFeedback
                                validateStatus={non_stick_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Non Stick"
                                    value={non_stick}
                                    onChange={(value) => {
                                        setNon_stick(value);
                                        updateValues("non_stick", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={non_stick === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("dish_washer_safe") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Dish washer safe"
                                hasFeedback
                                validateStatus={dish_washer_safe_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Dish washer safe"
                                    value={dish_washer_safe}
                                    onChange={(value) => {
                                        setDish_washer_safe(value);
                                        updateValues("dish_washer_safe", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={dish_washer_safe === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>     
                <Flex wrap="wrap">
                    {distinctfield.includes("lid_locking_mechanism") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Lid Locking Mechanism"
                                hasFeedback
                                validateStatus={lid_locking_mechanism_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Lid Locking Mechanism"
                                    value={lid_locking_mechanism}
                                    onChange={(value) => {
                                        setLid_locking_mechanism(value);
                                        updateValues("lid_locking_mechanism", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Lid_locking_mechanism.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={lid_locking_mechanism === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("shade") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Shade"
                                hasFeedback
                                validateStatus={shade_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Shade"
                                    value={shade}
                                    onChange={(value) => {
                                        setShade(value);
                                        updateValues("shade", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Shade.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={shade === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("suitable_for") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Suitable For"
                                hasFeedback
                                validateStatus={suitable_for_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Suitable For"
                                    value={suitable_for}
                                    onChange={(value) => {
                                        setSuitable_for(value);
                                        updateValues("suitable_for", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Suitable_for.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={suitable_for === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("handled_features") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Handled Features"
                                hasFeedback
                                validateStatus={handled_features_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Handled Features"
                                    value={handled_features}
                                    onChange={(value) => {
                                        setHandled_features(value);
                                        updateValues("handled_features", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Handled_features.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={handled_features === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("base_thickness") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Base Thickness"
                                hasFeedback
                                validateStatus={base_thickness_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Base Thickness"
                                    value={base_thickness}
                                    onChange={(value) => {
                                        setBase_thickness(value);
                                        updateValues("base_thickness", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Base_thickness.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={base_thickness === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>



                <Flex wrap="wrap">
                    {distinctfield.includes("body_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Body Material"
                                hasFeedback
                                validateStatus={body_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Body Material"
                                    value={body_material}
                                    onChange={(value) => {
                                        setBody_material(value);
                                        updateValues("body_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Body_material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={body_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("trivet_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Trivet Material"
                                hasFeedback
                                validateStatus={trivet_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Trivet Material"
                                    value={trivet_material}
                                    onChange={(value) => {
                                        setTrivet_material(value);
                                        updateValues("trivet_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Trivet_material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={trivet_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("burner_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Burner Material"
                                hasFeedback
                                validateStatus={burner_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Burner Material"
                                    value={burner_material}
                                    onChange={(value) => {
                                        setBurner_material(value);
                                        updateValues("burner_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Burner_material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={burner_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("number_of_burners") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Number of Burners"
                                hasFeedback
                                validateStatus={number_of_burners_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Number of Burners"
                                    value={number_of_burners}
                                    onChange={(value) => {
                                        setNumber_of_burners(value);
                                        updateValues("number_of_burners", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Number_of_burners.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={number_of_burners === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("burner_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Burner Type"
                                hasFeedback
                                validateStatus={burner_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Burner Type"
                                    value={burner_type}
                                    onChange={(value) => {
                                        setBurner_type(value);
                                        updateValues("burner_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Burner_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={burner_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("ignition_system") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Ignition System"
                                hasFeedback
                                validateStatus={ignition_system_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Ignition System"
                                    value={ignition_system}
                                    onChange={(value) => {
                                        setIgnition_system(value);
                                        updateValues("ignition_system", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Ignition_system.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={ignition_system === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("gas_inlet_nozzle_tyle") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Gas inlet nozzle tyle"
                                hasFeedback
                                validateStatus={gas_inlet_nozzle_tyle_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Gas inlet nozzle tyle"
                                    value={gas_inlet_nozzle_tyle}
                                    onChange={(value) => {
                                        setGas_inlet_nozzle_tyle(value);
                                        updateValues("gas_inlet_nozzle_tyle", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Gas_inlet_nozzle_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={gas_inlet_nozzle_tyle === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("control_features") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Control Features"
                                hasFeedback
                                validateStatus={control_features_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Control Features"
                                    value={control_features}
                                    onChange={(value) => {
                                        setControl_features(value);
                                        updateValues("control_features", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Control_features.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={control_features === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("spill_tray_included") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Spill Tray Included"
                                hasFeedback
                                validateStatus={spill_tray_included_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Spill Tray Included"
                                    value={spill_tray_included}
                                    onChange={(value) => {
                                        setSpill_tray_included(value);
                                        updateValues("spill_tray_included", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={spill_tray_included === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("wear_resistant") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Wear Resistant"
                                hasFeedback
                                validateStatus={wear_resistant_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Wear Resistant"
                                    value={wear_resistant}
                                    onChange={(value) => {
                                        setWear_resistant(value);
                                        updateValues("wear_resistant", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={wear_resistant === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("timer") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="timer"
                                hasFeedback
                                validateStatus={timer_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select timer"
                                    value={timer}
                                    onChange={(value) => {
                                        setTimer(value);
                                        updateValues("timer", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={timer === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("rust_proof") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Rust Proof"
                                hasFeedback
                                validateStatus={rust_proof_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Rust Proof"
                                    value={rust_proof}
                                    onChange={(value) => {
                                        setRust_proof(value);
                                        updateValues("rust_proof", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={rust_proof === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("support_mechanism") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Support Mechanism"
                                hasFeedback
                                validateStatus={support_mechanism_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Support Mechanism"
                                    value={support_mechanism}
                                    onChange={(value) => {
                                        setSupport_mechanism(value);
                                        updateValues("support_mechanism", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Support_mechanism.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={support_mechanism === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("certification") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Certification"
                                hasFeedback
                                validateStatus={certification_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Certification"
                                    value={certification}
                                    onChange={(value) => {
                                        setCertification(value);
                                        updateValues("certification", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={certification === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("width") && (
                        <Col className="gutter-row" span={12}>
                            <span>width:</span>
                            <Input
                                status={width_error !== "" ? "error" : ""}
                                suffix={
                                    width_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter width"
                                value={width}
                                onChange={(e) => {
                                    setWidth(e.target.value);
                                    updateValues("width", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("diameter") && (
                        <Col className="gutter-row" span={12}>
                            <span>Diameter:</span>
                            <Input
                                status={diameter_error !== "" ? "error" : ""}
                                suffix={
                                    diameter_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Diameter"
                                value={diameter}
                                onChange={(e) => {
                                    setDiameter(e.target.value);
                                    updateValues("diameter", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("depth") && (
                        <Col className="gutter-row" span={12}>
                            <span>Depth:</span>
                            <Input
                                status={depth_error !== "" ? "error" : ""}
                                suffix={
                                    depth_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Depth"
                                value={depth}
                                onChange={(e) => {
                                    setDepth(e.target.value);
                                    updateValues("depth", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("opacity") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Opacity"
                                hasFeedback
                                validateStatus={opacity_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Opacity"
                                    value={opacity}
                                    onChange={(value) => {
                                        setOpacity(value);
                                        updateValues("opacity", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Opacity.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={opacity === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("freezer_safe") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Freezer Safe"
                                hasFeedback
                                validateStatus={freezer_safe_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Freezer Safe"
                                    value={freezer_safe}
                                    onChange={(value) => {
                                        setFreezer_safe(value);
                                        updateValues("freezer_safe", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={freezer_safe === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("ideal_for") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Ideal For"
                                hasFeedback
                                validateStatus={ideal_for_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Ideal For"
                                    value={ideal_for}
                                    onChange={(value) => {
                                        setIdeal_for(value);
                                        updateValues("ideal_for", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Ideal_for.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={ideal_for === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("with_handle") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="With Handle"
                                hasFeedback
                                validateStatus={with_handle_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select With Handle"
                                    value={with_handle}
                                    onChange={(value) => {
                                        setWith_handle(value);
                                        updateValues("with_handle", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={with_handle === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("handle_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Handle Material"
                                hasFeedback
                                validateStatus={handle_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Handle Material"
                                    value={handle_material}
                                    onChange={(value) => {
                                        setHandle_material(value);
                                        updateValues("handle_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Handle_material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={handle_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("head_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Head Material"
                                hasFeedback
                                validateStatus={head_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Head Material"
                                    value={head_material}
                                    onChange={(value) => {
                                        setHead_material(value);
                                        updateValues("head_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Head_material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={head_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("number_of_contents_in_sales_package") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Number of Contents in Sales Package"
                                hasFeedback
                                validateStatus={number_of_contents_in_sales_package_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Number of Contents in Sales Package"
                                    value={number_of_contents_in_sales_package}
                                    onChange={(value) => {
                                        setNumber_of_contents_in_sales_package(value);
                                        updateValues("number_of_contents_in_sales_package", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Number_of_contents_in_sales_package.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={number_of_contents_in_sales_package === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("bpa_free") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="BPA Free"
                                hasFeedback
                                validateStatus={bpa_free_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select BPA Free"
                                    value={bpa_free}
                                    onChange={(value) => {
                                        setBpa_free(value);
                                        updateValues("bpa_free", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={bpa_free === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("leak_proof") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Leak Proof"
                                hasFeedback
                                validateStatus={leak_proof_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Leak Proof"
                                    value={leak_proof}
                                    onChange={(value) => {
                                        setLeak_proof(value);
                                        updateValues("leak_proof", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={leak_proof === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("series") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Series"
                                hasFeedback
                                validateStatus={series_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Series"
                                    value={series}
                                    onChange={(value) => {
                                        setSeries(value);
                                        updateValues("series", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Series.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={series === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("character") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Character"
                                hasFeedback
                                validateStatus={character_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Character"
                                    value={character}
                                    onChange={(value) => {
                                        setCharacter(value);
                                        updateValues("character", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Charcter.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={character === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("leak_resistant") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Leak Resistant"
                                hasFeedback
                                validateStatus={leak_resistant_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Leak Resistant"
                                    value={leak_resistant}
                                    onChange={(value) => {
                                        setLeak_resistant(value);
                                        updateValues("leak_resistant", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Leak_resistance.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={leak_resistant === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("model_series_name") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Model Series Name"
                                hasFeedback
                                validateStatus={model_series_name_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Model Series Name"
                                    value={model_series_name}
                                    onChange={(value) => {
                                        setModel_series_name(value);
                                        updateValues("model_series_name", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Model_series_name.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={model_series_name === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("bed_size") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Bed Size"
                                hasFeedback
                                validateStatus={bed_size_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Bed Size"
                                    value={bed_size}
                                    onChange={(value) => {
                                        setBed_size(value);
                                        updateValues("bed_size", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Bed_size.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={bed_size === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("delivery_condition") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Delivery Condition"
                                hasFeedback
                                validateStatus={delivery_condition_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Delivery Condition"
                                    value={delivery_condition}
                                    onChange={(value) => {
                                        setDelivery_condition(value);
                                        updateValues("delivery_condition", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Delivery_condition.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={delivery_condition === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("primary_color") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Primary Color"
                                hasFeedback
                                validateStatus={primary_color_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Primary Color"
                                    value={primary_color}
                                    onChange={(value) => {
                                        setPrimary_color(value);
                                        updateValues("primary_color", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Primary_color.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={primary_color === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("storage_included") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Storage Included"
                                hasFeedback
                                validateStatus={storage_included_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Storage Included"
                                    value={storage_included}
                                    onChange={(value) => {
                                        setStorage_included(value);
                                        updateValues("storage_included", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={storage_included === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>

                <Flex wrap="wrap">
                    {distinctfield.includes("recommended_mattress_length") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Recommended Mattress Length"
                                hasFeedback
                                validateStatus={recommended_mattress_length_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Recommended Mattress Length"
                                    value={recommended_mattress_length}
                                    onChange={(value) => {
                                        setRecommended_mattress_length(value);
                                        updateValues("recommended_mattress_length", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Recommended_mattress_length.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={recommended_mattress_length === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>

                <Flex wrap="wrap">
                    {distinctfield.includes("recommended_mattress_breadth") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Recommended Mattress Breadth"
                                hasFeedback
                                validateStatus={recommended_mattress_breadth_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Recommended Mattress Breadth"
                                    value={recommended_mattress_breadth}
                                    onChange={(value) => {
                                        setRecommended_mattress_breadth(value);
                                        updateValues("recommended_mattress_breadth", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Recommended_mattress_breadth.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={recommended_mattress_breadth === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("shape") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Shape"
                                hasFeedback
                                validateStatus={shape_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Shape"
                                    value={shape}
                                    onChange={(value) => {
                                        setShape(value);
                                        updateValues("shape", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Shape.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={shape === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("bed_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Bed Material"
                                hasFeedback
                                validateStatus={bed_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Bed Material"
                                    value={bed_material}
                                    onChange={(value) => {
                                        setBed_material(value);
                                        updateValues("bed_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Bed_material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={bed_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("bed_material_subtype") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Bed Material Subtype"
                                hasFeedback
                                validateStatus={bed_material_subtype_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Bed Material Subtype"
                                    value={bed_material_subtype}
                                    onChange={(value) => {
                                        setBed_material_subtype(value);
                                        updateValues("bed_material_subtype", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Bed_material_sub_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={bed_material_subtype === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("headboard_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Headboard Material"
                                hasFeedback
                                validateStatus={headboard_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Headboard Material"
                                    value={headboard_material}
                                    onChange={(value) => {
                                        setHeadboard_material(value);
                                        updateValues("headboard_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Headboard_material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={headboard_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("headboard_material_subtype") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Headboard Material Subtype"
                                hasFeedback
                                validateStatus={headboard_material_subtype_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Headboard Material Subtype"
                                    value={headboard_material_subtype}
                                    onChange={(value) => {
                                        setHeadboard_material_subtype(value);
                                        updateValues("headboard_material_subtype", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Headboar_material_subtype.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={headboard_material_subtype === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("upholstery_included") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Upholstery Included"
                                hasFeedback
                                validateStatus={upholstery_included_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Upholstery Included"
                                    value={upholstery_included}
                                    onChange={(value) => {
                                        setUpholstery_included(value);
                                        updateValues("upholstery_included", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={upholstery_included === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("finish_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Finish Type"
                                hasFeedback
                                validateStatus={finish_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Finish Type"
                                    value={finish_type}
                                    onChange={(value) => {
                                        setFinish_type(value);
                                        updateValues("finish_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Finish_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={finish_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("finish_color") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Finish Color"
                                hasFeedback
                                validateStatus={finish_color_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Finish Color"
                                    value={finish_color}
                                    onChange={(value) => {
                                        setFinish_color(value);
                                        updateValues("finish_color", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Finish_color.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={finish_color === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("storage_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Storage Type"
                                hasFeedback
                                validateStatus={storage_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Storage Type"
                                    value={storage_type}
                                    onChange={(value) => {
                                        setStorage_type(value);
                                        updateValues("storage_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Storage_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={storage_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("headboard_included") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Headboard Included"
                                hasFeedback
                                validateStatus={headboard_included_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Headboard Included"
                                    value={headboard_included}
                                    onChange={(value) => {
                                        setHeadboard_included(value);
                                        updateValues("headboard_included", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={headboard_included === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("floor_clearance") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Floor Clearance"
                                hasFeedback
                                validateStatus={floor_clearance_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Floor Clearance"
                                    value={floor_clearance}
                                    onChange={(value) => {
                                        setFloor_clearance(value);
                                        updateValues("floor_clearance", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Floor_clearance.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={floor_clearance === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>

                <Flex wrap="wrap">
                    {distinctfield.includes("number_of_shelves") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Number Of Shelves"
                                hasFeedback
                                validateStatus={number_of_shelves_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Number Of Shelves"
                                    value={number_of_shelves}
                                    onChange={(value) => {
                                        setNumber_of_shelves(value);
                                        updateValues("number_of_shelves", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Number_of_shelves.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={number_of_shelves === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("rod_included") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Rod Included"
                                hasFeedback
                                validateStatus={rod_included_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Rod Included"
                                    value={rod_included}
                                    onChange={(value) => {
                                        setRod_included(value);
                                        updateValues("rod_included", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={rod_included === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("bush_included") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Bush Included"
                                hasFeedback
                                validateStatus={bush_included_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Bush Included"
                                    value={bush_included}
                                    onChange={(value) => {
                                        setBush_included(value);
                                        updateValues("bush_included", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={bush_included === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("enclosure_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Enclosure Type"
                                hasFeedback
                                validateStatus={enclosure_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Enclosure Type"
                                    value={enclosure_type}
                                    onChange={(value) => {
                                        setEnclosure_type(value);
                                        updateValues("enclosure_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Enclosure_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={enclosure_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("mount_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Mount Type"
                                hasFeedback
                                validateStatus={mount_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Mount Type"
                                    value={mount_type}
                                    onChange={(value) => {
                                        setMount_type(value);
                                        updateValues("mount_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Mount_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={mount_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("number_of_rods") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Number of rods"
                                hasFeedback
                                validateStatus={number_of_rods_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Number of rods"
                                    value={number_of_rods}
                                    onChange={(value) => {
                                        setNumber_of_rods(value);
                                        updateValues("number_of_rods", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Number_of_rods.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={number_of_rods === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("cabinet_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Cabinet Type"
                                hasFeedback
                                validateStatus={cabinet_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Cabinet Type"
                                    value={cabinet_type}
                                    onChange={(value) => {
                                        setCabinet_type(value);
                                        updateValues("cabinet_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Cabinet_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={cabinet_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("support_layer") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Support Layer"
                                hasFeedback
                                validateStatus={support_layer_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Support Layer"
                                    value={support_layer}
                                    onChange={(value) => {
                                        setSupport_layer(value);
                                        updateValues("support_layer", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Support_layer.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={support_layer === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("comfort_layer") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Comfort Layer"
                                hasFeedback
                                validateStatus={comfort_layer_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Comfort Layer"
                                    value={comfort_layer}
                                    onChange={(value) => {
                                        setComfort_layer(value);
                                        updateValues("comfort_layer", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Comfort_layer.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={comfort_layer === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("mattress_top") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Mattress Top"
                                hasFeedback
                                validateStatus={mattress_top_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Mattress Top"
                                    value={mattress_top}
                                    onChange={(value) => {
                                        setMattress_top(value);
                                        updateValues("mattress_top", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Mattress_top.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={mattress_top === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("mattress_features") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Mattress Features"
                                hasFeedback
                                validateStatus={mattress_features_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Mattress Features"
                                    value={mattress_features}
                                    onChange={(value) => {
                                        setMattress_features(value);
                                        updateValues("mattress_features", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Mattress_features.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={mattress_features === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("comfort_levels") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Comfort Levels"
                                hasFeedback
                                validateStatus={comfort_levels_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Comfort Levels"
                                    value={comfort_levels}
                                    onChange={(value) => {
                                        setComfort_levels(value);
                                        updateValues("comfort_levels", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Comfort_levels.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={comfort_levels === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("upholstery_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Upholstery Material"
                                hasFeedback
                                validateStatus={upholstery_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Upholstery Material"
                                    value={upholstery_material}
                                    onChange={(value) => {
                                        setUpholstery_material(value);
                                        updateValues("upholstery_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Upholstery_material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={upholstery_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("removable_cover") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Removable Cover"
                                hasFeedback
                                validateStatus={removable_cover_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Removable Cover"
                                    value={removable_cover}
                                    onChange={(value) => {
                                        setRemovable_cover(value);
                                        updateValues("removable_cover", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Removable_cover.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={removable_cover === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("reinforced_edges") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Reinforced Edges"
                                hasFeedback
                                validateStatus={reinforced_edges_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Reinforced Edges"
                                    value={reinforced_edges}
                                    onChange={(value) => {
                                        setReinforced_edges(value);
                                        updateValues("reinforced_edges", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={reinforced_edges === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("thickness") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Thickness"
                                hasFeedback
                                validateStatus={thickness_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Thickness"
                                    value={thickness}
                                    onChange={(value) => {
                                        setThickness(value);
                                        updateValues("thickness", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Thickness.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={thickness === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("length") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="length"
                                hasFeedback
                                validateStatus={length_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select length"
                                    value={length}
                                    onChange={(value) => {
                                        setLength(value);
                                        updateValues("length", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {length1.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={length === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("secondary_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Secondary Material"
                                hasFeedback
                                validateStatus={secondary_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Secondary Material"
                                    value={secondary_material}
                                    onChange={(value) => {
                                        setSecondary_material(value);
                                        updateValues("secondary_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Secondary_material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={secondary_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("secondary_material_subtype") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Secondary Material Subtype"
                                hasFeedback
                                validateStatus={secondary_material_subtype_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Secondary Material Subtype"
                                    value={secondary_material_subtype}
                                    onChange={(value) => {
                                        setSecondary_material_subtype(value);
                                        updateValues("secondary_material_subtype", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Seconday_material_subtype.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={secondary_material_subtype === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("configuration") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Configuration"
                                hasFeedback
                                validateStatus={configuration_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Configuration"
                                    value={configuration}
                                    onChange={(value) => {
                                        setConfiguration(value);
                                        updateValues("configuration", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Configuration.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={configuration === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("upholstery_material_subtype") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Upholstery Material Subtype"
                                hasFeedback
                                validateStatus={upholstery_material_subtype_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Upholstery Material Subtype"
                                    value={upholstery_material_subtype}
                                    onChange={(value) => {
                                        setUpholstery_material_subtype(value);
                                        updateValues("upholstery_material_subtype", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Upholstery_material_subtype.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={upholstery_material_subtype === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("upholstery_material_color") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label=" Upholstery Material Color"
                                hasFeedback
                                validateStatus={upholstery_material_color_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select  Upholstery Material Color"
                                    value={upholstery_material_color}
                                    onChange={(value) => {
                                        setUpholstery_material_color(value);
                                        updateValues("upholstery_material_color", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Color.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={upholstery_material_color === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("filling_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Filling Material"
                                hasFeedback
                                validateStatus={filling_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Filling Material"
                                    value={filling_material}
                                    onChange={(value) => {
                                        setFilling_material(value);
                                        updateValues("filling_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Filling_material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={filling_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("seating_capacity") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Seating Capacity"
                                hasFeedback
                                validateStatus={seating_capacity_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Seating Capacity"
                                    value={seating_capacity}
                                    onChange={(value) => {
                                        setSeating_capacity(value);
                                        updateValues("seating_capacity", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Seating_capacity.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={seating_capacity === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("mattress_included") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Mattress Included"
                                hasFeedback
                                validateStatus={mattress_included_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Mattress Included"
                                    value={mattress_included}
                                    onChange={(value) => {
                                        setMattress_included(value);
                                        updateValues("mattress_included", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={mattress_included === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("mechanism_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Mechanism Type"
                                hasFeedback
                                validateStatus={mechanism_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Mechanism Type"
                                    value={mechanism_type}
                                    onChange={(value) => {
                                        setMechanism_type(value);
                                        updateValues("mechanism_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Mechanism_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={mechanism_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("upholstery_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Upholstery Type"
                                hasFeedback
                                validateStatus={upholstery_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Upholstery Type"
                                    value={upholstery_type}
                                    onChange={(value) => {
                                        setUpholstery_type(value);
                                        updateValues("upholstery_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Upholstery_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={upholstery_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("mattress_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Mattress Type"
                                hasFeedback
                                validateStatus={mattress_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Mattress Type"
                                    value={mattress_type}
                                    onChange={(value) => {
                                        setMattress_type(value);
                                        updateValues("mattress_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Mattress_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={mattress_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("removable_leg") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Removable Leg"
                                hasFeedback
                                validateStatus={removable_leg_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Removable Leg"
                                    value={removable_leg}
                                    onChange={(value) => {
                                        setRemovable_leg(value);
                                        updateValues("removable_leg", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Removable_leg.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={removable_leg === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("frame_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Frame Material"
                                hasFeedback
                                validateStatus={frame_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Frame Material"
                                    value={frame_material}
                                    onChange={(value) => {
                                        setFrame_material(value);
                                        updateValues("frame_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Frame_material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={frame_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("frame_material_subtype") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Frame Material Subtype"
                                hasFeedback
                                validateStatus={frame_material_subtype_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Frame Material Subtype"
                                    value={frame_material_subtype}
                                    onChange={(value) => {
                                        setFrame_material_subtype(value);
                                        updateValues("frame_material_subtype", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Frame_material_subtype.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={frame_material_subtype === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("accessories_included") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Accessories Included"
                                hasFeedback
                                validateStatus={accessories_included_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Accessories Included"
                                    value={accessories_included}
                                    onChange={(value) => {
                                        setAccessories_included(value);
                                        updateValues("accessories_included", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={accessories_included === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("origin_of_manufacture") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Origin Of Manufacture"
                                hasFeedback
                                validateStatus={origin_of_manufacture_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Origin Of Manufacture"
                                    value={origin_of_manufacture}
                                    onChange={(value) => {
                                        setOrigin_of_manufacture(value);
                                        updateValues("origin_of_manufacture", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Origin_of_manufacture.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={origin_of_manufacture === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("with_door") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="With Door"
                                hasFeedback
                                validateStatus={with_door_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select With Door"
                                    value={with_door}
                                    onChange={(value) => {
                                        setWith_door(value);
                                        updateValues("with_door", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {With_door.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={with_door === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("number_of_drawers") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Number Of Drawers"
                                hasFeedback
                                validateStatus={number_of_drawers_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Number Of Drawers"
                                    value={number_of_drawers}
                                    onChange={(value) => {
                                        setNumber_of_drawers(value);
                                        updateValues("number_of_drawers", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Number_of_drawers.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={number_of_drawers === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("style") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Style"
                                hasFeedback
                                validateStatus={style_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Style"
                                    value={style}
                                    onChange={(value) => {
                                        setStyle(value);
                                        updateValues("style", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Style.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={style === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("maximum_tv_size") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Maximum Tv Size"
                                hasFeedback
                                validateStatus={maximum_tv_size_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Maximum Tv Size"
                                    value={maximum_tv_size}
                                    onChange={(value) => {
                                        setMaximum_tv_size(value);
                                        updateValues("maximum_tv_size", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Maximum_tv_size.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={maximum_tv_size === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("unit_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Unit Material"
                                hasFeedback
                                validateStatus={unit_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Unit Material"
                                    value={unit_material}
                                    onChange={(value) => {
                                        setUnit_material(value);
                                        updateValues("unit_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Unit_material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={unit_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("unit_material_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Unit Material Type"
                                hasFeedback
                                validateStatus={unit_material_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Unit Material Type"
                                    value={unit_material_type}
                                    onChange={(value) => {
                                        setUnit_material_type(value);
                                        updateValues("unit_material_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Unit_material_sub_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={unit_material_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("number_of_chairs") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Number Of Chairs"
                                hasFeedback
                                validateStatus={number_of_chairs_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Number Of Chairs"
                                    value={number_of_chairs}
                                    onChange={(value) => {
                                        setNumber_of_chairs(value);
                                        updateValues("number_of_chairs", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Number_of_chairs.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={number_of_chairs === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("table_shape") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Table Shape"
                                hasFeedback
                                validateStatus={table_shape_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Table Shape"
                                    value={table_shape}
                                    onChange={(value) => {
                                        setTable_shape(value);
                                        updateValues("table_shape", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Table_shape.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={table_shape === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("wheels_included") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Wheels Included"
                                hasFeedback
                                validateStatus={wheels_included_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Wheels Included"
                                    value={wheels_included}
                                    onChange={(value) => {
                                        setWheels_included(value);
                                        updateValues("wheels_included", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={wheels_included === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("extendable") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Extendable"
                                hasFeedback
                                validateStatus={extendable_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Extendable"
                                    value={extendable}
                                    onChange={(value) => {
                                        setExtendable(value);
                                        updateValues("extendable", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={extendable === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("foldable") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Foldable"
                                hasFeedback
                                validateStatus={foldable_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Foldable"
                                    value={foldable}
                                    onChange={(value) => {
                                        setFoldable(value);
                                        updateValues("foldable", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Foldable.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={foldable === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("table_frame_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Table Frame Material"
                                hasFeedback
                                validateStatus={table_frame_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Table Frame Material"
                                    value={table_frame_material}
                                    onChange={(value) => {
                                        setTable_frame_material(value);
                                        updateValues("table_frame_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Table_frame_material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={table_frame_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("table_frame_material_subtype") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Table Frame Material Subtype"
                                hasFeedback
                                validateStatus={table_frame_material_subtype_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Table Frame Material Subtype"
                                    value={table_frame_material_subtype}
                                    onChange={(value) => {
                                        setTable_frame_material_subtype(value);
                                        updateValues("table_frame_material_subtype", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Table_frame_materical_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={table_frame_material_subtype === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("chair_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Chair Material"
                                hasFeedback
                                validateStatus={chair_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Chair Material"
                                    value={chair_material}
                                    onChange={(value) => {
                                        setChair_material(value);
                                        updateValues("chair_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Chair_material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={chair_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("chair_width") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Chair Width"
                                hasFeedback
                                validateStatus={chair_width_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Chair Width"
                                    value={chair_width}
                                    onChange={(value) => {
                                        setChair_width(value);
                                        updateValues("chair_width", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Chair_width.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={chair_width === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("chair_height") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Chair Height"
                                hasFeedback
                                validateStatus={chair_height_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Chair Height"
                                    value={chair_height}
                                    onChange={(value) => {
                                        setChair_height(value);
                                        updateValues("chair_height", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Chair_height.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={chair_height === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("chair_depth") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Chair Depth"
                                hasFeedback
                                validateStatus={chair_depth_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Chair Depth"
                                    value={chair_depth}
                                    onChange={(value) => {
                                        setChair_depth(value);
                                        updateValues("chair_depth", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Chair_depth.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={chair_depth === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("chair_weight") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Chair Weight"
                                hasFeedback
                                validateStatus={chair_weight_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Chair Weight"
                                    value={chair_weight}
                                    onChange={(value) => {
                                        setChair_weight(value);
                                        updateValues("chair_weight", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Chair_weight.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={chair_weight === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("table_width") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Table Width"
                                hasFeedback
                                validateStatus={table_width_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Table Width"
                                    value={table_width}
                                    onChange={(value) => {
                                        setTable_width(value);
                                        updateValues("table_width", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Table_width.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={table_width === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("table_height") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Table Height"
                                hasFeedback
                                validateStatus={table_height_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Table Height"
                                    value={table_height}
                                    onChange={(value) => {
                                        setTable_height(value);
                                        updateValues("table_height", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Table_height.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={table_height === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("table_depth") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Table Depth"
                                hasFeedback
                                validateStatus={table_depth_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Table Depth"
                                    value={table_depth}
                                    onChange={(value) => {
                                        setTable_depth(value);
                                        updateValues("table_depth", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Table_depth.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={table_depth === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("table_weight") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Table Weight"
                                hasFeedback
                                validateStatus={table_weight_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Table Weight"
                                    value={table_weight}
                                    onChange={(value) => {
                                        setTable_weight(value);
                                        updateValues("table_weight", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Table_weight.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={table_weight === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("table_top_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Table Top Material"
                                hasFeedback
                                validateStatus={table_top_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Table Top Material"
                                    value={table_top_material}
                                    onChange={(value) => {
                                        setTable_top_material(value);
                                        updateValues("table_top_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Table_Top_Material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={table_top_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("table_top_material_subtype") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Table Top Material Subtype"
                                hasFeedback
                                validateStatus={table_top_material_subtype_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Table Top Material Subtype"
                                    value={table_top_material_subtype}
                                    onChange={(value) => {
                                        setTable_top_material_subtype(value);
                                        updateValues("table_top_material_subtype", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Table_Top_Material_sub_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={table_top_material_subtype === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("lock_provided") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Lock Provided"
                                hasFeedback
                                validateStatus={lock_provided_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Lock Provided"
                                    value={lock_provided}
                                    onChange={(value) => {
                                        setLock_provided(value);
                                        updateValues("lock_provided", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={lock_provided === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("wall_mount_support") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Wall Mount Support"
                                hasFeedback
                                validateStatus={wall_mount_support_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Wall Mount Support"
                                    value={wall_mount_support}
                                    onChange={(value) => {
                                        setWall_mount_support(value);
                                        updateValues("wall_mount_support", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Wall_mount_support.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={wall_mount_support === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("number_of_shoe_pairs") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Number of Shoe Pairs"
                                hasFeedback
                                validateStatus={number_of_shoe_pairs_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Number of Shoe Pairs"
                                    value={number_of_shoe_pairs}
                                    onChange={(value) => {
                                        setNumber_of_shoe_pairs(value);
                                        updateValues("number_of_shoe_pairs", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Number_of_shoe_pairs.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={number_of_shoe_pairs === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("material_subtype") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Material Subtype"
                                hasFeedback
                                validateStatus={material_subtype_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Material Subtype"
                                    value={material_subtype}
                                    onChange={(value) => {
                                        setMaterial_subtype(value);
                                        updateValues("material_subtype", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Material_subtype.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={material_subtype === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>

                <Flex wrap="wrap">
                    {distinctfield.includes("primary_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Primary Material"
                                hasFeedback
                                validateStatus={primary_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Primary Material"
                                    value={primary_material}
                                    onChange={(value) => {
                                        setPrimary_material(value);
                                        updateValues("primary_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Primaray_material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={primary_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("primary_material_subtype") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Primary Material Subtype"
                                hasFeedback
                                validateStatus={primary_material_subtype_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Primary Material Subtype"
                                    value={primary_material_subtype}
                                    onChange={(value) => {
                                        setPrimary_material_subtype(value);
                                        updateValues("primary_material_subtype", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Primary_material_subtype.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={primary_material_subtype === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("rollout_bed") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Rollout Bed"
                                hasFeedback
                                validateStatus={rollout_bed_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Rollout Bed"
                                    value={rollout_bed}
                                    onChange={(value) => {
                                        setRollout_bed(value);
                                        updateValues("rollout_bed", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Rollout_bed.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={rollout_bed === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("book_shelf_included") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Book Shelf Included"
                                hasFeedback
                                validateStatus={book_shelf_included_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Book Shelf Included"
                                    value={book_shelf_included}
                                    onChange={(value) => {
                                        setBook_shelf_included(value);
                                        updateValues("book_shelf_included", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={book_shelf_included === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("built_in_ladder") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Built in Ladder"
                                hasFeedback
                                validateStatus={built_in_ladder_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Built in Ladder"
                                    value={built_in_ladder}
                                    onChange={(value) => {
                                        setBuilt_in_ladder(value);
                                        updateValues("built_in_ladder", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Built_in_ladder.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={built_in_ladder === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("study_desk_available") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Study desk Available"
                                hasFeedback
                                validateStatus={study_desk_available_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Study desk Available"
                                    value={study_desk_available}
                                    onChange={(value) => {
                                        setStudy_desk_available(value);
                                        updateValues("study_desk_available", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={study_desk_available === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("tent_included") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Tent Included"
                                hasFeedback
                                validateStatus={tent_included_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Tent Included"
                                    value={tent_included}
                                    onChange={(value) => {
                                        setTent_included(value);
                                        updateValues("tent_included", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={tent_included === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("slide_included") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Slide Included"
                                hasFeedback
                                validateStatus={slide_included_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Slide Included"
                                    value={slide_included}
                                    onChange={(value) => {
                                        setSlide_included(value);
                                        updateValues("slide_included", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={slide_included === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("railing_included") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Railing Included"
                                hasFeedback
                                validateStatus={railing_included_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Railing Included"
                                    value={railing_included}
                                    onChange={(value) => {
                                        setRailing_included(value);
                                        updateValues("railing_included", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={railing_included === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("cover_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Cover Type"
                                hasFeedback
                                validateStatus={cover_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Cover Type"
                                    value={cover_type}
                                    onChange={(value) => {
                                        setCover_type(value);
                                        updateValues("cover_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Cover_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={cover_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("filling_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Filling Type"
                                hasFeedback
                                validateStatus={filling_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Filling Type"
                                    value={filling_type}
                                    onChange={(value) => {
                                        setFilling_type(value);
                                        updateValues("filling_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Filling_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={filling_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("filling_amount") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Filling Amount"
                                hasFeedback
                                validateStatus={filling_amount_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Filling Amount"
                                    value={filling_amount}
                                    onChange={(value) => {
                                        setFilling_amount(value);
                                        updateValues("filling_amount", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Filling_amount.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={filling_amount === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("flat_sheet_length") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Flat Sheet Length"
                                hasFeedback
                                validateStatus={flat_sheet_length_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Flat Sheet Length"
                                    value={flat_sheet_length}
                                    onChange={(value) => {
                                        setFlat_sheet_length(value);
                                        updateValues("flat_sheet_length", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Flat_sheet_length.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={flat_sheet_length === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("number_of_bedsheets") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Number of Bedsheets"
                                hasFeedback
                                validateStatus={number_of_bedsheets_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Number of Bedsheets"
                                    value={number_of_bedsheets}
                                    onChange={(value) => {
                                        setNumber_of_bedsheets(value);
                                        updateValues("number_of_bedsheets", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Number_of_bedsheets.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={number_of_bedsheets === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("thread_count") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Thread Count"
                                hasFeedback
                                validateStatus={thread_count_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Thread Count"
                                    value={thread_count}
                                    onChange={(value) => {
                                        setThread_count(value);
                                        updateValues("thread_count", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Thread_count.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={thread_count === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("collection") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Collection"
                                hasFeedback
                                validateStatus={collection_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Collection"
                                    value={collection}
                                    onChange={(value) => {
                                        setCollection(value);
                                        updateValues("collection", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Collection.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={collection === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("fabric_care") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Fabric Care"
                                hasFeedback
                                validateStatus={fabric_care_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Fabric Care"
                                    value={fabric_care}
                                    onChange={(value) => {
                                        setFabric_care(value);
                                        updateValues("fabric_care", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Fabric_care.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={fabric_care === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("machine_washable") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Machine Washable"
                                hasFeedback
                                validateStatus={machine_washable_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Machine Washable"
                                    value={machine_washable}
                                    onChange={(value) => {
                                        setMachine_washable(value);
                                        updateValues("machine_washable", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={machine_washable === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("lining") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="lining"
                                hasFeedback
                                validateStatus={lining_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select lining"
                                    value={lining}
                                    onChange={(value) => {
                                        setLining(value);
                                        updateValues("lining", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Lining.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={lining === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("transparency") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Transparency"
                                hasFeedback
                                validateStatus={transparency_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Transparency"
                                    value={transparency}
                                    onChange={(value) => {
                                        setTransparency(value);
                                        updateValues("transparency", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Transparency.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={transparency === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("external_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="External Material"
                                hasFeedback
                                validateStatus={external_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select External Material"
                                    value={external_material}
                                    onChange={(value) => {
                                        setExternal_material(value);
                                        updateValues("external_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {External_material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={external_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("with_pillow_cover") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="With Pillow Cover"
                                hasFeedback
                                validateStatus={with_pillow_cover_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select With Pillow Cover"
                                    value={with_pillow_cover}
                                    onChange={(value) => {
                                        setWith_pillow_cover(value);
                                        updateValues("with_pillow_cover", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={with_pillow_cover === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("hand_washable") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Hand Washable"
                                hasFeedback
                                validateStatus={hand_washable_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Hand Washable"
                                    value={hand_washable}
                                    onChange={(value) => {
                                        setHand_washable(value);
                                        updateValues("hand_washable", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={hand_washable === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("warranty") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="warranty"
                                hasFeedback
                                validateStatus={warranty_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select warranty"
                                    value={warranty}
                                    onChange={(value) => {
                                        setWarranty(value);
                                        updateValues("warranty", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Warranty.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={warranty === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("ideal_usage") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Ideal Usage"
                                hasFeedback
                                validateStatus={ideal_usage_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Ideal Usage"
                                    value={ideal_usage}
                                    onChange={(value) => {
                                        setIdeal_usage(value);
                                        updateValues("ideal_usage", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Ideal_usage.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={ideal_usage === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("top_rating") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Top Rating"
                                hasFeedback
                                validateStatus={top_rating_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Top Rating"
                                    value={top_rating}
                                    onChange={(value) => {
                                        setTop_rating(value);
                                        updateValues("top_rating", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Top_rating.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={top_rating === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>

                <Flex wrap="wrap">
                    {distinctfield.includes("gsm") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="GSM"
                                hasFeedback
                                validateStatus={gsm_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select GSM"
                                    value={gsm}
                                    onChange={(value) => {
                                        setGsm(value);
                                        updateValues("gsm", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {GSM.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={gsm === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("weave_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Weave Type"
                                hasFeedback
                                validateStatus={weave_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Weave Type"
                                    value={weave_type}
                                    onChange={(value) => {
                                        setWeave_type(value);
                                        updateValues("weave_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Weave_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={weave_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("outer_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Outer Material"
                                hasFeedback
                                validateStatus={outer_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Outer Material"
                                    value={outer_material}
                                    onChange={(value) => {
                                        setOuter_material(value);
                                        updateValues("outer_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Outer_material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={outer_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("design") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Design"
                                hasFeedback
                                validateStatus={design_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Design"
                                    value={design}
                                    onChange={(value) => {
                                        setDesign(value);
                                        updateValues("design", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Design.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={design === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("pattern") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Pattern"
                                hasFeedback
                                validateStatus={pattern_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Pattern"
                                    value={pattern}
                                    onChange={(value) => {
                                        setPattern(value);
                                        updateValues("pattern", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Pattern.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={pattern === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("theme") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Theme"
                                hasFeedback
                                validateStatus={theme_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Theme"
                                    value={theme}
                                    onChange={(value) => {
                                        setTheme(value);
                                        updateValues("theme", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Theme.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={theme === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("usage") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Usage"
                                hasFeedback
                                validateStatus={usage_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Usage"
                                    value={usage}
                                    onChange={(value) => {
                                        setUsage(value);
                                        updateValues("usage", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Usage.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={usage === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("brand_color") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Brand Color"
                                hasFeedback
                                validateStatus={brand_color_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Brand Color"
                                    value={brand_color}
                                    onChange={(value) => {
                                        setBrand_color(value);
                                        updateValues("brand_color", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Brand_color.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={brand_color === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>

                <Flex wrap="wrap">
                    {distinctfield.includes("key_features") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Key Features"
                                hasFeedback
                                validateStatus={key_features_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Key Features"
                                    value={key_features}
                                    onChange={(value) => {
                                        setKey_features(value);
                                        updateValues("key_features", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Key_features.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={key_features === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("camera_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Camera Type"
                                hasFeedback
                                validateStatus={camera_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Camera Type"
                                    value={camera_type}
                                    onChange={(value) => {
                                        setCamera_type(value);
                                        updateValues("camera_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Camera_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={camera_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("video_output") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Video Output"
                                hasFeedback
                                validateStatus={video_output_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Video Output"
                                    value={video_output}
                                    onChange={(value) => {
                                        setVideo_output(value);
                                        updateValues("video_output", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Video_output.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={video_output === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("video_recording_resolution") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Video Recording Resolution"
                                hasFeedback
                                validateStatus={video_recording_resolution_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Video Recording Resolution"
                                    value={video_recording_resolution}
                                    onChange={(value) => {
                                        setVideo_recording_resolution(value);
                                        updateValues("video_recording_resolution", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={video_recording_resolution === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("video_recording_frame_rate") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Video Recording Frame Rate"
                                hasFeedback
                                validateStatus={video_recording_resolution_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Video Recording Frame Rate"
                                    value={video_recording_frame_rate}
                                    onChange={(value) => {
                                        setVideo_recording_frame_rate(value);
                                        updateValues("video_recording_frame_rate", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Videorecordingresolution.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={video_recording_frame_rate === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("video_recording_frame_rate") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Video Recording Frame rate"
                                hasFeedback
                                validateStatus={video_recording_frame_rate_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Video Recording Frame rate"
                                    value={video_recording_frame_rate}
                                    onChange={(value) => {
                                        setVideo_recording_resolution(value);
                                        updateValues("video_recording_frame_rate", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={video_recording_frame_rate === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("mobile_compatibility") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Mobile Compatibility"
                                hasFeedback
                                validateStatus={mobile_compatibility_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Mobile Compatibility"
                                    value={mobile_compatibility}
                                    onChange={(value) => {
                                        setMobile_compatibility(value);
                                        updateValues("mobile_compatibility", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Mobile_capability.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={mobile_compatibility === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("lens_size") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Lens Size"
                                hasFeedback
                                validateStatus={lens_size_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Lens Size"
                                    value={lens_size}
                                    onChange={(value) => {
                                        setLens_size(value);
                                        updateValues("lens_size", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Lens_size.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={lens_size === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("angle_of_view") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Angle Of View"
                                hasFeedback
                                validateStatus={angle_of_view_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Angle Of View"
                                    value={angle_of_view}
                                    onChange={(value) => {
                                        setAngle_of_view(value);
                                        updateValues("angle_of_view", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Angular_view.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={angle_of_view === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("mounting_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Mounting Type"
                                hasFeedback
                                validateStatus={mounting_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Mounting Type"
                                    value={mounting_type}
                                    onChange={(value) => {
                                        setMounting_type(value);
                                        updateValues("mounting_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Mounting_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={mounting_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("records_modes") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Records Modes"
                                hasFeedback
                                validateStatus={records_modes_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Records Modes"
                                    value={records_modes}
                                    onChange={(value) => {
                                        setRecords_modes(value);
                                        updateValues("records_modes", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Recording_modes.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={records_modes === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("image_sensor") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Image Sensor"
                                hasFeedback
                                validateStatus={image_sensor_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Image Sensor"
                                    value={image_sensor}
                                    onChange={(value) => {
                                        setImage_sensor(value);
                                        updateValues("image_sensor", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Image_sensor.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={image_sensor === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("ir_distance") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="IR Distance"
                                hasFeedback
                                validateStatus={ir_distance_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select IR Distance"
                                    value={ir_distance}
                                    onChange={(value) => {
                                        setIr_distance(value);
                                        updateValues("ir_distance", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {IR_distance.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={ir_distance === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("number_of_ir_leds") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Number of IR leds"
                                hasFeedback
                                validateStatus={number_of_ir_leds_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Number of IR leds"
                                    value={number_of_ir_leds}
                                    onChange={(value) => {
                                        setNumber_of_ir_leds(value);
                                        updateValues("number_of_ir_leds", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Number_of_IR_LEDS.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={number_of_ir_leds === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("hdd_available") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="HDD Available"
                                hasFeedback
                                validateStatus={hdd_available_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select  HDD Available"
                                    value={hdd_available}
                                    onChange={(value) => {
                                        setHdd_available(value);
                                        updateValues("hdd_available", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={hdd_available === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("motion_triggered_recording") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Motion Triggered Recording"
                                hasFeedback
                                validateStatus={motion_triggered_recording_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Motion Triggered Recording"
                                    value={motion_triggered_recording}
                                    onChange={(value) => {
                                        setMotion_triggered_recording(value);
                                        updateValues("motion_triggered_recording", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Motion_triggered_recording.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={motion_triggered_recording === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("power_source") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Power Source"
                                hasFeedback
                                validateStatus={power_source_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Power Source"
                                    value={power_source}
                                    onChange={(value) => {
                                        setPower_source(value);
                                        updateValues("power_source", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Power_source.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={power_source === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("audio_output") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Audio Output"
                                hasFeedback
                                validateStatus={audio_output_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Audio Output"
                                    value={audio_output}
                                    onChange={(value) => {
                                        setAudio_output(value);
                                        updateValues("audio_output", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Audio_output.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={audio_output === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("usb_port_present") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="USB Port Present"
                                hasFeedback
                                validateStatus={usb_port_present_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select USB Port Present"
                                    value={usb_port_present}
                                    onChange={(value) => {
                                        setUsb_port_present(value);
                                        updateValues("usb_port_present", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={usb_port_present === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("battery_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Battery Type"
                                hasFeedback
                                validateStatus={battery_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Battery Type"
                                    value={battery_type}
                                    onChange={(value) => {
                                        setBattery_type(value);
                                        updateValues("battery_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Battery_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={battery_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("finish") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Finish"
                                hasFeedback
                                validateStatus={finish_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Finish"
                                    value={finish}
                                    onChange={(value) => {
                                        setFinish(value);
                                        updateValues("finish", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Finish.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={finish === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("functions") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Functions"
                                hasFeedback
                                validateStatus={functions_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Functions"
                                    value={functions}
                                    onChange={(value) => {
                                        setFunctions(value);
                                        updateValues("functions", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Functions.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={functions === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("compatible_devices") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Compatible Devices"
                                hasFeedback
                                validateStatus={compatible_devices_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Compatible Devices"
                                    value={compatible_devices}
                                    onChange={(value) => {
                                        setCompatible_devices(value);
                                        updateValues("compatible_devices", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Compatible_devices.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={compatible_devices === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("connectivity_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Connectivity Type"
                                hasFeedback
                                validateStatus={connectivity_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Connectivity Type"
                                    value={connectivity_type}
                                    onChange={(value) => {
                                        setConnectivity_type(value);
                                        updateValues("connectivity_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Connective_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={connectivity_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("number_of_batteries") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Number of Batteries"
                                hasFeedback
                                validateStatus={number_of_batteries_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Number of Batteries"
                                    value={number_of_batteries}
                                    onChange={(value) => {
                                        setNumber_of_batteries(value);
                                        updateValues("number_of_batteries", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Number_of_battires.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={number_of_batteries === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("frame_color") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Frame Color"
                                hasFeedback
                                validateStatus={frame_color_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Frame Color"
                                    value={frame_color}
                                    onChange={(value) => {
                                        setFrame_color(value);
                                        updateValues("frame_color", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Frame_color.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={frame_color === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("wall_mount") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Wall Mount"
                                hasFeedback
                                validateStatus={wall_mount_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Wall Mount"
                                    value={wall_mount}
                                    onChange={(value) => {
                                        setWall_mount(value);
                                        updateValues("wall_mount", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Wall_mount.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={wall_mount === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("frame_included") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Frame Included"
                                hasFeedback
                                validateStatus={frame_included_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Frame Included"
                                    value={frame_included}
                                    onChange={(value) => {
                                        setFrame_included(value);
                                        updateValues("frame_included", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={frame_included === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("mount_mechanism") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Mount Mechanism"
                                hasFeedback
                                validateStatus={mount_mechanism_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Mount Mechanism"
                                    value={mount_mechanism}
                                    onChange={(value) => {
                                        setMount_mechanism(value);
                                        updateValues("mount_mechanism", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Mount_mechanism.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={mount_mechanism === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("scratch_resistant") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Scratch Resistant"
                                hasFeedback
                                validateStatus={scratch_resistant_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Scratch Resistant"
                                    value={scratch_resistant}
                                    onChange={(value) => {
                                        setScratch_resistant(value);
                                        updateValues("scratch_resistant", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Scratch_resistant.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={scratch_resistant === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("decal_color") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Decal Color"
                                hasFeedback
                                validateStatus={decal_color_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Decal Color"
                                    value={decal_color}
                                    onChange={(value) => {
                                        setDecal_color(value);
                                        updateValues("decal_color", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Decal_color.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={decal_color === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("sticker_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Sticker Type"
                                hasFeedback
                                validateStatus={sticker_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Sticker Type"
                                    value={sticker_type}
                                    onChange={(value) => {
                                        setSticker_type(value);
                                        updateValues("sticker_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Sticker_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={sticker_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("art_form_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Art Form Type"
                                hasFeedback
                                validateStatus={art_form_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Art Form Type"
                                    value={art_form_type}
                                    onChange={(value) => {
                                        setArt_form_type(value);
                                        updateValues("art_form_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Art_form_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={art_form_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("sub_category") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Sub Category"
                                hasFeedback
                                validateStatus={sub_category_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Sub Category"
                                    value={sub_category}
                                    onChange={(value) => {
                                        setSub_category(value);
                                        updateValues("sub_category", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Sub_category.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={sub_category === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("pack_of_lamps") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Pack of Lamps"
                                hasFeedback
                                validateStatus={pack_of_lamps_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Pack of Lamps"
                                    value={pack_of_lamps}
                                    onChange={(value) => {
                                        setPack_of_lamps(value);
                                        updateValues("pack_of_lamps", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Pack_of_lamps.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={pack_of_lamps === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("blub_included") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Blub Included"
                                hasFeedback
                                validateStatus={blub_included_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Blub Included"
                                    value={blub_included}
                                    onChange={(value) => {
                                        setBlub_included(value);
                                        updateValues("blub_included", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={blub_included === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("blub_used") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Blub Used"
                                hasFeedback
                                validateStatus={blub_used_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Blub Used"
                                    value={blub_used}
                                    onChange={(value) => {
                                        setBlub_used(value);
                                        updateValues("blub_used", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Blub_used.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={blub_used === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("adjustable") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Adjustable"
                                hasFeedback
                                validateStatus={adjustable_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Adjustable"
                                    value={adjustable}
                                    onChange={(value) => {
                                        setAdjustable(value);
                                        updateValues("adjustable", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={adjustable === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("blub_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Blub Type"
                                hasFeedback
                                validateStatus={blub_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Blub Type"
                                    value={blub_type}
                                    onChange={(value) => {
                                        setBlub_type(value);
                                        updateValues("blub_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Blub_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={blub_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("with_shade") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="With Shade"
                                hasFeedback
                                validateStatus={with_shade_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select With Shade"
                                    value={with_shade}
                                    onChange={(value) => {
                                        setWith_shade(value);
                                        updateValues("with_shade", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={with_shade === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("light_color") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Light Color"
                                hasFeedback
                                validateStatus={light_color_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Light Color"
                                    value={light_color}
                                    onChange={(value) => {
                                        setLight_color(value);
                                        updateValues("light_color", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Light_color.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={light_color === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("blub_shape") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Blub Shape"
                                hasFeedback
                                validateStatus={blub_shape_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Blub Shape"
                                    value={blub_shape}
                                    onChange={(value) => {
                                        setBlub_shape(value);
                                        updateValues("blub_shape", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Blub_shape.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={blub_shape === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("energy_saving") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Energy Saving"
                                hasFeedback
                                validateStatus={energy_saving_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Energy Saving"
                                    value={energy_saving}
                                    onChange={(value) => {
                                        setEnergy_saving(value);
                                        updateValues("energy_saving", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={energy_saving === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("shatter_proof") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Shatter Proof"
                                hasFeedback
                                validateStatus={shatter_proof_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Shatter Proof"
                                    value={shatter_proof}
                                    onChange={(value) => {
                                        setShatter_proof(value);
                                        updateValues("shatter_proof", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Shatter_proof.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={shatter_proof === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("card_length") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Card Length"
                                hasFeedback
                                validateStatus={card_length_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Card Length"
                                    value={card_length}
                                    onChange={(value) => {
                                        setCard_length(value);
                                        updateValues("card_length", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Card_length.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={card_length === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("number_of_bulb") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Number of Bulb"
                                hasFeedback
                                validateStatus={number_of_bulb_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Number of Bulb"
                                    value={number_of_bulb}
                                    onChange={(value) => {
                                        setNumber_of_bulb(value);
                                        updateValues("number_of_bulb", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Number_of_blubs.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={number_of_bulb === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("light_used") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Light Used"
                                hasFeedback
                                validateStatus={light_used_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Light Used"
                                    value={light_used}
                                    onChange={(value) => {
                                        setLight_used(value);
                                        updateValues("light_used", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Light_used.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={light_used === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("light_mode") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Light Mode"
                                hasFeedback
                                validateStatus={light_mode_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Light Mode"
                                    value={light_mode}
                                    onChange={(value) => {
                                        setLight_mode(value);
                                        updateValues("light_mode", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Light_modes.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={light_mode === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("indicators") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Indicators"
                                hasFeedback
                                validateStatus={indicators_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Indicators"
                                    value={indicators}
                                    onChange={(value) => {
                                        setIndicators(value);
                                        updateValues("indicators", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Indicators.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={indicators === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("charging_time") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Charging Time"
                                hasFeedback
                                validateStatus={charging_time_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Charging Time"
                                    value={charging_time}
                                    onChange={(value) => {
                                        setCharging_time(value);
                                        updateValues("charging_time", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Charging_time.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={charging_time === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("maximum_number_of_recharges") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Maximum Number of Recharges"
                                hasFeedback
                                validateStatus={maximum_number_of_recharges_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Maximum Number of Recharges"
                                    value={maximum_number_of_recharges}
                                    onChange={(value) => {
                                        setMaximum_number_of_recharges(value);
                                        updateValues("maximum_number_of_recharges", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Maximum_number_of_recharges.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={maximum_number_of_recharges === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("battery_capacity") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Battery Capacity"
                                hasFeedback
                                validateStatus={battery_capacity_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Battery Capacity"
                                    value={battery_capacity}
                                    onChange={(value) => {
                                        setBattery_capacity(value);
                                        updateValues("battery_capacity", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Battery_capacity.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={battery_capacity === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>

                <Flex wrap="wrap">
                    {distinctfield.includes("pet_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Pet Type"
                                hasFeedback
                                validateStatus={pet_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Pet Type"
                                    value={pet_type}
                                    onChange={(value) => {
                                        setPet_type(value);
                                        updateValues("pet_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Pet_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={pet_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("activity_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Activity Type"
                                hasFeedback
                                validateStatus={activity_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Activity Type"
                                    value={activity_type}
                                    onChange={(value) => {
                                        setActivity_type(value);
                                        updateValues("activity_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Activity_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={activity_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("chewable") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Chewable"
                                hasFeedback
                                validateStatus={chewable_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Chewable"
                                    value={chewable}
                                    onChange={(value) => {
                                        setChewable(value);
                                        updateValues("chewable", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Chewable.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={chewable === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("led_toy") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Led Toy"
                                hasFeedback
                                validateStatus={led_toy_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Led Toy"
                                    value={led_toy}
                                    onChange={(value) => {
                                        setLed_toy(value);
                                        updateValues("led_toy", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Led_toy.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={led_toy === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>

                <Flex wrap="wrap">
                    {distinctfield.includes("collar_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Collar Type"
                                hasFeedback
                                validateStatus={collar_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Collar Type"
                                    value={collar_type}
                                    onChange={(value) => {
                                        setCollar_type(value);
                                        updateValues("collar_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Collor_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={collar_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("towel_rack") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Towel Rack"
                                hasFeedback
                                validateStatus={towel_rack_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Towel Rack"
                                    value={towel_rack}
                                    onChange={(value) => {
                                        setTowel_rack(value);
                                        updateValues("towel_rack", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Towel_rack.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={towel_rack === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("number_of_tools") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Number Of Tools"
                                hasFeedback
                                validateStatus={number_of_tools_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Number Of Tools"
                                    value={number_of_tools}
                                    onChange={(value) => {
                                        setNumber_of_tools(value);
                                        updateValues("number_of_tools", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Number_of_tools.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={number_of_tools === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("assembly_required") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Assembly Required"
                                hasFeedback
                                validateStatus={assembly_required_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Assembly Required"
                                    value={assembly_required}
                                    onChange={(value) => {
                                        setAssembly_required(value);
                                        updateValues("assembly_required", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={assembly_required === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("magazine_capacity") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Magazine Capacity"
                                hasFeedback
                                validateStatus={magazine_capacity_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Magazine Capacity"
                                    value={magazine_capacity}
                                    onChange={(value) => {
                                        setMagazine_capacity(value);
                                        updateValues("magazine_capacity", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Magazine_capacity.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={magazine_capacity === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("fastener_length") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Fastener Length"
                                hasFeedback
                                validateStatus={fastener_length_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Fastener Length"
                                    value={fastener_length}
                                    onChange={(value) => {
                                        setFastener_length(value);
                                        updateValues("fastener_length", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Fastener_length.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={fastener_length === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("fastener_gauge") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Fastener Gauge"
                                hasFeedback
                                validateStatus={fastener_gauge_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Fastener Gauge"
                                    value={fastener_gauge}
                                    onChange={(value) => {
                                        setFastener_gauge(value);
                                        updateValues("fastener_gauge", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Fastener_gauge.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={fastener_gauge === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("fastener_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Fastener Type"
                                hasFeedback
                                validateStatus={fastener_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Fastener Type"
                                    value={fastener_type}
                                    onChange={(value) => {
                                        setFastener_type(value);
                                        updateValues("fastener_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Fastener_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={fastener_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("fastener_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Fastener Type"
                                hasFeedback
                                validateStatus={fastener_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Fastener Type"
                                    value={fastener_type}
                                    onChange={(value) => {
                                        setFastener_type(value);
                                        updateValues("fastener_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Fastener_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={fastener_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("dial_color") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Dial Color"
                                hasFeedback
                                validateStatus={dial_color_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Dial Color"
                                    value={dial_color}
                                    onChange={(value) => {
                                        setDial_color(value);
                                        updateValues("dial_color", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Dial_color.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={dial_color === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("battery") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Battery"
                                hasFeedback
                                validateStatus={battery_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Battery"
                                    value={battery}
                                    onChange={(value) => {
                                        setBattery(value);
                                        updateValues("battery", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Battery.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={battery === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("water_proof") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Water Proof"
                                hasFeedback
                                validateStatus={water_proof_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Water Proof"
                                    value={water_proof}
                                    onChange={(value) => {
                                        setWater_proof(value);
                                        updateValues("water_proof", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={water_proof === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("mounting_handware_included") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Mounting Hardware Included"
                                hasFeedback
                                validateStatus={mounting_handware_included_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Mounting Hardware Included"
                                    value={mounting_handware_included}
                                    onChange={(value) => {
                                        setMounting_handware_included(value);
                                        updateValues("mounting_handware_included", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={mounting_handware_included === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("with_frame") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="With Frame"
                                hasFeedback
                                validateStatus={with_frame_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select With Frame"
                                    value={with_frame}
                                    onChange={(value) => {
                                        setWith_frame(value);
                                        updateValues("with_frame", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {yes_r_no.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={with_frame === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("pillow_cover_length") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Pillow Cover Length"
                                hasFeedback
                                validateStatus={pillow_cover_length_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Pillow Cover Length"
                                    value={pillow_cover_length}
                                    onChange={(value) => {
                                        setPillow_cover_length(value);
                                        updateValues("pillow_cover_length", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Pillow_cover_length.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={pillow_cover_length === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("pillow_cover_width") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Pillow Cover Width"
                                hasFeedback
                                validateStatus={pillow_cover_width_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Pillow Cover Width"
                                    value={pillow_cover_width}
                                    onChange={(value) => {
                                        setPillow_cover_width(value);
                                        updateValues("pillow_cover_width", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Pillow_cover_width.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={pillow_cover_width === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("flat_sheet_width") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Flat Sheet Width"
                                hasFeedback
                                validateStatus={flat_sheet_width_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Flat Sheet Width"
                                    value={flat_sheet_width}
                                    onChange={(value) => {
                                        setFlat_sheet_width(value);
                                        updateValues("flat_sheet_width", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Flat_sheet_width.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={flat_sheet_width === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("net_width") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Net width"
                                hasFeedback
                                validateStatus={net_width_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Net width"
                                    value={net_width}
                                    onChange={(value) => {
                                        setNet_width(value);
                                        updateValues("net_width", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Net_width.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={net_width === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("net_length") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Net Length"
                                hasFeedback
                                validateStatus={net_length_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Net Length"
                                    value={net_length}
                                    onChange={(value) => {
                                        setNet_length(value);
                                        updateValues("net_length", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Net_length.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={net_length === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("reversible") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Reversible"
                                hasFeedback
                                validateStatus={reversible_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Reversible"
                                    value={reversible}
                                    onChange={(value) => {
                                        setReversible(value);
                                        updateValues("reversible", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Reversible.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={reversible === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("retractable") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Retractable"
                                hasFeedback
                                validateStatus={retractable_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Retractable"
                                    value={retractable}
                                    onChange={(value) => {
                                        setRetractable(value);
                                        updateValues("retractable", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Retractable.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={retractable === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("harness_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Harness Type"
                                hasFeedback
                                validateStatus={harness_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Harness Type"
                                    value={harness_type}
                                    onChange={(value) => {
                                        setHarness_type(value);
                                        updateValues("harness_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Harness_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={harness_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>


            </Row>



        </>
    )
}
