import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  brandtotal: localStorage.getItem("brandtotal")
    ? JSON.parse(localStorage.getItem("brandtotal")).sort()
    : [],
  brandShow: localStorage.getItem("brandtotal")
    ? JSON.parse(localStorage.getItem("brandtotal")).filter((data) => data.show === true).sort()
    : [],
  mobileimage: "",
  desktopimage: "",
  imageLoading: true,
  isLoading: true,
  brandLoading: true,
  delbrandLoading: true,
};

export const getBrand = createAsyncThunk("brand/getBrand", async (thunkAPI) => {
  try {
    const url = `${Baseurl}/api/v1/brand/all`;
    const resp = await axios(url);
    return resp.data.brands;
  } catch (error) {
    return thunkAPI.rejectWithValue("404 Not Found");
  }
});

export const brandPost = createAsyncThunk(
  "brand/brandPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/brand/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("brand Not create");
    }
  }
);


export const validatebrandSlugUrl = createAsyncThunk(
  "brand/validatebrandSlugUrl",
  async (slugurl, thunkAPI) => {
    let resp = {
      success: false,
      message: "new email",
    }
    try {
      const url = `${Baseurl}/api/v1/brand/slugurl/${slugurl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const brandImages = createAsyncThunk(
  "brand/brandImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/brand/brandimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("brandimages Not create");
    }
  }
);
export const brandUpdate = createAsyncThunk(
  "brand/brandUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/brand/${formData.brandid}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("brand Not create");
    }
  }
);

export const brandDelete = createAsyncThunk(
  "brand/brandDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/brand/${id}`;
      const resp = await axios.delete(url, id, config);
      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("brand Not create");
    }
  }
);

const BrandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    updateBrandMobileImage(state, action) {
      state.mobileimage = action.payload;
      state.imageLoading = false;
    },
    updateBrandDeskImage(state, action) {
      state.desktopimage = action.payload;
      state.imageLoading = false;
    },
    removeBrandImages(state, action) {
      state.desktopimage = "";
      state.mobileimage = "";
      state.imageLoading = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBrand.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBrand.fulfilled, (state, action) => {
        state.brandtotal = action.payload.sort();
        state.brandShow = state.brandtotal.filter((data) => data.show === true);
        localStorage.setItem("brandtotal", JSON.stringify(state.brandtotal));
        state.isLoading = false;
        state.brandLoading = false;
      })
      .addCase(getBrand.rejected, (state) => {
        state.isLoading = true;
      });
    builder
      .addCase(brandPost.pending, (state) => {
        state.brandLoading = true;
      })
      .addCase(brandPost.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.brandtotal = [...state.brandtotal, action.payload.brand].sort();
          state.brandShow = state.brandtotal.filter((data) => data.show === true);
          localStorage.setItem("brandtotal", JSON.stringify(state.brandtotal));
        }
        state.brandLoading = false;
      })
      .addCase(brandPost.rejected, (state) => {
        state.brandLoading = true;
      });
    builder
      .addCase(brandImages.pending, (state) => {
        state.imageLoading = true;
      })
      .addCase(brandImages.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.mobileimage = action.payload.mobileimages;
          state.desktopimage = action.payload.desktopImages;
        }
        state.imageLoading = false;
      })
      .addCase(brandImages.rejected, (state) => {
        state.imageLoading = true;
      });
    builder
      .addCase(brandUpdate.pending, (state) => {
        state.brandLoading = true;
      })
      .addCase(brandUpdate.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.brandtotal = state.brandtotal.filter(
            (brand) => brand._id !== action.payload.brand._id
          );
          state.brandtotal = [...state.brandtotal, action.payload.brand];
          state.mobileimage = "";
          state.desktopimage = "";
          state.imageLoading = true;
          state.brandShow = state.brandtotal.filter((data) => data.show === true);
          localStorage.setItem("brandtotal", JSON.stringify(state.brandtotal));
        }
        state.brandLoading = false;
      })
      .addCase(brandUpdate.rejected, (state) => {
        state.brandLoading = true;
      });
    builder
      .addCase(brandDelete.pending, (state) => {
        state.delbrandLoading = true;
      })
      .addCase(brandDelete.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.brandtotal = state.brandtotal.filter(
            (brand) => brand._id !== action.payload.id
          ).sort();
          state.brandShow = state.brandtotal.filter((data) => data.show === true);
          localStorage.setItem("brandtotal", JSON.stringify(state.brandtotal));
        }
        state.delbrandLoading = false;
      })
      .addCase(brandDelete.rejected, (state) => {
        state.delbrandLoading = true;
      });
  },
});

export const {
  updateBrandMobileImage,
  updateBrandDeskImage,
  removeBrandImages
} = BrandSlice.actions;
export default BrandSlice.reducer;
