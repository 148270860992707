import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  homeSlider: localStorage.getItem("homeSlider")
    ? JSON.parse(localStorage.getItem("homeSlider")).sort((a, b) =>
        a.createdAt > b.createdAt ? 1 : -1
      )
    : [],
  mobileimage: "",
  desktopimage: "",
  ismobileImageLoading: true,
  isDeskImageLoading: true,
  ishomeSliderLoading: true,
  homeSliderLoading: true,
  deleteSliderLoading: true,
};

export const homeSliderPost = createAsyncThunk(
  "slider/sliderpost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/mainhomeslider/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Slider Not create");
    }
  }
);

export const getSlider = createAsyncThunk(
  "slider/getslider",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/mainhomeslider/all`;
      const resp = await axios(url);
      return resp.data.mainHomeSliders;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const homesliderImages = createAsyncThunk(
  "slider/sliderImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/mainhomeslider/homesliderimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Slider not create");
    }
  }
);

export const validateSlugUrl = createAsyncThunk(
  "slider/validateSlugUrl",
  async (slugurl, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/threeslider/verify-slugurl/${slugurl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const categoryBannerUpdate = createAsyncThunk(
  "category/categoryBannerUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/mainhomeslider/update/${formData.sliderid}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("categoryBannerUpdate Not update");
    }
  }
);

export const sliderDelete = createAsyncThunk(
  "homeSlider/sliderDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/mainhomeslider/delete/${id}`;
      const resp = await axios.delete(url, id, config);
      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Not create");
    }
  }
);

const HomeSliderSlice = createSlice({
  name: "homeSlider",
  initialState,
  reducers: {
    resetHomeSlider: (state) => {
      state.desktopimage = "";
      state.mobileimage = "";
      state.ismobileImageLoading = true;
      state.isDeskImageLoading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSlider.pending, (state) => {
        state.ishomeSliderLoading = true;
      })
      .addCase(getSlider.fulfilled, (state, action) => {
        // state.homeSlider = action.payload.sort((a, b) =>
        //   a.createdAt > b.createdAt ? 1 : -1
        // );
        state.homeSlider = action.payload;
        let result = state.homeSlider;
        result = result.map((row, index) => ({
          ...row,
          index: index + 1,
        }));
        localStorage.setItem("homeSlider", JSON.stringify(result));
        state.ishomeSliderLoading = false;
      })
      .addCase(getSlider.rejected, (state) => {
        state.ishomeSliderLoading = true;
      });
    builder
      .addCase(homesliderImages.pending, (state) => {
        state.isDeskImageLoading = true;
      })
      .addCase(homesliderImages.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.desktopimage = action.payload.desktopImages;
          state.mobileimage = action.payload.mobileimages;
        }
        state.isDeskImageLoading = false;
        state.ismobileImageLoading = false;
      })
      .addCase(homesliderImages.rejected, (state) => {
        state.isDeskImageLoading = true;
      });

    builder
      .addCase(categoryBannerUpdate.pending, (state) => {
        state.homeSliderLoading = true;
      })
      .addCase(categoryBannerUpdate.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.homeSlider = state.homeSlider
            .filter(
              (slider) => slider._id !== action.payload.mainHomeSlider._id
            )
            .sort((a, b) => (a.name > b.name ? 1 : -1));
          state.homeSlider = [
            ...state.homeSlider,
            action.payload.mainHomeSlider,
          ];

          localStorage.setItem("homeSlider", JSON.stringify(state.homeSlider));
        }

        state.homeSliderLoading = false;
      })
      .addCase(categoryBannerUpdate.rejected, (state) => {
        state.homeSliderLoading = true;
      });

    builder
      .addCase(sliderDelete.pending, (state) => {
        state.deleteSliderLoading = true;
      })
      .addCase(sliderDelete.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.homeSlider = state.homeSlider
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .filter((slider) => slider._id !== action.payload.id);

          localStorage.setItem("homeSlider", JSON.stringify(state.homeSlider));
        }
        state.deleteSliderLoading = false;
      })
      .addCase(sliderDelete.rejected, (state) => {
        state.deleteSliderLoading = true;
      });
  },
});

export const { resetHomeSlider } = HomeSliderSlice.actions;
export default HomeSliderSlice.reducer;
