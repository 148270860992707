import React, { Component, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const Dashboard = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const { shop_total } = useSelector((store) => store.shop);
  const { superCatTotal } = useSelector((store) => store.superCategory);
  const { categorytotal } = useSelector((store) => store.category);
  const { subcategorytotal } = useSelector((store) => store.subCategories);
  const { brandtotal } = useSelector((store) => store.brand);
  const { categoryTagTotal } = useSelector((store) => store.categorytag);
  const { homeSlider } = useSelector((store) => store.homeSlider);
  const { threeSliderstotal } = useSelector((store) => store.threeSlider);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      {isAuth ? (
        <Fragment>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0 text-dark">Dashboard</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <a>Home</a>
                      </li>
                      <li className="breadcrumb-item active">Dashboard v1</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-primary">
                      <div className="inner">
                        <h3>
                          {shop_total.length}
                          <sup style={{ fontSize: 20 }}></sup>
                        </h3>
                        <p>Total Shops</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-stats-bars" />
                      </div>
                      <a className="small-box-footer"></a>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-success">
                      <div className="inner">
                        <h3>{superCatTotal.length}</h3>
                        <p>Total Super Categories</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-person-add" />
                      </div>
                      <a className="small-box-footer"></a>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-danger">
                      <div className="inner">
                        <h3>{categorytotal.length}</h3>
                        <p>Total Categories</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-pie-graph" />
                      </div>
                      <a className="small-box-footer"></a>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-info">
                      <div className="inner">
                        <h3>{subcategorytotal.length}</h3>
                        <p>Total Subcategories</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-bag" />
                      </div>
                      <a className="small-box-footer">
                        {/* More info <i className="fas fa-arrow-circle-right" /> */}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-info">
                      <div className="inner">
                        <h3>{brandtotal.length}</h3>
                        <p>Total Brands</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-bag" />
                      </div>
                      <a className="small-box-footer"></a>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-primary">
                      <div className="inner">
                        <h3>
                          {categoryTagTotal.length}
                          <sup style={{ fontSize: 20 }}></sup>
                        </h3>
                        <p>Total CategoryTags</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-stats-bars" />
                      </div>
                      <a className="small-box-footer"></a>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-success">
                      <div className="inner">
                        <h3>{homeSlider.length}</h3>
                        <p>Total Home Banners</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-person-add" />
                      </div>
                      <a className="small-box-footer"></a>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-danger">
                      <div className="inner">
                        <h3>{threeSliderstotal.length}</h3>
                        <p>Total Category Banners</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-pie-graph" />
                      </div>
                      <a className="small-box-footer"></a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Fragment>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default Dashboard;
