import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

export const addressupdate = createAsyncThunk(
  "auth/addressupdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/client/bulkaddress/${formData.clientId}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("address Not create");
    }
  }
);

export const send_sms_through_backend = createAsyncThunk(
  "Athentication/send_sms_through_backend",
  async (formData, thunkAPI) => {
    try {
      const config = {
        maxBodyLength: Infinity,
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/admin/sendsms`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("client Not create");
    }
  }
);

export const validateMobile = createAsyncThunk(
  "authentication/validateMobile",
  async (mobile, thunkAPI) => {
    let resp = {
      success: false,
      message: "new mobile",
    };
    try {
      const url = `${Baseurl}/api/v1/shop/mobile/${mobile}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const validatePhoneNumber = createAsyncThunk(
  "user/validatePhoneNumber",
  async (mobile, thunkAPI) => {
    let resp = {
      success: false,
      message: "new Mobile",
    };
    try {
      const url = `${Baseurl}/api/v1/admin/mobile/${mobile}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    mob: "",
    otp: "",
    checkout_authentication_status: 0,
    isAuth: JSON.parse(localStorage.getItem("authState"))?.isAuth
      ? JSON.parse(localStorage.getItem("authState")).isAuth
      : false,
    token: JSON.parse(localStorage.getItem("authState"))?.token
      ? JSON.parse(localStorage.getItem("authState")).token
      : "",
    name: JSON.parse(localStorage.getItem("authState"))?.name
      ? JSON.parse(localStorage.getItem("authState")).name
      : "",
    email: JSON.parse(localStorage.getItem("authState"))?.email
      ? JSON.parse(localStorage.getItem("authState")).email
      : "",
    clientid: JSON.parse(localStorage.getItem("authState"))?.clientid
      ? JSON.parse(localStorage.getItem("authState")).clientid
      : "",
    // adminData: JSON.parse(localStorage.getItem("authState"))?.admin.admin
    //   ? JSON.parse(localStorage.getItem("authState")).admin.admin
    //   : "",
    adminData: "",
  },
  reducers: {
    signin(state, action) {
      localStorage.setItem("authState", JSON.stringify(action.payload));
      state.isAuth = true;
    },
    signout(state, action) {
      localStorage.removeItem("authState");
      // localStorage.clear();
      state.isAuth = false;
    },
    LoginOtp(state, action) {
      state.mob = action.payload.number;
      state.otp = action.payload.OTP;
    },
    set_checkout_authentication_status(state, action) {
      state.checkout_authentication_status = action.payload;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice;
