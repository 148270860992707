import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { Modal } from "antd";
import { send_approve_sms, shopUpdate } from "../../redux/shop/ShopSlice";
import { TbArrowBadgeRight } from "react-icons/tb";

const ApprovedShops = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { approved_shops } = useSelector((store) => store.shop);

  const [search, setSearch] = useState("");
  const [filterOrder, setFilterOrder] = useState([]);
  const [selectedShop, setselectedShop] = useState("");

  const [rejectModel, setrejectModel] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  console.log(filterOrder[0], "filterOrder");

  useEffect(() => {
    if (search.trim() !== "") {
      let result = approved_shops.filter((shop) => {
        return (
          shop.shopId.toLowerCase().match(search.toLowerCase()) ||
          shop.shopName.toLowerCase().match(search.toLowerCase()) ||
          shop.mobile.match(search)
        );
      });
      result = result.map((row, index) => ({
        ...row,
        index: index + 1,
      }));
      setFilterOrder(result);
    } else {
      // let result = approved_shops;
      let result = [...approved_shops].sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA; // Descending order (most recent first)
      });
      result = result.map((row, index) => ({
        ...row,
        index: index + 1,
      }));
      setFilterOrder(result);
    }
  }, [search, approved_shops]);

  const columns = [
    {
      field: "index",
      headerName: "S. No",
      align: "center",
      headerAlign: "center",
      filterable: false,
      headerClassName: "customsheader",
      width: 110,
    },

    {
      field: "shopName",
      headerName: "Shop Name",
      width: 150,
      headerClassName: "customsheader",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "shopId",
      headerName: "Shop Id",
      width: 160,
      headerAlign: "center",
      headerClassName: "customsheader",
      align: "center",
    },
    {
      field: "shopAddress",
      headerName: "Address",
      width: 200,
      headerClassName: "customsheader",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "mobile",
      headerName: "Mobile",
      width: 100,
      headerClassName: "customsheader",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 100,
      headerClassName: "customsheader",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto flex-column">
            <h6>{moment(params.row.createdAt).format("MMM/DD/YYYY")}</h6>
          </div>
        );
      },
    },
    {
      field: "ACTIONS",
      width: 300,
      headerAlign: "center",
      headerClassName: "customsheader",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex">
              <div className="d-flex  flex-column my-3">
                <Button
                  variant="contained"
                  onClick={(e) => {
                    setselectedShop(params.row);
                    setrejectModel(true);
                  }}
                  style={{ borderColor: "#EE4E4E", background: "#EE4E4E" }}
                >
                  Reject
                </Button>
              </div>
              <div className="d-flex  flex-column my-3 ml-1">
                <Button
                  variant="contained"
                  className="mb-2"
                  style={{ borderColor: "#0c359e", background: "#0c359e" }}
                  onClick={() => {
                    setselectedShop(params.row);
                    navigate(`/admin/seller-orders/${params.row.shopId}`);
                  }}
                >
                  View Details
                </Button>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const rejectlok = async () => {
    const formdata = {
      id: selectedShop._id,
      shopStatusText: "Rejected",
      ShopStatus: "3",
    };
    const shop = await dispatch(shopUpdate(formdata));
    if (shop.payload.success === true) {
      setrejectModel(false);
    }
  };
  const approvecancel = async () => {
    setselectedShop("");
    setrejectModel(false);
  };

  const allSmsClcik = async () => {
    for (let index = 0; index < filterOrder.length; index++) {
      const element = filterOrder[index];
      const formdata = {
        mobile: element.mobile,
        name: element.shopDisplayName,
      };
      await dispatch(send_approve_sms(formdata));
    }
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div className="card me-2 ms-2">
            <div className="card-footer">
              <h5>APPROVED SHOPS</h5>
            </div>
            <div className="card-body">
              <Row className="g-2  m-2">
                <Col md>
                  <div className="space-y-1">
                    {/* <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: '500', color: 'rgb(0, 0, 102)' }}
                    >
                      Search by shopId
                    </label> */}
                    <div className="textfieldcontainer">
                      <TextField
                        label="Search by shop Id"
                        variant="outlined"
                        style={{ width: "100%", textTransform: "uppercase" }}
                        type="text"
                        id="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        // className={classes.textfield}
                      />
                    </div>
                  </div>
                </Col>
                <Col md>
                  {/* <div onClick={(e) => allSmsClcik(e)}>SMS Press</div> */}
                </Col>
              </Row>
            </div>
            <div
              style={{ height: 650, width: "100%", overflow: "hidden" }}
              className="d-flex m-auto text-align-center"
            >
              <DataGrid
                // rows={[...filterOrder].reverse()}
                rows={filterOrder}
                columns={columns}
                getRowId={(row) => row._id}
                pageSize={20}
                rowsPerPageOptions={[5]}
                getRowHeight={() => "auto"}
                rowHeight={100}
                sx={{
                  ".MuiDataGrid-columnSeparator": {
                    display: "none",
                  },
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                }}
              />
            </div>
            <Modal
              title="Shop Rejection"
              open={rejectModel}
              onOk={rejectlok}
              confirmLoading={confirmLoading}
              onCancel={approvecancel}
              zIndex={99999}
            >
              <p>Do you want to reject {selectedShop.shopId} this shop</p>
            </Modal>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default ApprovedShops;
