import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { Modal } from "antd";
import { send_approve_sms, shopUpdate } from "../../redux/shop/ShopSlice";
const NotApprovedShops = () => {
  const dispatch = useDispatch();

  const { not_approved_shops } = useSelector((store) => store.shop);

  const [search, setSearch] = useState("");
  const [filterOrder, setFilterOrder] = useState([]);

  const [selectedShop, setselectedShop] = useState("");

  const [approveModel, setapproveModel] = useState(false);
  const [rejectModel, setrejectModel] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    if (search.trim() !== "") {
      let result = not_approved_shops.filter((shop) => {
        return shop.shopId.toLowerCase().match(search.toLowerCase());
      });
      result = result.map((row, index) => ({
        ...row,
        index: index + 1,
      }));
      setFilterOrder(result);
    } else {
      // let result = not_approved_shops;
      let result = [...not_approved_shops].sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA; // Descending order (most recent first)
      });
      result = result.map((row, index) => ({
        ...row,
        index: index + 1,
      }));
      setFilterOrder(result);
    }
  }, [search, not_approved_shops]);

  const columns = [
    {
      field: "index",
      headerName: "S. No",
      align: "center",
      headerClassName: "customsheader",
      headerAlign: "center",
      filterable: false,
      width: 110,
    },

    {
      field: "shopName",
      headerName: "Shop Name",
      width: 150,
      headerClassName: "customsheader",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "shopId",
      headerName: "Shop Id",
      width: 170,
      headerClassName: "customsheader",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "shopAddress",
      headerName: "Address",
      headerClassName: "customsheader",
      width: 200,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "mobile",
      headerName: "Mobile",
      headerClassName: "customsheader",
      width: 155,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 150,
      headerClassName: "customsheader",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto flex-column">
            <h6>{moment(params.row.createdAt).format("MMM/DD/YYYY")}</h6>
          </div>
        );
      },
    },

    {
      field: "ACTIONS",
      width: 150,
      headerClassName: "customsheader",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex  flex-column my-3">
            <Button
              className="mb-2"
              variant="contained"
              onClick={(e) => {
                setselectedShop(params.row);
                setapproveModel(true);
              }}
              style={{ borderColor: "#009FBD", background: "#009FBD" }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              onClick={(e) => {
                setselectedShop(params.row);
                setrejectModel(true);
              }}
              style={{ borderColor: "#EE4E4E", background: "#EE4E4E" }}
            >
              Reject
            </Button>
          </div>
        );
      },
    },
  ];

  const approvelok = async () => {
    const formdata = {
      id: selectedShop._id,
      shopStatusText: "Approve",
      ShopStatus: "2",
    };
    const shop = await dispatch(shopUpdate(formdata));
    if (shop.payload.success === true) {
      const smsform = {
        mobile: selectedShop.mobile,
        name: selectedShop.shopDisplayName,
      };
      console.log(smsform,"smsform");
      await dispatch(send_approve_sms(smsform));
      setapproveModel(false);
    }
  };
  const rejectlok = async () => {
    const formdata = {
      id: selectedShop._id,
      shopStatusText: "Rejected",
      ShopStatus: "3",
    };
    const shop = await dispatch(shopUpdate(formdata));
    if (shop.payload.success === true) {
      setrejectModel(false);
    }
  };
  const approvecancel = async () => {
    setselectedShop("");
    setapproveModel(false);
    setrejectModel(false);
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div className="card me-2 ms-2">
            <div className="card-footer">
              <h5>PENDING SHOPS</h5>
            </div>
            <div className="card-body">
              <Row className="g-2  m-2">
                <Col md>
                  <div className="space-y-1">
                    <div className="textfieldcontainer">
                      <TextField
                        label="Search by shop Id"
                        variant="outlined"
                        style={{ width: "100%", textTransform: "uppercase" }}
                        type="text"
                        id="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        // className={classes.textfield}
                      />
                    </div>
                  </div>
                </Col>
                <Col md></Col>
              </Row>
            </div>
            <div
              style={{ height: 650, width: "100%", overflow: "hidden" }}
              className="d-flex m-auto text-align-center"
            >
              <DataGrid
                rows={filterOrder}
                columns={columns}
                getRowId={(row) => row._id}
                rowHeight={100}
                getRowHeight={() => "auto"}
                pageSize={20}
                rowsPerPageOptions={[5]}
                // getRowClassName={(params) =>
                //   params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                // }
                sx={{
                  ".MuiDataGrid-columnSeparator": {
                    display: "none",
                  },
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                }}
              />
            </div>

            <Modal
              title="Shop Approvel"
              open={approveModel}
              onOk={approvelok}
              confirmLoading={confirmLoading}
              onCancel={approvecancel}
              zIndex={99999}
            >
              <p>Do you want to approve {selectedShop.shopId} this shop</p>
            </Modal>
            <Modal
              title="Shop Rejection"
              open={rejectModel}
              onOk={rejectlok}
              confirmLoading={confirmLoading}
              onCancel={approvecancel}
              zIndex={99999}
            >
              <p>Do you want to reject {selectedShop.shopId} this shop</p>
            </Modal>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default NotApprovedShops;
