import React, { useState, useEffect } from "react";
import { Button, Flex, Form, Select, Col, Divider, Row } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getOneFieldStatus } from "../../../../../redux/fieldStatus/FieldStatusSlice";
import { values } from "pdf-lib";
const { Option } = Select;

const BeautySupercat = ({
  subcategoryid,
  categoryid,
  supercategoryid,
  categoryTagid,
  buttonPress,
  getPropDetails,
  resetield,
}) => {
  return <div>BeautySupercat</div>;
};

export default BeautySupercat;
