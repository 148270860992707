import React, { useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Link } from "react-router-dom";

import { useEffect } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { sliderDelete } from "../../../redux/HomeSlider/HomeSliderSlice";
import { threeSliderDelete } from "../../../redux/ThreeSlider/ThreesliderSlice";

const ThreeSliderList = () => {
  const dispatch = useDispatch();
  const { homeSlider } = useSelector((store) => store.homeSlider);
  const { threeSliderstotal } = useSelector((store) => store.threeSlider);

  const [priority, setPriority] = useState("");
  const [selectSlider, setSelectSlider] = useState("");
  const [cancelmodel, setCancelModel] = useState(false);

  const cancelClick = (e, slider) => {
    setCancelModel(true);
    setSelectSlider(slider);
  };

  const deleteClick = async (e, id) => {
    const data = await dispatch(threeSliderDelete(selectSlider._id));
    setCancelModel(false);
  };

  const columns = [
    {
      field: "index",
      headerName: "S. No",
      align: "center",
      width: 150,
      headerAlign: "center",
      filterable: false,
      headerClassName: "customsheader",
    },
    {
      field: "name",
      headerName: "BANNER NAME",
      width: 260,
      align: "center",
      headerAlign: "center",
      headerClassName: "customsheader",
    },
    {
      field: "mobileImage",
      headerName: "IMAGE",
      width: 230,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            <img
              className="d-flex m-auto"
              src={params.row.mobileImage}
              width={100}
              alt="Banner_Img"
            />
          </>
        );
      },
    },

    {
      field: "priority",
      headerName: "PRIORITY",
      width: 220,
      align: "center",
      headerAlign: "center",
      headerClassName: "customsheader",
    },

    {
      field: "ACTION.",
      width: 200,
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div className="d-flex m-auto">
            <Button
              variant="contained"
              onClick={(e) => cancelClick(e, cellValues.row)}
              color="error"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div className="card me-2 ms-2">
            <div
              style={{ height: 650, width: "100%", overflow: "hidden" }}
              className="d-flex m-auto text-align-center"
            >
              <DataGrid
                rows={threeSliderstotal}
                columns={columns}
                getRowId={(row) => row._id}
                pageSize={10}
                rowsPerPageOptions={[10]}
                // checkboxSelection
              />
            </div>
          </div>
          <Modal
            size="md"
            isOpen={cancelmodel}
            toggle={() => setCancelModel(!cancelmodel)}
          >
            <ModalHeader>
              <div className=" ">
                <div className="col-12 ">
                  <div className="col-12">
                    <h3>
                      Do You Want to{" "}
                      <span style={{ color: "red" }}>Delete</span>{" "}
                      {selectSlider !== "" ? (
                        <>
                          <span
                            style={{
                              color: "#dc3545",
                            }}
                          >
                            {selectSlider.name}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}{" "}
                      Slider
                    </h3>
                    <div className="col-12 mt-3 ">
                      <div className="col-6 d-flex justify-content-between">
                        <Button
                          variant="contained"
                          color="error"
                          className="mx-1 px-5"
                          onClick={() => setCancelModel(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className="mx-1 px-5"
                          onClick={(e) => deleteClick(e)}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalHeader>
          </Modal>
        </div>
      </Fragment>
    </>
  );
};

export default ThreeSliderList;
