import useSelection from "antd/es/table/hooks/useSelection";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  getAllorderByshopId,
  getAllOrderByShopIdLast10Days,
  getAllOrderByShopIdLast30Days,
} from "../../redux/shop/ShopSlice";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Row } from "react-bootstrap";
import { useCallback } from "react";
import debounce from "lodash/debounce";
export const Order = () => {
  const arr = [
    {
      id: "1",
      name: "Packed Order",
    },
    {
      id: "2",
      name: "Dispatch Order",
    },
    {
      id: "3",
      name: "Out For Delivery",
    },
    {
      id: "4",
      name: "Order Delivered",
    },
    {
      id: "5",
      name: "Order Recieved",
    },
    {
      id: "6",
      name: "Order Cancelled",
    },
  ];
  const { shopId } = useParams();
  const [filterOrder, setFilterOrder] = useState([]);
  const [orderid, setOrderId] = useState();
  const [orderValue, setOrderValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [button_value, setbutton_value] = useState("10 days");

  const selectOrder = (evt) => {
    const { value } = evt.target;
    setOrderId(value);
    const textValue = arr.find((indOrder) => indOrder.id === value);
    if (textValue) {
      setOrderValue(textValue.name);
    }
  };
  const changeSearchValue = async (e) => {
    const value = e.target.value;
    const upperValue = value.toUpperCase();
    setSearchValue(upperValue);
  };
  const dispatch = useDispatch();

  const handle_30Days = () => {
    if (shopId) {
      const fetch_30daysOrder = async () => {
        const get30daysorder = await dispatch(
          getAllOrderByShopIdLast30Days(shopId)
        );
        if (get30daysorder.payload.success === true) {
          const order_30days_data = get30daysorder.payload.orders;
          if (orderValue !== "") {
            const content = order_30days_data.filter((ele) => {
              const isMatching = ele.OrderStatusText === orderValue;
              return isMatching;
            });
            let contentFilter = [...content].sort((a, b) =>
              a.createdAt > b.createdAt ? 1 : -1
            );
            contentFilter = contentFilter.map((row, index) => ({
              ...row,
              index: index + 1,
            }));
            setFilterOrder(contentFilter);
          }
          // else if (searchValue.trim() !== "") {
          //     const content = order_30days_data.filter((ele) => {
          //         const isMatching = ele._id.slice(18).toUpperCase() === searchValue;
          //         return isMatching;
          //     });
          //     let contentFilter = [...content].sort((a, b) =>
          //         a.createdAt > b.createdAt ? 1 : -1
          //     );
          //     contentFilter = contentFilter.map((row, index) => ({
          //         ...row,
          //         index: index + 1,
          //     }));
          //     setFilterOrder(contentFilter);
          // }
          else {
            let slotFilter = [...order_30days_data].sort((a, b) =>
              a.createdAt > b.createdAt ? 1 : -1
            );
            slotFilter = slotFilter.map((row, index) => ({
              ...row,
              index: index + 1,
            }));
            setFilterOrder(slotFilter);
          }
        }
      };
      fetch_30daysOrder();
    }
  };
  const handle_10Days = () => {
    if (shopId) {
      const fetch_10daysOrder = async () => {
        const get10daysorder = await dispatch(
          getAllOrderByShopIdLast10Days(shopId)
        );
        if (get10daysorder.payload.success === true) {
          const order_10days_data = get10daysorder.payload.orders;
          if (orderValue !== "") {
            const content = order_10days_data.filter((ele) => {
              const isMatching = ele.OrderStatusText === orderValue;
              return isMatching;
            });
            let contentFilter = [...content].sort((a, b) =>
              a.createdAt > b.createdAt ? 1 : -1
            );
            contentFilter = contentFilter.map((row, index) => ({
              ...row,
              index: index + 1,
            }));
            setFilterOrder(contentFilter);
          }

          // else if (searchValue !== "") {
          //     const content = order_10days_data.filter((ele) => {
          //         const isMatching = ele._id.slice(18).toUpperCase() === searchValue;
          //         return isMatching;
          //     });
          //     let contentFilter = [...content].sort((a, b) =>
          //         a.createdAt > b.createdAt ? 1 : -1
          //     );
          //     contentFilter = contentFilter.map((row, index) => ({
          //         ...row,
          //         index: index + 1,
          //     }));
          //     setFilterOrder(contentFilter);
          // }
          else {
            let slotFilter = [...order_10days_data].sort((a, b) =>
              a.createdAt > b.createdAt ? 1 : -1
            );
            slotFilter = slotFilter.map((row, index) => ({
              ...row,
              index: index + 1,
            }));
            setFilterOrder(slotFilter);
          }
        }
      };
      fetch_10daysOrder();
    }
  };
  useEffect(() => {
    if (searchValue.trim() !== "") {
      let result = filterOrder.filter((order) => {
        return order._id.toLowerCase().match(searchValue.toLowerCase());
      });
      result = result.map((row, index) => ({
        ...row,
        index: index + 1,
      }));
      setFilterOrder(result);
    } else {
      setFilterOrder([...filterOrder]);
    }
  }, [searchValue, filterOrder]);

  useEffect(() => {
    if (shopId && button_value === "10 days") {
      const fetch_order = async () => {
        const getorder = await dispatch(getAllOrderByShopIdLast10Days(shopId));
        if (getorder.payload.success === true) {
          const orders_data = getorder.payload.orders;
          if (orderValue !== "") {
            const content = orders_data.filter((ele) => {
              const isMatching = ele.OrderStatusText === orderValue;
              return isMatching;
            });
            let contentFilter = [...content].sort((a, b) =>
              a.createdAt > b.createdAt ? 1 : -1
            );
            contentFilter = contentFilter.map((row, index) => ({
              ...row,
              index: index + 1,
            }));
            setFilterOrder(contentFilter);
          } else if (searchValue !== "") {
            const content = orders_data.filter((ele) => {
              const isMatching =
                ele._id.slice(18).toUpperCase() === searchValue;
              return isMatching;
            });
            let contentFilter = [...content].sort((a, b) =>
              a.createdAt > b.createdAt ? 1 : -1
            );
            contentFilter = contentFilter.map((row, index) => ({
              ...row,
              index: index + 1,
            }));
            setFilterOrder(contentFilter);
          } else {
            let slotFilter = [...orders_data].sort((a, b) =>
              a.createdAt > b.createdAt ? 1 : -1
            );
            slotFilter = slotFilter.map((row, index) => ({
              ...row,
              index: index + 1,
            }));
            setFilterOrder(slotFilter);
          }
        }
      };
      fetch_order();
    }
  }, [shopId, button_value, orderValue, orderid]);

  const columns = [
    {
      field: "_id",
      headerName: "Order Id",
      width: 100,
      align: "center",
      headerClassName: "customsheader",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            <span>{params.row._id.slice(18).toUpperCase()}</span>
          </>
        );
      },
    },
    {
      field: "ProductName",
      headerName: "Product Information",
      width: 250,
      align: "center",
      headerAlign: "center",
      headerClassName: "customsheader",
    },
    {
      field: "Address",
      headerName: "Buyer Details",
      width: 250,
      headerAlign: "center",
      align: "center",
      headerClassName: "customsheader",
    },
    {
      field: "TotalPrice",
      headerName: "Amount",
      width: 200,
      headerAlign: "center",
      align: "center",
      headerClassName: "customsheader",
    },
    {
      field: "createdAt",
      headerName: " Order Date",
      width: 100,
      headerClassName: "customsheader",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto flex-column">
            <h6>{moment(params.row.createdAt).format("MMM/DD/YYYY")}</h6>
          </div>
        );
      },
    },

    {
      field: "OrderStatusText",
      headerName: "Order Status",
      width: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "customsheader",
    },
  ];

  return (
    <>
      <div className="content-wrapper">
        <div className="card me-2 ms-2">
          <div className="card-footer">
            <h5>Orders</h5>
          </div>
          <div className="card-body">
            <Row className="g-2  m-2">
              <Col md>
                <div className="space-y-1">
                  {/* <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: '500', color: 'rgb(0, 0, 102)' }}
                        >
                          Field Name
                        </label> */}
                  <div className="textfieldcontainer">
                    <TextField
                      label="Search Order"
                      variant="outlined"
                      type="text"
                      value={searchValue}
                      onChange={(e) => changeSearchValue(e)}
                      id="text"
                      style={{ width: "100%", textTransform: "capitalize" }}
                    />
                  </div>
                </div>
              </Col>
              <Col md>
                <div className="space-y-1">
                  <FormControl fullWidth className="textfield">
                    <InputLabel
                      id="demo-simple-Case-Type"
                      className="inputcenter"
                    >
                      Select Order Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-Case-Type"
                      id="demo-simple-select"
                      label="Select Order Status"
                      onChange={(e) => selectOrder(e)}
                      // onChange={(e) => selectsuperCategory(e)}
                      value={orderid}
                      style={{ height: "38px" }}
                      inputProps={{ style: { textAlign: "center" } }}
                      // className={orderError !== "" ? "error_class" : ""}
                    >
                      {arr.map((test, index) => (
                        <MenuItem key={index} value={test.id}>
                          {test.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Col>
              <Col md>
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  <button
                    className="btn btn-primary"
                    id="demoq"
                    onClick={() => {
                      handle_10Days();
                      setbutton_value("10 days");
                    }}
                    style={
                      button_value === "10 days"
                        ? { backgroundColor: "#0c359e", borderColor: "#0c359e" }
                        : {
                            borderRadius: "5px",
                            backgroundColor: "#7979d2",
                            borderColor: "#7979d2",
                          }
                    }
                  >
                    10 days
                  </button>
                  <button
                    className="btn btn-primary"
                    id="demoq"
                    onClick={() => {
                      handle_30Days();
                      setbutton_value("30 days");
                    }}
                    style={
                      button_value === "30 days"
                        ? { backgroundColor: "#0c359e", borderColor: "#0c359e" }
                        : {
                            borderRadius: "5px",
                            backgroundColor: "#7979d2",
                            borderColor: "#7979d2",
                          }
                    }
                  >
                    30 days
                  </button>
                </div>
              </Col>
            </Row>
          </div>

          <div
            style={{ height: 650, width: "100%", overflow: "hidden" }}
            className="d-flex m-auto text-align-center"
          >
            <DataGrid
              rows={filterOrder}
              columns={columns}
              getRowId={(row) => row._id}
              pageSize={20}
              rowsPerPageOptions={[5]}
              getRowHeight={() => "auto"}
              rowHeight={100}
              sx={{
                ".MuiDataGrid-columnSeparator": {
                  display: "none",
                },
                "&.MuiDataGrid-root": {
                  border: "none",
                },
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
