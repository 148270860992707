import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Link } from "react-router-dom";
import {
  subCategoryDelete,
  subCategoryUpdate,
} from "../../../redux/subCategory/subCategorySlice";
import { Modal, ModalHeader } from "reactstrap";

const ListSubCategory = () => {
  const dispatch = useDispatch();
  const { subcategorytotal } = useSelector((store) => store.subCategories);
  const { categorytotal } = useSelector((store) => store.category);
  const { superCatTotal } = useSelector((store) => store.superCategory);
  const [supercategory, setSupercategory] = useState("");
  const [supercategoryid, setSupercategoryid] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [catbySupercat, setCatbySupercat] = useState([]);
  const [subcatbyCat, setSubcatbyCat] = useState([]);
  console.log(subcatbyCat ,"subcatbyCat");
  const [selectCategorys, setSelectCategory] = useState("");
  const [cancelmodel, setCancelModel] = useState(false);
  const selectsupCategory = (evt) => {
    // console.log(evt.target,"evt.target");
    const { value } = evt.target;
    setSupercategoryid(value);
    // console.log(value, "Value");
    const textValue = superCatTotal.find((indSuperCat) =>
      indSuperCat._id === evt.target.value
    )
    // console.log(textValue, "textValue");
    if (textValue) {
      setSupercategory(textValue.name);
      // console.log(textValue.name, "name");
    }
  };

  const selectCategory = (evt) => {
    const value = evt.target.value;
    setCategoryid(value);
    const textvalue = catbySupercat.find((indCat) =>
      indCat._id === value
    )

    if (textvalue) {
      setCategory(textvalue.name);
    }
  };
  useEffect(() => {
    const catbysupercat = categorytotal.filter(
      (cat) => cat.superCategoryId === supercategoryid
    );
    setCatbySupercat(catbysupercat);
  }, [supercategoryid, categorytotal]);

  useEffect(() => {

    if (categoryid === "") {
      let slotFilter = [...subcategorytotal].sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA; // Descending order (most recent first)
      });
      slotFilter = slotFilter.map((row, index) => ({
        ...row,
        index: index + 1,
      }));
      setSubcatbyCat(slotFilter);
    } else {
      const subcatbycat = subcategorytotal.filter(
        (cat) => cat.categoryId === categoryid
      );
      let slotFilter = [...subcatbycat].sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      slotFilter = slotFilter.map((row, index) => ({
        ...row,
        index: index + 1,
      }));

      setSubcatbyCat(slotFilter);
    }
  }, [categoryid, subcategorytotal]);

  const deleteClick = async (e, id) => {
    const data = await dispatch(subCategoryDelete(selectCategorys._id));
    setCancelModel(false);
  };
  const cancelClick = (e, subcategory) => {
    setCancelModel(true);
    setSelectCategory(subcategory);
  };
  const statusChange = async (e, data) => {
    e.preventDefault();
    // console.log(data);
    const value = e.target.checked;
    if (data) {
      const formData = {
        subcatid: data._id,
        status: value,
      };
      const updateCat = await dispatch(subCategoryUpdate(formData));
    }
  };
  const columns = [
    {
      field: "index",
      headerName: "S. No",
      align: "center",
      width: 200,
      headerAlign: "center",
      filterable: false,
      headerClassName: 'customsheader'
    },
    {
      field: "name",
      headerName: "SUB-CATEGORY NAME",
      width: 240,
      // align: "center",
      headerAlign: "center",
      headerClassName: 'customsheader'
    },
    // {
    //   field: "thumbnail",
    //   headerName: "IMAGE",
    //   width: 230,
    //   headerAlign: "center",

    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <img
    //           className="d-flex m-auto"
    //           src={params.row.thumbnail}
    //           width={100}
    //           alt="thumnail"
    //         />
    //       </>
    //     );
    //   },
    // },
    {
      field: "STATUS",
      width: 200,
      headerAlign: "center",
      align: "center",
      headerClassName: 'customsheader',
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.status}
                  checked={params.row.status}
                  onChange={(e) => statusChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },

    {
      field: "priority",
      headerName: "PRIORITY",
      width: 200,
      align: "center",
      headerAlign: "center",
      headerClassName: 'customsheader'
    },

    // {
    //   field: "ACTION",
    //   width: 200,
    //   headerAlign: "center",
    //   renderCell: (params) => {
    //     return (
    //       <div className="d-flex m-auto">
    //         <Link to={`/admin/update-subcategory/${params.row.slugUrl}`}>
    //           <Button variant="contained" color="primary">
    //             EDIT
    //           </Button>
    //         </Link>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   field: "ACTION.",
    //   width: 200,
    //   headerAlign: "center",
    //   renderCell: (cellValues) => {
    //     return (
    //       <div className="d-flex m-auto my-2">
    //         <Button
    //           variant="contained"
    //           onClick={(e) => cancelClick(e, cellValues.row)}
    //           color="error"
    //         >
    //           Delete
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div className="card me-2 ms-2">
            <div className="card-body">
              <Row className="g-2  m-2">
                <Col md>
                  <div className="space-y-1">
                    <FormControl fullWidth className="textfield">
                      <InputLabel id="demo-simple-Case-Type" className="inputcenter"> Select Super Category</InputLabel>
                      <Select
                        labelId="demo-simple-Case-Type"
                        id="demo-simple-select"
                        label="Select Super Category"
                        style={{ height: '38px' }}
                        // inputProps={{ style: { textAlign: 'center' } }}
                        onChange={(e) => {
                          selectsupCategory(e);
                        }}
                        value={supercategoryid}
                        name={superCatTotal.name}
                      >
                        {superCatTotal.map((test, index) =>
                          <MenuItem value={test._id} key={test._id}
                            name={test.name}
                            required
                          >{test.name}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </div>
                </Col>
                {/* <Col md>
                  <Form.Label htmlFor="disabledTextInput">Category</Form.Label>
                  <Form.Select
                    aria-label="Floating label select example"
                    onChange={(e) => {
                      selectCategory(e);
                    }}
                    value={catbySupercat._id}
                    name={catbySupercat.name}
                  >
                    <option hidden selected>
                      {"Select Category"}
                    </option>
                    {catbySupercat.map((data) => (
                      <option
                        key={data.id}
                        value={data.id}
                        name={data.name}
                        required
                      >
                        {data.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col> */}
                <Col md >
                  <div className="space-y-1">
                    <FormControl fullWidth className="textfield">
                      <InputLabel id="demo-simple-Case-Type" className="inputcenter">Select Category</InputLabel>
                      <Select
                        labelId="demo-simple-Case-Type"
                        id="demo-simple-select"
                        label="Select Category"
                        onChange={(e) => {
                          selectCategory(e);
                        }}
                        value={categoryid._id}
                        name={catbySupercat.name}
                        style={{ height: '38px' }}
                      // inputProps={{ style: { textAlign: 'center' } }}
                      >
                        {catbySupercat.map((test, index) =>
                          <MenuItem value={test._id} key={test._id}
                            name={test.name}
                            required
                          >{test.name}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </div>
                </Col>
              </Row>
            </div>
            <div
              style={{ height: 650, width: "100%" }}
              className="d-flex m-auto text-align-center"
            >
              <DataGrid
                rows={subcatbyCat}
                columns={columns}
                getRowId={(row) => row._id}
                // pageSize={10}
                rowHeight={100}
                getRowHeight={() => "auto"}
                rowsPerPageOptions={[10]}
              // checkboxSelection
              />
            </div>
          </div>
          <Modal
            size="md"
            isOpen={cancelmodel}
            toggle={() => setCancelModel(!cancelmodel)}
          >
            <ModalHeader>
              <div className=" ">
                <div className="col-12 ">
                  <div className="col-12">
                    <h3>
                      Do You Want to{" "}
                      <span style={{ color: "red" }}>Delete</span>{" "}
                      {selectCategorys !== "" ? (
                        <>
                          <span
                            style={{
                              color: "#dc3545",
                            }}
                          >
                            {selectCategorys.name}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}{" "}
                      Sub-Category
                    </h3>
                    <div className="col-12 mt-3 ">
                      <div className="col-6 d-flex justify-content-between">
                        <Button
                          variant="contained"
                          color="error"
                          className="mx-1 px-5"
                          onClick={() => setCancelModel(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className="mx-1 px-5"
                          onClick={(e) => deleteClick(e)}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalHeader>
          </Modal>
        </div>
      </Fragment>
    </>
  );
};

export default ListSubCategory;
