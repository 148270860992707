import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import {
  superCategoryDelete,
  updateSuperCategory,
} from "../../../redux/superCategory/superCategorySlice";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";
import { useToasts } from "react-toast-notifications";

const ListSuperCategory = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { superCatTotal } = useSelector((store) => store.superCategory);
  const [supcat, setSupCat] = useState([]);

  useEffect(() => {
    let slotFilter = [...superCatTotal].sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
    slotFilter = slotFilter.map((row, index) => ({
      ...row,
      index: index + 1,
    }));
    setSupCat(slotFilter);
  }, [superCatTotal]);

  const deleteClick = async (e, id) => {
    const data = await dispatch(superCategoryDelete(id));
    if (data.payload.success) {
      addToast("Supercategory Deleted", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 6000,
        placement: "bottom-right",
        style: { zIndex: 9999 },
      });
    }
  };

  const statusChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        supercatid: data._id,
        showProducts: value,
      };
      const updateCat = await dispatch(updateSuperCategory(formData));

      if (data.name === "Supermart") {
        const formData = {
          _id: "657147425855fb1b4052dbd7",
          superMartShown: !value,
        };
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/massage/${formData._id}`;
        const resp = await axios.put(url, formData, config);
      }
    }
  };
  const columns = [
    {
      field: "index",
      headerName: "S. No",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: 'customsheader',
      filterable: false,
    },
    {
      field: "name",
      headerName: "SUPER CATEGORY NAME",
      headerClassName: 'customsheader',
      width: 250,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "ACTION",
    //   width: 140,
    //   headerAlign: "center",
    //   renderCell: (params) => {
    //     return (
    //       <div className="d-flex m-auto">
    //         <Link to={`/admin/updatesupercategory/${params.row.slugUrl}`}>
    //           <Button variant="contained" color="primary">
    //             EDIT
    //           </Button>
    //         </Link>
    //       </div>
    //     );
    //   },
    // },
    {
      field: "ACTION.",
      width: 200,
      headerAlign: "center",
      headerClassName: 'customsheader',
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto">
            <Button
              variant="contained"
              onClick={(e) => deleteClick(e, params.row._id)}
              className="deletebuttomss"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
    {
      field: "SHOW PRODUCTS",
      width: 250,
      headerAlign: "center",
      align: "center",
      headerClassName: 'customsheader',
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.showProducts}
                  checked={params.row.showProducts}
                  onChange={(e) => statusChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },
    // {
    //   field: "ACTION.",
    //   width: 240,
    //   headerAlign: "center",
    //   renderCell: (cellValues) => {
    //     return (
    //       <div className="d-flex m-auto">
    //         <Button
    //           variant="contained"
    //           onClick={(e) => deleteClick(e, cellValues.row._id)}
    //           color="error"
    //         >
    //           Delete
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          {/* <div
            style={{ height: 100, width: "100%" }}
            className="d-flex m-auto text-align-center"
          >
            <div className="d-flex m-auto">
              <Col>
                <Form.Label>Show Supermart</Form.Label>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={statusG}
                  checked={statusG}
                  onChange={(e) => statusChangeSupermart(e, adminData)}
                />
              </Col>
            </div>
          </div> */}
          <div
            style={{ height: 650, width: "100%" }}
            className="d-flex m-auto text-align-center"
          >
            <DataGrid
              rows={supcat}
              columns={columns}
              getRowId={(row) => row._id}
              pageSize={10}
              rowsPerPageOptions={[10]}
            />
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default ListSuperCategory;
