import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MobileLogin from "./MobileLogin";
import OtpVerify from "./OtpVerify";

const Authentication = () => {
  const { checkout_authentication_status } = useSelector((state) => state.auth);

  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return <MobileLogin />;
      case 1:
        return <OtpVerify />;

      default:
        return <MobileLogin />;
        break;
    }
  };

  return <>{renderSwitch(checkout_authentication_status)}</>;
};

export default Authentication;
