import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { categoryPost } from "../../../redux/category/categorySlice";
import { useToasts } from "react-toast-notifications";
import { PostField } from "../../../redux/field/FieldSlice";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
// import { toast } from 'react-toastify';
// import { toast } from 'react-toastify';
const FieldAdd = () => {
  const { superCatTotal } = useSelector((store) => store.superCategory);
  const { addToast } = useToasts();
  const [supcategroyError, setsupcategroyError] = useState("");
  const [categroy, setCategroy] = useState("");
  const [slugUrlError, setSlugUrlError] = useState("");
  const [imageError, setimageError] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [supercat, setSupercat] = useState("");
  const [supercatid, setSupercatid] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const [result, setResult] = useState([]);
  const [buttonPress, setbuttonPress] = useState(false);
  const [buttonLoading, setbuttonLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (buttonPress === true) {
      if (supercatid === "") {
        setsupcategroyError("Required");
      } else {
        setsupcategroyError("");
      }
      if (checkslugUrl === "") {
        setSlugUrlError("Required");
      } else {
        setSlugUrlError("");
      }
    }
  }, [buttonPress, supercatid, checkslugUrl]);

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setCategroy(value);
    setCheckSlugUrl(value);
    setSlugUrl(value);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setbuttonPress(true);
    setbuttonLoading(true);

    if (supercatid === "") {
      setsupcategroyError("Required");
    } else {
      setsupcategroyError("");
    }
    if (checkslugUrl === "") {
      setSlugUrlError("Required");
    } else {
      setSlugUrlError("");
    }

    if (checkslugUrl !== "" && supercatid !== "") {
      const formData = {
        name: checkslugUrl,
        superCategoryId: supercatid,
        superCategory: supercat,
      };

      const data = await dispatch(PostField(formData));
      if (data.payload.success) {

        alert("Field Added")
        // addToast('Field Added', {
        //   appearance: 'success',
        //   autoDismiss: true,
        //   autoDismissTimeout: 2000,
        //   placement: 'bottom-right',
        //   style: {
        //     background: 'green',
        //     color: 'white',
        //     border: '2px solid darkgreen',
        //     zIndex: 99999,
        //   },
        // })
        setCategroy("");
        setSlugUrl("");
        setCheckSlugUrl("");
        setSupercatid("");
        setsupcategroyError("");
        setSlugUrlError("");
        setbuttonPress(false);
        setSupercat("")
      } else {
        setbuttonLoading(false);
      }
    } else {
      setbuttonLoading(false);
    }
  };



  const selectSuperCat = (evt) => {
    const { value } = evt.target;
    setSupercatid(value);
    const textValue = superCatTotal.find((indSuper) =>
      indSuper._id === value
    )
    if (textValue) {
      setSupercat(textValue.name);
    }
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card me-2 ms-2 mt-4">
                <div className="card-footer">
                  <h5>Add Field</h5>
                </div>
                <div className="card-body">
                  <Row className="g-3  m-2 ">
                    <Col md>
                      <div className="space-y-1">
                        {/* <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: '700', color: 'rgb(0, 0, 102)', fontSize: '22px' }}
                        >
                          Super Category
                        </label> */}
                        <FormControl fullWidth className="textfield">
                          <InputLabel id="demo-simple-Case-Type" className="inputcenter"> Select Super Category</InputLabel>
                          <Select
                            labelId="demo-simple-Case-Type"
                            id="demo-simple-select"
                            label="Select Super Category"
                            onChange={(e) => {
                              selectSuperCat(e);
                            }}
                            // value={superCatTotal._id}
                            value={supercatid}
                            name={superCatTotal.name}
                            className={supcategroyError !== "" ? "error_class" : ""}
                            style={{ height: '38px' }}
                            inputProps={{ style: { textAlign: 'center' } }}
                          >
                            {superCatTotal.map((test, index) =>
                              <MenuItem value={test._id} key={index}
                                name={test.name}
                                selected={supercatid === test._id ? true : false}
                              >{test.name}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </Col>
                    <Col md>
                      <div className="space-y-1">
                        {/* <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: '500', color: 'rgb(0, 0, 102)' }}
                        >
                          Field Name
                        </label> */}
                        <div className="textfieldcontainer">
                          <TextField
                            label="Enter Field"
                            variant="outlined"
                            type="text"
                            value={categroy}
                            onChange={(e) => verifyslugurl(e)}
                            className={slugUrlError !== "" ? "error_class" : ""}
                            id="text"
                            style={{ width: '100%', textTransform: "capitalize" }}

                          // className={classes.textfield}
                          />
                          <p style={{ color: errorcolor }} className="mt-2">
                            {error}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  {buttonLoading ? (
                    <button className="btn btn-primary" style={{ background: "#000066", borderColor: "#000066" }}>Add Field</button>
                  ) : (
                    <button className="btn btn-primary" type="submit" style={{ background: "#000066", borderColor: "#000066" }}>
                      Add Field
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default FieldAdd;
