
import useSelection from "antd/es/table/hooks/useSelection";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getAllOrderByShopIdLast10Days } from "../../redux/shop/ShopSlice";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Row } from "react-bootstrap";
import { useCallback } from "react";
import debounce from "lodash/debounce";
import { validateMobile } from "../../redux/Athentication/AuthSlice";

const ChangeShopPassword = () => {
  const dispatch = useDispatch();

  const [mobile, setMobile] = useState("");
  const [mobile_confirm, setMobile_confirm] = useState("");
  const [mobileError, setMobileError] = useState("");

  const [buttonPress, setbuttonPress] = useState(false);
  const [show_order_list, setshow_order_list] = useState(false);

  const [sellerDetails, setsellerDetails] = useState("");

  const [fetchOrdersOrder, setFetchOrdersOrder] = useState([]);
  const [filterOrder, setFilterOrder] = useState([]);
  const [orderid, setOrderId] = useState();
  const [orderValue, setOrderValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [button_value, setbutton_value] = useState("10 days");

  const newSearch = () => {
    setSearchValue("");
    setOrderId("");
    setsellerDetails("");
    setMobile("");
    setMobile_confirm("");
    setMobileError("");
    setFilterOrder([]);
    setFetchOrdersOrder([]);
    setshow_order_list(false);
  };

  const arr = [
    {
      id: "8",
      name: "All Order",
    },
    {
      id: "5",
      name: "Order Recieved",
    },
    {
      id: "1",
      name: "Packed Order",
    },
    {
      id: "2",
      name: "Dispatch Order",
    },
    {
      id: "3",
      name: "Out For Delivery",
    },
    {
      id: "4",
      name: "Order Delivered",
    },
    {
      id: "6",
      name: "Order Cancelled",
    },
  ];

  const selectOrder = (value) => {
    setOrderId(value);
    setSearchValue("");
    if (value === "All Order" || value === undefined) {
      let result = fetchOrdersOrder;
      result = result.map((row, index) => ({
        ...row,
        index: index + 1,
      }));
      setFilterOrder(result);
    } else {
      let result = fetchOrdersOrder.filter(
        (order) => order.OrderStatusText === value
      );
      result = result.map((row, index) => ({
        ...row,
        index: index + 1,
      }));
      setFilterOrder(result);
    }
  };
  const changeSearchValue = async (e) => {
    const value = e.target.value.toUpperCase();
    setSearchValue(value);
    setOrderId("");
  };

  useEffect(() => {
    if (searchValue.trim() !== "") {
      let result = fetchOrdersOrder.filter((order) => {
        return order._id
          .slice(18)
          .toLowerCase()
          .match(searchValue.toLowerCase());
      });
      result = result.map((row, index) => ({
        ...row,
        index: index + 1,
      }));
      setFilterOrder(result);
    } else {
      setFilterOrder([...fetchOrdersOrder]);
    }
  }, [fetchOrdersOrder, searchValue]);

  useEffect(() => {
    if (buttonPress === true) {
      if (mobile_confirm.length !== 10) {
        setMobileError("Please enter your mobile number");
      } else {
        setMobileError("");
      }
    }
  }, [buttonPress, mobile_confirm]);

  const mobileValideteChange = async (e) => {
    let inputValue = e.replace(/\D/g, "");
    setMobile(inputValue);
    if (inputValue.length !== 10) {
      setMobileError("Please enter valid mobile number");
      setMobile_confirm("");
    } else {
      const mobileValidate = await dispatch(validateMobile(inputValue));
      if (mobileValidate.payload.success === false) {
        setMobileError("Mobile number not availble");
        setMobile_confirm("");
      } else {
        const seller = mobileValidate.payload.shop;
        setsellerDetails(seller);
        setMobileError("");
        setMobile_confirm(inputValue);
      }
    }
  };

  const getSellerOrders = async (e) => {
    e.preventDefault();
    setbuttonPress(true);

    if (mobile_confirm.length !== 10) {
      setMobileError("Please enter your mobile number");
    } else {
      setMobileError("");
    }

    if (mobile_confirm.length === 10) {
      const getorder = await dispatch(
        getAllOrderByShopIdLast10Days(sellerDetails.shopId)
      );

      if (getorder.payload.success === true) {
        const orders_data = getorder.payload.orders;
        let contentFilter = [...orders_data].sort((a, b) =>
          a.createdAt > b.createdAt ? 1 : -1
        );
        contentFilter = contentFilter.map((row, index) => ({
          ...row,
          index: index + 1,
        }));
        setFilterOrder(contentFilter);
        setFetchOrdersOrder(contentFilter);
        setshow_order_list(true);
        setbuttonPress(false);
      }
    } else {
      setbuttonPress(false);
    }
  };

  const columns = [
    {
      field: "_id",
      headerName: "Order Id",
      width: 100,
      align: "center",
      headerClassName: "customsheader",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            <span>{params.row._id.slice(18).toUpperCase()}</span>
          </>
        );
      },
    },
    {
      field: "ProductName",
      headerName: "Product Information",
      width: 250,
      align: "center",
      headerAlign: "center",
      headerClassName: "customsheader",
    },
    {
      field: "Address",
      headerName: "Buyer Details",
      width: 250,
      headerAlign: "center",
      align: "center",
      headerClassName: "customsheader",
    },
    {
      field: "TotalPrice",
      headerName: "Amount",
      width: 200,
      headerAlign: "center",
      align: "center",
      headerClassName: "customsheader",
    },
    {
      field: "createdAt",
      headerName: " Order Date",
      width: 100,
      headerClassName: "customsheader",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto flex-column">
            <h6>{moment(params.row.createdAt).format("MMM/DD/YYYY")}</h6>
          </div>
        );
      },
    },

    {
      field: "OrderStatusText",
      headerName: "Order Status",
      width: 150,
      headerAlign: "center",
      align: "center",
      headerClassName: "customsheader",
    },
  ];

  return (
    <>
      {show_order_list === false ? (
        <>
          <div className="content-wrapper">
            <div className="card me-2 ms-2 mt-2">
              <div className="card-footer">
                <h5>Search Seller</h5>
              </div>
            </div>
            <div className="card-body">
              <Row className="g-2  m-1">
                <Col md>
                  <div className="space-y-1">
                    <div className="textfieldcontainer">
                      <TextField
                        label="Seller Mobile Number"
                        className="textfield "
                        type="text"
                        id="outlined"
                        error={mobileError !== "" ? true : false}
                        helperText={mobileError}
                        style={{ width: "100%" }}
                        value={mobile}
                        onChange={(e) => mobileValideteChange(e.target.value)}
                        inputProps={{
                          minLength: 10,
                          maxLength: 10,
                        }}
                      />
                    </div>
                  </div>
                </Col>

                <Col md>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <button
                      className="btn btn-primary"
                      id="demoq"
                      onClick={(e) => getSellerOrders(e)}
                      style={{
                        backgroundColor: "#0c359e",
                        borderColor: "#0c359e",
                      }}
                    >
                      Search Seller Order
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="content-wrapper">
            <div className="card me-2 ms-2">
              <div className="card-footer">
                <h5>Seller Orders History</h5>
              </div>
              <div className="card-body">
                <Row className="g-2  m-1">
                  <Col md>
                    <div className="space-y-1">
                      <div className="textfieldcontainer">
                        <TextField
                          label="Seller Mobile Number"
                          className="textfield "
                          type="text"
                          id="outlined"
                          error={mobileError !== "" ? true : false}
                          helperText={mobileError}
                          style={{ width: "100%" }}
                          value={mobile}
                          onChange={(e) => mobileValideteChange(e.target.value)}
                          inputProps={{
                            minLength: 10,
                            maxLength: 10,
                          }}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <button
                        className="btn btn-primary"
                        id="demoq"
                        onClick={(e) => getSellerOrders(e)}
                        style={{
                          backgroundColor: "#0c359e",
                          borderColor: "#0c359e",
                        }}
                      >
                        Search Seller Order
                      </button>
                    </div>
                  </Col>
                </Row>
                <Row className="g-2  m-2">
                  <Col md>
                    <div className="space-y-1">
                      <div className="textfieldcontainer">
                        <TextField
                          label="Search Order"
                          variant="outlined"
                          type="text"
                          value={searchValue}
                          onChange={(e) => changeSearchValue(e)}
                          id="text"
                          style={{ width: "100%", textTransform: "capitalize" }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md>
                    <div className="space-y-1">
                      <FormControl fullWidth className="textfield">
                        <InputLabel
                          id="demo-simple-Case-Type"
                          className="inputcenter"
                        >
                          Select Order Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-Case-Type"
                          id="demo-simple-select"
                          label="Select Order Status"
                          onChange={(e) => selectOrder(e.target.value)}
                          value={orderid}
                          style={{ height: "38px" }}
                          inputProps={{ style: { textAlign: "center" } }}
                        >
                          {arr.map((test, index) => (
                            <MenuItem key={index} value={test.name}>
                              {test.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Col>
                  {/* <Col md>
                    <button
                      className="btn btn-primary"
                      id="demoq"
                      onClick={() => newSearch()}
                      style={{
                        backgroundColor: "#0c359e",
                        borderColor: "#0c359e",
                        float: "right",
                      }}
                    >
                      New Search
                    </button>
                  </Col> */}
                </Row>
              </div>

              <div
                style={{ height: 650, width: "100%", overflow: "hidden" }}
                className="d-flex m-auto text-align-center"
              >
                <DataGrid
                  rows={filterOrder}
                  columns={columns}
                  getRowId={(row) => row._id}
                  pageSize={20}
                  rowsPerPageOptions={[5]}
                  getRowHeight={() => "auto"}
                  rowHeight={100}
                  sx={{
                    ".MuiDataGrid-columnSeparator": {
                      display: "none",
                    },
                    "&.MuiDataGrid-root": {
                      border: "none",
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ChangeShopPassword;
