import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  resetSlider,
  sliderImages,
  sliderPost,
  validateSlugUrl,
} from "../../../redux/ThreeSlider/ThreesliderSlice";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

const ThreeSliderAdd = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const { categoryShow } = useSelector((store) => store.category);
  const {
    mobileimage,
    desktopimage,
    ismobileImageLoading,
    isDeskImageLoading,
  } = useSelector((store) => store.threeSlider);

  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuth) {
      navigate("/");
    }
  }, []);

  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const [text, setText] = useState("");
  const [category, setCategory] = useState("");
  const [categorySlugurl, setCategorySlugUrl] = useState("");

  // Error message
  const [error, setError] = useState("");

  const [selectedPosition, setSelectedPosition] = useState(null);

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setName(value);
    setCheckSlugUrl("");
    const sliderSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(sliderSlug);

    if (sliderSlug !== "") {
      let responce = await dispatch(validateSlugUrl(sliderSlug));
      if (responce.payload.success) {
        setError("slider alredy exist");
        setErrorcolor("red");
      } else if (sliderSlug.length <= 2) {
        setErrorcolor("red");
        setError("minimum 3 letters");
        setCheckSlugUrl("");
      } else if (sliderSlug.length >= 3) {
        setErrorcolor("");
        setError("");
        setCheckSlugUrl(sliderSlug);
      }
    }
  };

  const selectCategory = (evt) => {
    const { value } = evt.target;
    setCategorySlugUrl(value)
    setCategory(value);

  };

  const handlePositionChange = (position) => {
    if (position === selectedPosition) {
      setSelectedPosition(null); // Deselect if already selected
    } else {
      setSelectedPosition(position); // Select the clicked position
    }
  };

  const categoryImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (selectedPosition === null) {
      alert("Please select position first");
    }
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(sliderImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    if (
      name !== "" &&
      text !== "" &&
      !ismobileImageLoading &&
      !isDeskImageLoading
    ) {
      const formData = {
        name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
        slugUrl: checkslugUrl,
        text: text,
        category: category,
        categoryUrl: categorySlugurl,
        mobileImage: mobileimage,
        desktopImage: desktopimage,
        superCategoryId: "6612e068c43ce3641948d3b6",
        superCategory: "Electronics",
      };
      if (selectedPosition === "position1") {
        formData.position1 = true;
      } else if (selectedPosition === "position2") {
        formData.position2 = true;
      } else if (selectedPosition === "position3") {
        formData.position3 = true;
      }
      const data = await dispatch(sliderPost(formData));
     
      if (!data) {
        alert("please check the flields");
      } else {
        if (data.payload.success) {
          alert("slider Uploaded");
          dispatch(resetSlider());
          setName("");
          setCategorySlugUrl("")
          setText("");
          setCategory("")
          setSelectedPosition(null);
        } else {
          alert(data.message);
        }
      }
    } else {
      alert("please enter correct data");
    }
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Add Three Slider</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Add Three Slider</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <div className="space-y-1">
                        {/* <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: '500', color: 'rgb(0, 0, 102)' }}
                        >
                          Slider Name
                        </label> */}
                        <div className="textfieldcontainer">
                          <TextField
                            label="Enter Slider Name"
                            variant="outlined"
                            style={{ width: '100%' }}
                            type="text"
                            id="text"
                            value={name}
                            onChange={(e) => verifyslugurl(e)}
                          // className={classes.textfield}
                          />

                        </div>
                      </div>
                      <p style={{ color: "red" }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                    <Col md>
                      <div className="space-y-1">
                        {/* <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: '500', color: 'rgb(0, 0, 102)' }}
                        >
                          Text
                        </label> */}
                        <div className="textfieldcontainer">
                          <TextField
                            label="Enter Text"
                            variant="outlined"
                            style={{ width: '100%' }}
                            type="text"
                            id="text"
                            maxLength={30}
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                          // className={classes.textfield}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {
                      categoryShow.length > 1 && (
                        <Col md>
                          <div className="space-y-1  space_another">
                            {/* <label
                              htmlFor="fName"
                              className="text-sm font-medium text-default-900"
                              style={{ fontWeight: '700', color: 'rgb(0, 0, 102)', fontSize: '22px' }}
                            >
                              Category Name
                            </label> */}
                            <FormControl fullWidth className="textfield">
                              <InputLabel id="demo-simple-Case-Type" className="inputcenter">Select Category</InputLabel>
                              <Select
                                labelId="demo-simple-Case-Type"
                                id="demo-simple-select"
                                onChange={(e) => {
                                  selectCategory(e);
                                }}
                                value={categorySlugurl}
                                name={categoryShow.name}
                                label="Super Category"
                                style={{ height: '38px' }}
                                inputProps={{ style: { textAlign: 'center' } }}
                              >
                                {categoryShow.map((test, index) =>
                                  <MenuItem
                                    key={test.slugUrl}
                                    value={test.slugUrl}
                                    name={test.name}
                                    selected={categorySlugurl === test.slugUrl}
                                  >{test.name}</MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        </Col>
                      )
                    }
                    <Col>
                      <Form.Group as={Col}>
                        <Form.Label>Choose Position</Form.Label>
                        <Form.Check
                          type="switch"
                          id="custom-switch1"
                          label="Position 1"
                          checked={selectedPosition === "position1"}
                          onChange={() => handlePositionChange("position1")}
                        />
                        <Form.Check
                          type="switch"
                          id="custom-switch2"
                          label="Position 2"
                          checked={selectedPosition === "position2"}
                          onChange={() => handlePositionChange("position2")}
                        />
                        <Form.Check
                          type="switch"
                          id="custom-switch3"
                          label="Position 3"
                          checked={selectedPosition === "position3"}
                          onChange={() => handlePositionChange("position3")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>


                  <Row className="g-2  m-2 w-50">
                    <Col md>
                      <Form.Label>Desktop Image</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={categoryImageChange}
                      // className={imageError !== "" ? "error_class" : ""}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 500px * 500px
                      </p>
                      <div>
                        {isDeskImageLoading ? (
                          <div></div>
                        ) : (
                          <img src={desktopimage} height={100} alt="img" />
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="card-footer ">
                  {name !== "" && text !== "" && selectedPosition !== "" ? (
                    <button className="btn btn-primary" type="submit" style={{ background: "#000066", borderColor: "#000066" }}>
                      Add Three Slider
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled type="submit" style={{ background: "#000066", borderColor: "#000066" }}>
                      Add Three Slider
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default ThreeSliderAdd;
