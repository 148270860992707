import React, { useEffect } from "react";
import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { read, utils } from "xlsx";
import {
  productPost,
  validateProductSlugUrl,
} from "../../../redux/product/productSlice";

const ElectronicsBulkUploade = () => {
  const dispatch = useDispatch();

  const { superCatShow } = useSelector((store) => store.superCategory);
  const { categoryShow } = useSelector((store) => store.category);
  const { subcategoryShow } = useSelector((store) => store.subCategories);
  const { brandShow } = useSelector((store) => store.brand);
  const { categoryTagShow, categoryTagTotal } = useSelector(
    (store) => store.categorytag
  );

  const [data, setData] = useState("");
  const [uploadedProductName, setUploadedProductName] = useState("");

  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [catbysuper, setCatbysuper] = useState([]);
  const [supercategory, setSupercategory] = useState("");
  const [supercategoryid, setSupercategoryid] = useState("");
  const [subCat, setSubcat] = useState([]);
  const [brand, setBrand] = useState("");
  const [categoryTagsShow, setCategoryTagsShow] = useState([]);
  const [categoryTag, setCategoryTag] = useState("");
  const [categoryTagid, setCategoryTagid] = useState("");

  const [buttonPress, setbuttonPress] = useState(false);
  const [buttonLoading, setbuttonLoading] = useState(false);
  const [supcategroyError, setsupcategroyError] = useState("");
  const [categroyError, setcategroyError] = useState("");
  const [subcategroyError, setSubcategroyError] = useState("");
  const [brandError, setbrandError] = useState("");
  const [slugUrlError, setSlugUrlError] = useState("");
  const [categoryTagError, setcategoryTagError] = useState("");

  const selectsupCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupercategoryid(value);
    setSupercategory(text);
  };
  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };

  useEffect(() => {
    const catbysupercat = categoryShow.filter(
      (cat) => cat.superCategoryId === supercategoryid
    );
    setCatbysuper(catbysupercat);
  }, [supercategoryid, categoryShow]);

  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSubcategoryid(value);
    setSubcategory(text);
  };
  useEffect(() => {
    const subcatbycat = subcategoryShow.filter(
      (subcat) => subcat.categoryId === categoryid
    );
    setSubcat(subcatbycat);
  }, [categoryid, subcategoryShow]);

  const selectCategoryTag = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryTagid(value);
    setCategoryTag(text);
  };

  useEffect(() => {
    const catTagbySubcat = categoryTagShow.filter(
      (subcat) => subcat.SubCategoryId === subcategoryid
    );
    setCategoryTagsShow(catTagbySubcat);
  }, [subcategoryid, categoryTagShow]);
  useEffect(() => {
    if (buttonPress === true) {
      if (supercategoryid === "") {
        setsupcategroyError("Required");
      } else {
        setsupcategroyError("");
      }
      if (categoryid === "") {
        setcategroyError("Required");
      } else {
        setcategroyError("");
      }
      if (subcategoryid === "") {
        setSubcategroyError("Required");
      } else {
        setSubcategroyError("");
      }
      if (categoryTagid === "") {
        setcategoryTagError("Required");
      } else {
        setcategoryTagError("");
      }
    }
  }, [buttonPress, subcategoryid, categoryid, supercategoryid, categoryTagid]);

  const handlefile = async (e) => {
    const file = e.target.files[0];
    const f = await file.arrayBuffer();
    const wb = read(f);
    const worksheetname = wb.SheetNames[0];
    const worksheet = wb.Sheets[worksheetname];
    const datas = utils.sheet_to_json(worksheet);
    setData(datas);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();

    setbuttonPress(true);

    if (supercategoryid === "") {
      setsupcategroyError("Required");
    } else {
      setsupcategroyError("");
    }
    if (categoryid === "") {
      setcategroyError("Required");
    } else {
      setcategroyError("");
    }
    if (subcategoryid === "") {
      setSubcategroyError("Required");
    } else {
      setSubcategroyError("");
    }
    if (categoryTagid === "") {
      setcategoryTagError("Required");
    } else {
      setcategoryTagError("");
    }

    let PrimeryTag = "";
    let PrimeryTagId = "";
    // if (subcategoryid !== "") {
    //   const findSubcat = subcategoryShow.find(
    //     (data) => data._id === subcategoryid
    //   );

    //   let createprimetag = findSubcat.category
    //     .trim()
    //     .toLowerCase()
    //     .replace(" ", "-")
    //     .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
    //     .replace("--", "-")
    //     .replace("---", "-")
    //     .replace("----", "-")
    //     .replace("/", "-")
    //     .replace("//", "-")
    //     .replace("///", "-");
    //   createprimetag = `${createprimetag}-${findSubcat.slugUrl}`;
    //   createprimetag = createprimetag
    //     .trim()
    //     .toLowerCase()
    //     .replace(" ", "-")
    //     .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
    //     .replace("--", "-")
    //     .replace("---", "-")
    //     .replace("----", "-")
    //     .replace("/", "-")
    //     .replace("//", "-")
    //     .replace("///", "-");
    //   PrimeryTag = createprimetag;
    //   if (createprimetag !== "") {
    //     const findPrimeId = categoryTagTotal.find(
    //       (data) => data.slugUrl === createprimetag
    //     );
    //     PrimeryTagId = findPrimeId._id;
    //   }
    // }

    let numbers = 0;
    if (
      supercategoryid !== "" &&
      categoryid !== "" &&
      subcategoryid !== "" &&
      categoryTagid !== ""
      //  &&
      // PrimeryTagId !== ""
    ) {
      for (let i in data) {
        let myrow = data[i];

        const thumnail = `https://mystoreewshooping.mygoldenhomes.com/admin/Products/Desktop/${myrow.ImgUrlDesk}`;
        const thumnailtest = `https://mystoreewshooping.mygoldenhomes.com/admin/Products/Desktop/${myrow.ImgUrlDesk}`;

        const formData = {
          name: myrow.Name,
          slugUrl: myrow.Url,
          superCategoryId: supercategoryid,
          superCategory: supercategory,
          category: category,
          categoryId: categoryid,
          SubCategory: subcategory,
          SubCategoryId: subcategoryid,
          CategoryTag: categoryTag,
          CategoryTagId: categoryTagid,
          PrimeryTag: categoryTag,
          PrimeryTagId: categoryTagid,
          // PrimeryTag: PrimeryTag,
          // PrimeryTagId: PrimeryTagId,
          brand: myrow.Brand,
          CountryofOrigin: "India",
          metaTitle: myrow.Description,
          metaKeyword: myrow.Description,
          metaDesc: myrow.Description,
          multi: false,
          multiVender: true,
          shopName: "EWSHOPPING",
          shopNumber: "8447282606",
          shopEmail: "info@ewshopping.com",
          shopId: "EWS100001",
          productDetails: "",
          NumberofItems: "1",
          packageContainer: "",
          designDetails: [
            {
              designImage: "",
              designHeader: "",
              designContent: "",
            },
          ],
          SizeFit: [],
          MaterialCare: [],

          packSize: [
            {
              packSizeName: "Red",
              PsPrime: true,
              enabled: false,
              OutOfStack: true,
              thumbnail: thumnail,
              slider: [thumnail, thumnail],
              PackSizeOptions: [
                {
                  packSizeOptionName: "xl",
                  // packSizeOptionName: myrow.Model,
                  PsnPrime: true,
                  enabled: false,
                  Mrp: myrow.Mrp,
                  Price: myrow.SellingPrice,
                  OutOfStack: false,
                  Discount: myrow.Discount,
                },
              ],
            },
          ],
        };
        // console.log(formData, "formData");
        try {
          const verify_url = await dispatch(
            validateProductSlugUrl(formData.slugUrl)
          );
          if (verify_url.payload.success === false) {
            const product_uploade = await dispatch(productPost(formData));
            if (product_uploade.payload.success) {
              // numbers = numbers + 1;
              // console.log(numbers, "numbers");
              setUploadedProductName(`  ${myrow.Name}  upload successfull`);
            }
          }
        } catch (error) {}
      }
    }
  };

  return (
    <div className="content-wrapper">
      <div className="employeesContainer">
        <form onSubmit={handlesubmit}>
          <div className="card m-4">
            <div className="card-footer">
              <h5> Product Bulk Upload</h5>
            </div>
            <div className="card-body">
              <h6 className="ps-3"> Product Excel File *</h6>
              <Row className="g-2  m-2">
                <Col md>
                  <Form.Label htmlFor="disabledTextInput"></Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="enter name....."
                    onChange={(e) => handlefile(e)}
                  />
                </Col>
                <Col md>
                  <button
                    className="btn btn-primary me-2 mt-4 ml-5 "
                    type="submit"
                  >
                    Product Bulk Upload
                  </button>
                </Col>
              </Row>
              {data !== "" && (
                <Row className="g-2  m-2">
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Super Category<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Floating label select example"
                      onChange={(e) => {
                        selectsupCategory(e);
                      }}
                      value={superCatShow._id}
                      name={superCatShow.name}
                      className={supcategroyError !== "" ? "error_class" : ""}
                    >
                      <option hidden selected>
                        {"Select Super Category"}
                      </option>
                      {superCatShow.map((data) => (
                        <option
                          key={data._id}
                          value={data._id}
                          name={data.name}
                          selected={supercategoryid === data._id}
                        >
                          {data.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Category<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Floating label select example"
                      onChange={(e) => {
                        selectCategory(e);
                      }}
                      value={catbysuper._id}
                      name={catbysuper.name}
                      className={categroyError !== "" ? "error_class" : ""}
                    >
                      <option hidden selected>
                        {"Select Category"}
                      </option>
                      {catbysuper.map((data) => (
                        <option
                          key={data._id}
                          value={data._id}
                          name={data.name}
                          selected={categoryid === data._id}
                        >
                          {data.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>

                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Sub Category<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Floating label select example"
                      onChange={(e) => {
                        selectSubCategory(e);
                      }}
                      value={subCat._id}
                      name={subCat.name}
                      className={subcategroyError !== "" ? "error_class" : ""}
                    >
                      <option hidden selected>
                        {"Select Sub Category"}
                      </option>
                      {subCat.map((data) => (
                        <option
                          key={data._id}
                          value={data._id}
                          name={data.name}
                          selected={subcategoryid === data._id}
                        >
                          {data.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Tag<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Floating label select example"
                      onChange={(e) => {
                        selectCategoryTag(e);
                      }}
                      value={categoryTagsShow._id}
                      name={categoryTagsShow.name}
                      className={categoryTagError !== "" ? "error_class" : ""}
                    >
                      <option hidden selected>
                        {"Select Sub Category"}
                      </option>
                      {categoryTagsShow.map((data) => (
                        <option
                          key={data._id}
                          value={data._id}
                          name={data.name}
                          selected={categoryTagid === data._id}
                        >
                          {data.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              )}

              <p style={{ color: "green" }} className="mt-2">
                {uploadedProductName}
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ElectronicsBulkUploade;
