import React from "react";
import ClothingSupercat from "./Clothing/ClothingSupercat";
import BeautySupercat from "./Beauty/BeautySupercat";
import ElectronicsSupercat from "./Electronics/ElectronicsSupercat";
import GrocerySupercat from "./Grocery/GrocerySupercat";
import MedicienSupercat from "./Medicines/MedicienSupercat";
import ClothingsSuperCategory from "./Clothing/ClothingsSuperCategory";
import { HomeandFurnitureSuperCat } from "./HomeandFurniture/HomeandFurnitureSuperCat";

const MainSupercatProduct = ({
  supercategory,
  category,
  subcategory,
  categoryTag,
  buttonPress,
  getPropDetails,
  subcategoryid,
  categoryid,
  supercategoryid,
  categoryTagid,

  resetield,
}) => {

  // console.log(supercategory, "supercategory");
  // console.log(category, "category");
  // console.log(subcategory, "subcategory");
  // console.log(categoryTag, "categoryTag");
  // console.log(getPropDetails, "getPropDetails");
  // console.log(subcategoryid, "subcategoryid");
  // console.log(categoryid, "categoryid");
  // console.log(supercategoryid, "supercategoryid");
  // console.log(categoryTagid, "categoryTagid");

  const renderSwitch = (param) => {
    switch (param) {
      case "Clothing": {
        return (
          <>
            {/* <ClothingSupercat
              category={category}
              subcategory={subcategory}
              categoryTag={categoryTag}
              buttonPress={buttonPress}
              getPropDetails={getPropDetails}
            /> */}
            <ClothingsSuperCategory
              supercategory={supercategory}
              category={category}
              subcategory={subcategory}
              categoryTag={categoryTag}
              subcategoryid={subcategoryid}
              categoryid={categoryid}
              supercategoryid={supercategoryid}
              categoryTagid={categoryTagid}
              buttonPress={buttonPress}
              getPropDetails={getPropDetails}
              resetield={resetield}
            />
          </>
        );
      }
      case "Beauty": {
        return (
          <>
            <BeautySupercat
              supercategory={supercategory}
              category={category}
              subcategory={subcategory}
              categoryTag={categoryTag}
              subcategoryid={subcategoryid}
              categoryid={categoryid}
              supercategoryid={supercategoryid}
              categoryTagid={categoryTagid}
              buttonPress={buttonPress}
              getPropDetails={getPropDetails}
              resetield={resetield}
            />
          </>
        );
      }
      case "Electronics": {
        return (
          <>
            <ElectronicsSupercat
              supercategory={supercategory}
              category={category}
              subcategory={subcategory}
              categoryTag={categoryTag}
              subcategoryid={subcategoryid}
              categoryid={categoryid}
              supercategoryid={supercategoryid}
              categoryTagid={categoryTagid}
              buttonPress={buttonPress}
              getPropDetails={getPropDetails}
              resetield={resetield}
            />
          </>
        );
      }
      case "Grocery": {
        return (
          <>
            <GrocerySupercat
              category={category}
              subcategory={subcategory}
              categoryTag={categoryTag}
              buttonPress={buttonPress}
              getPropDetails={getPropDetails}
              resetield={resetield}
            />
          </>
        );
      }
      case "Medicines": {
        return (
          <>
            <MedicienSupercat
              category={category}
              subcategory={subcategory}
              categoryTag={categoryTag}
              buttonPress={buttonPress}
              getPropDetails={getPropDetails}
              resetield={resetield}
            />
          </>
        );
      }
      case "Home and furniture": {
        return (
          <>
            <HomeandFurnitureSuperCat
              supercategory={supercategory}
              category={category}
              subcategory={subcategory}
              categoryTag={categoryTag}
              subcategoryid={subcategoryid}
              categoryid={categoryid}
              supercategoryid={supercategoryid}
              categoryTagid={categoryTagid}
              buttonPress={buttonPress}
              getPropDetails={getPropDetails}
              resetield={resetield}
            />
          </>
        );
      }

      default:
        return <>{/* <ClothingSupercat /> */}</>;
    }
  };
  return <>{renderSwitch(supercategory)}</>;
};

export default MainSupercatProduct;
