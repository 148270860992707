import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  homeSliderPost,
  homesliderImages,
  resetHomeSlider,
  validateSlugUrl,
} from "../../../redux/HomeSlider/HomeSliderSlice";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const MainHomeSliderAdd = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const {
    mobileimage,
    desktopimage,
    ismobileImageLoading,
    isDeskImageLoading,
  } = useSelector((store) => store.homeSlider);
  const { superCatShow } = useSelector((store) => store.superCategory);
  const { categoryShow } = useSelector((store) => store.category);
  const { subcategoryShow } = useSelector((store) => store.subCategories);
  const { brandShow } = useSelector((store) => store.brand);
  const { categoryTagShow, categoryTagTotal } = useSelector(
    (store) => store.categorytag
  );
  const { producttotal, productLoading, isLoading } = useSelector(
    (store) => store.product
  );
  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [catbysuper, setCatbysuper] = useState([]);
  const [supercategory, setSupercategory] = useState("");
  const [supercategoryid, setSupercategoryid] = useState("");
  const [subCat, setSubcat] = useState([]);
  const [brand, setBrand] = useState("");
  const [categoryTagsShow, setCategoryTagsShow] = useState([]);
  const [categoryTag, setCategoryTag] = useState("");
  const [categoryTagid, setCategoryTagid] = useState("");

  const [propDerails, setpropDerails] = useState("");
  const [propDerailsStatus, setpropDerailsStatus] = useState(false);
  const [resetield, setresetield] = useState(false);

  const [supcategroyError, setsupcategroyError] = useState("");
  const [categroyError, setcategroyError] = useState("");
  const [subcategroyError, setSubcategroyError] = useState("");
  const [brandError, setbrandError] = useState("");
  const [slugUrlError, setSlugUrlError] = useState("");
  const [categoryTagError, setcategoryTagError] = useState("");
  const [name, setName] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const [text, setText] = useState("");
  const [categorySlugurl, setCategorySlugUrl] = useState("");

  const [tagName, setTagName] = useState("");
  const [headingName, setHeadingName] = useState("");
  const [subheadingName, setSubheadingName] = useState("");
  const [buttonName, setButtonName] = useState("");
  const [position, setPosition] = useState("");
  const positionList = [
    {
      id: 1,
      label: "Right",
    },
    {
      id: 2,
      label: "Left",
    },
  ];

  // Error message
  const [error, setError] = useState("");

  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectProduct, setSelectProduct] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isAuth) {
      navigate("/");
    }
  }, []);

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setName(value);
    setCheckSlugUrl("");
    const sliderSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(sliderSlug);

    if (sliderSlug !== "") {
      let responce = await dispatch(validateSlugUrl(sliderSlug));
      if (responce.payload.success) {
        setError("slider alredy exist");
        setErrorcolor("red");
      } else if (sliderSlug.length <= 2) {
        setErrorcolor("red");
        setError("minimum 3 letters");
        setCheckSlugUrl("");
      } else if (sliderSlug.length >= 3) {
        setErrorcolor("");
        setError("");
        setCheckSlugUrl(sliderSlug);
      }
    }
  };

  const handlePosition = (evt) => {
    const { value } = evt.target;
    setPosition(value);
  };

  const selectsupCategory = (evt) => {
    const { value } = evt.target;
    setSupercategoryid(value);
    const textValue = superCatShow.find((indSup) => indSup._id === value);
    if (textValue) {
      setSupercategory(textValue.name);
    }
    setpropDerails("");
    setpropDerailsStatus(false);
  };

  useEffect(() => {
    const catbysupercat = categoryShow.filter(
      (cat) => cat.superCategoryId === supercategoryid
    );
    setCatbysuper(catbysupercat);
  }, [supercategoryid, categoryShow]);

  const selectCategory = (evt) => {
    const { value } = evt.target;
    setCategoryid(value);
    const textValue = catbysuper.find((indCat) => indCat._id === value);
    if (textValue) {
      setCategory(text);
    }

    setpropDerails("");
    setpropDerailsStatus(false);
  };

  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    setSubcategoryid(value);
    const textValue = subCat.find((indSubCat) => indSubCat._id === value);
    if (textValue) {
      setSubcategory(textValue.name);
    }

    setpropDerails("");
    setpropDerailsStatus(false);
  };
  useEffect(() => {
    const subcatbycat = subcategoryShow.filter(
      (subcat) => subcat.categoryId === categoryid
    );
    setSubcat(subcatbycat);
  }, [categoryid, subcategoryShow]);

  const selectCategoryTag = (evt) => {
    const { value } = evt.target;
    setCategoryTagid(value);
    const textValue = categoryTagsShow.find((indTag) => indTag._id === value);
    console.log(value, "value");
    console.log(textValue, "textValue");
    if (textValue) {
      setCategoryTag(textValue.name);
    }
    setpropDerails("");
    setpropDerailsStatus(false);
    setresetield(false);
  };

  useEffect(() => {
    const catTagbySubcat = categoryTagShow.filter(
      (subcat) => subcat.SubCategoryId === subcategoryid
    );
    setCategoryTagsShow(catTagbySubcat);
  }, [subcategoryid, categoryTagShow]);

  const handleProductPress = (value) => {
    if (selectProduct.includes(value)) {
      setSelectProduct(
        selectProduct.filter((e) => {
          return e !== value;
        })
      );
    } else {
      setSelectProduct((selectProduct) => [...selectProduct, value]);
    }
  };
  const categoryImageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(homesliderImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    if (name !== "" && !ismobileImageLoading && !isDeskImageLoading) {
      const formData = {
        name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
        slugUrl: checkslugUrl,
        mobileImage: mobileimage,
        desktopImage: desktopimage,
      };
      const data = await dispatch(homeSliderPost(formData));
      if (!data) {
        alert("please check the flields");
      } else {
        if (data.payload.success) {
          alert("slider Uploaded");
          dispatch(resetHomeSlider());
          setName("");
          setTagName("");
          setHeadingName("");
          setSubheadingName("");
          setButtonName("");
          setSelectProduct([]);
          setPosition("");
          setPosition([]);
          setSubcategoryid("");
          setCategoryid("");
          setSupercategoryid("");
          setCategoryTagid("");
          setCategoryTag("");
          setCategoryTagsShow("");
        } else {
          alert(data.message);
        }
      }
    } else {
      alert("please enter correct data");
    }
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Add Home Slider</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Add Home Slider</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <div className="space-y-1">
                        <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: "500", color: "rgb(0, 0, 102)" }}
                        >
                          Name
                        </label>
                        <div className="textfieldcontainer">
                          <TextField
                            label="Enter Name"
                            variant="outlined"
                            style={{ width: "100%" }}
                            type="text"
                            id="text"
                            value={name}
                            onChange={(e) => verifyslugurl(e)}
                            // className={classes.textfield}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label>Desktop Image</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={categoryImageChange}
                        // className={imageError !== "" ? "error_class" : ""}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 500px * 500px
                      </p>
                      <div>
                        {isDeskImageLoading ? (
                          <div></div>
                        ) : (
                          <img src={desktopimage} height={100} alt="img" />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <br />

                  <div className="row">
                    {producttotal
                      .filter(
                        (individualProduct) =>
                          individualProduct.CategoryTagId === categoryTagid
                      )
                      .map((filteredProduct) => (
                        <div
                          key={filteredProduct._id}
                          className="col-lg-4 col-md-6 col-6"
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <input
                              type="checkbox"
                              className="me-1"
                              value={filteredProduct._id}
                              checked={selectProduct.includes(
                                filteredProduct._id
                              )}
                              onChange={(e) =>
                                handleProductPress(filteredProduct._id)
                              }
                            />
                            <p>{filteredProduct.name}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <div className="card-footer ">
                  {name !== "" ? (
                    <button
                      className="btn btn-primary"
                      type="submit"
                      style={{ background: "#000066", borderColor: "#000066" }}
                    >
                      Add Slider
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      disabled
                      type="submit"
                      style={{ background: "#000066", borderColor: "#000066" }}
                    >
                      Add Slider
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default MainHomeSliderAdd;
