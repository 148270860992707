import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./Athentication/AuthSlice";
import superCategoryReducer from "./superCategory/superCategorySlice";
import categoryReducer from "./category/categorySlice";
import subCategoriesReducer from "./subCategory/subCategorySlice";
import brandReducer from "./brand/brandSlice";
import productReducer from "./product/productSlice";
import categorytagReducer from "./categoryTags/CategoryTagsSlice";
import MessurentsReducer from "./messuurementsandDropdowns/Messuremenrsslice";
import fieldReducer from "./field/FieldSlice";
import fieldStatusReducer from "./fieldStatus/FieldStatusSlice";
import homeSliderReducer from "./HomeSlider/HomeSliderSlice";
import threeSliderReducer from "./ThreeSlider/ThreesliderSlice";
import shopReducer from "./shop/ShopSlice";
export const store = configureStore({
  reducer: {
    superCategory: superCategoryReducer,
    category: categoryReducer,
    subCategories: subCategoriesReducer,
    brand: brandReducer,
    categorytag: categorytagReducer,
    product: productReducer,
    Messurents: MessurentsReducer,
    field: fieldReducer,
    fieldStatus: fieldStatusReducer,
    homeSlider: homeSliderReducer,
    threeSlider: threeSliderReducer,
    shop: shopReducer,
    
    auth: authSlice.reducer,
  },
});