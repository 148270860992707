import "./App.css";
import { Route, Routes, HashRouter } from "react-router-dom";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import Login from "./pages/Login";
import MainLayout from "./components/layout/MainLayout";
import { getSuperCategory } from "./redux/superCategory/superCategorySlice";
import ListSuperCategory from "./pages/addEdits/superCategory/ListSuperCategory";
import AddSuperCategory from "./pages/addEdits/superCategory/AddSuperCategory";
import { getCategory } from "./redux/category/categorySlice";
import AddCategory from "./pages/addEdits/CategoryTags/category/AddCategory";
import ListCategory from "./pages/addEdits/CategoryTags/category/ListCategory";
import AddSubCategory from "./pages/addEdits/subcategory/AddSubCategory";
import { getSubCategory } from "./redux/subCategory/subCategorySlice";
import ListSubCategory from "./pages/addEdits/subcategory/ListSubCategory";
import AddBrand from "./pages/addEdits/brand/AddBrand";
import ListBrand from "./pages/addEdits/brand/ListBrand";
import AddProduct from "./pages/addEdits/product/AddProduct";
import { getBrand } from "./redux/brand/brandSlice";
import PdfTestPage from "./pages/addEdits/product/PdfTestPage";
import { getCategorytag } from "./redux/categoryTags/CategoryTagsSlice";
import AddCategoryTags from "./pages/addEdits/CategoryTags/AddCategoryTags";
import ListCategoryTags from "./pages/addEdits/CategoryTags/ListCategoryTags";
import FieldAdd from "./pages/addEdits/field/FieldAdd";
import FieldList from "./pages/addEdits/field/FieldList";
import AddFieldsStatus from "./pages/addEdits/fieldsStatus/AddFieldsStatus";
import { getField } from "./redux/field/FieldSlice";
import ClothingBulkUploade from "./pages/addEdits/product/ClothingBulkUploade";
import UpdateCategory from "./pages/addEdits/CategoryTags/category/UpdateCategory";
import UpdateSubcategory from "./pages/addEdits/subcategory/UpdateSubcategory";
import ElectronicsBulkUploade from "./pages/addEdits/product/ElectronicsBulkUploade";
import MainHomeSliderAdd from "./pages/addEdits/mainHomeSlider/MainHomeSliderAdd";
import ThreeSliderAdd from "./pages/addEdits/ThreeSlider/ThreeSliderAdd";
import { getShop } from "./redux/shop/ShopSlice";
import NotApprovedShops from "./pages/Shop/NotApprovedShops";
import ApprovedShops from "./pages/Shop/ApprovedShops";
import RejectedShops from "./pages/Shop/RejectedShops";
import Dashboard from "./pages/Dashboard";
import { Order } from "./pages/orders/Order";
import PendingOrders from "./pages/orders/PendingOrders";
import ChangeShopPassword from "./pages/Shop/ChangeShopPassword";
import Seller_Products from "./pages/products/Seller_Products";
import Authentication from "./pages/Authentication";
import MainHomeSliderList from "./pages/addEdits/mainHomeSlider/MainHomeSliderList";
import { getSlider } from "./redux/HomeSlider/HomeSliderSlice";
import ThreeSliderList from "./pages/addEdits/ThreeSlider/ThreeSliderList";
import { getThreeSlider } from "./redux/ThreeSlider/ThreesliderSlice";
import { HomeandFurnitureSuperCat } from "./pages/addEdits/product/supercategory/HomeandFurniture/HomeandFurnitureSuperCat";


function App() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getSuperCategory())
    dispatch(getCategory())
    dispatch(getSubCategory())
    dispatch(getBrand())
    dispatch(getCategorytag())
    dispatch(getField())
    dispatch(getShop())
    dispatch(getSlider())
    dispatch(getThreeSlider())
  }, [])
  // src/components/ScrollToTop.js


  const ScrollToTop = ({ children }) => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return children;
  };

  return (
    <div className="wrapper">
      <HashRouter>
        <ScrollToTop>
          <Routes>
            {/* <Route path="/" element={<Login />} /> */}
            <Route path="/" element={<Authentication />} />
            <Route path="/admin" exact element={<MainLayout />}>
              <Route index element={<Dashboard />} />
              {/* supercategory */}
              <Route
                path="/admin/addsupercategory"
                element={<AddSuperCategory />}
              />
              <Route
                path="/admin/listsupercategory"
                element={<ListSuperCategory />}
              />

              {/* category */}
              <Route path="/admin/addcategory" element={<AddCategory />} />
              <Route path="/admin/update-category/:category_url" element={<UpdateCategory />} />
              <Route path="/admin/listcategory" element={<ListCategory />} />

              {/* subcategory */}
              <Route path="/admin/addsubcategory" element={<AddSubCategory />} />
              <Route path="/admin/update-subcategory/:sub_category_url" element={<UpdateSubcategory />} />
              <Route
                path="/admin/listsubcategory"
                element={<ListSubCategory />}
              />

              {/* brand */}
              <Route path="/admin/addbrand" element={<AddBrand />} />
              <Route path="/admin/listbrand" element={<ListBrand />} />

              {/* product */}
              <Route path="/admin/add-product" element={<AddProduct />} />
              <Route path="/admin/clothing-bulk-product" element={<ClothingBulkUploade />} />
              <Route path="/admin/electronic-bulk-product" element={<ElectronicsBulkUploade />} />

              {/* category tags */}
              <Route path="/admin/add-category-tags" element={<AddCategoryTags />} />
              <Route path="/admin/list-category-tags" element={<ListCategoryTags />} />

              {/* Field Add */}
              <Route path="/admin/add-fields" element={<FieldAdd />} />
              <Route path="/admin/list-fields" element={<FieldList />} />

              {/* Field Add */}
              <Route path="/admin/add-fields-status" element={<AddFieldsStatus />} />

              {/* Home Slider Add */}
              <Route path="/admin/add-home-slider" element={<MainHomeSliderAdd />} />
              <Route path="/admin/list-home-slider" element={<MainHomeSliderList />} />
              {/* Three Slider Add */}
              <Route path="/admin/add-three-slider" element={<ThreeSliderAdd />} />
              <Route path="/admin/list-three-slider" element={<ThreeSliderList />} />
              {/* Orders */}
              <Route path="/admin/seller-orders/:shopId" element={<Order />} />
              {/* Shops*/}
              <Route path="/admin/not-approved-shop" element={<NotApprovedShops />} />
              <Route path="/admin/approved-shop" element={<ApprovedShops />} />
              <Route path="/admin/rejected-shop" element={<RejectedShops />} />

              <Route path="/admin/change-seller-password" element={<ChangeShopPassword />} />

              {/* Orders*/}
              <Route path="/admin/pending-orders" element={<PendingOrders />} />

              {/* produtcs*/}
              <Route path="/admin/seller-products" element={<Seller_Products />} />
            </Route>
            {/* <Route path="*" element={<Error />} /> */}
          </Routes>
        </ScrollToTop>
      </HashRouter>
    </div>
  );
}

export default App;