import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  producttotal: localStorage.getItem("producttotal")
    ? JSON.parse(localStorage.getItem("producttotal"))
    : [],

  mobileimage: "",
  desktopimage: "",
  isproductmobileimageLoading: true,
  isproductdeskimageLoading: true,
  isLoading: true,
  productLoading: true,
  delproductLoading: true,
};

export const getlast10daysProducts = createAsyncThunk(
  "Product/getlast10daysProducts",
  async (sellerid, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/product/last10days-productBy-ShopId/${sellerid}`;
      const resp = await axios(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);
export const getProduct = createAsyncThunk(
  "Product/getProduct",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/product/all`;
      const resp = await axios(url);
      return resp.data.product;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);
export const productPost = createAsyncThunk(
  "product/productPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/product/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("product Not create");
    }
  }
);

export const productUpdate = createAsyncThunk(
  "product/productUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/product/${formData.productid}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("product Not create");
    }
  }
);
export const productDelete = createAsyncThunk(
  "product/productDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/product/${id}`;
      const resp = await axios.delete(url, id, config);
      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("product Not create");
    }
  }
);

export const productImages = createAsyncThunk(
  "product/productImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/product/productimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("product Not create");
    }
  }
);

export const validateProductSlugUrl = createAsyncThunk(
  "product/validateProductSlugUrl",
  async (slugurl, thunkAPI) => {
    let resp = {
      success: false,
      message: "new slugurl",
    }
    try {
      const url = `${Baseurl}/api/v1/product/verify-slugurl/${slugurl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const packsizeImages = createAsyncThunk(
  "product/packsizeImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/product/packsize-images`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Not create");
    }
  }
);


const ProductSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(productPost.pending, (state) => {
        state.productLoading = true;
      })
      .addCase(productPost.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.producttotal = [...state.producttotal, action.payload.product];
          localStorage.setItem("producttotal", JSON.stringify(state.producttotal));
        }
        state.productLoading = false;
      })
      .addCase(productPost.rejected, (state) => {
        state.productLoading = true;
      });
  },
});
export const {
} = ProductSlice.actions;
export default ProductSlice.reducer;