import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { AiFillLock } from "react-icons/ai";
import axios from "axios";
import { Baseurl } from "../config/BaseUrl";
import {
  authActions,
  send_sms_through_backend,
  validatePhoneNumber,
} from "../redux/Athentication/AuthSlice";
import { useSelector, useDispatch } from "react-redux";

const OtpVerify = () => {
  const { isAuth } = useSelector((store) => store.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRefs = useRef([]);

  const { otp, mob } = useSelector((state) => state.auth);
  const [otpValues, setOtpValues] = useState("");
  const [otpError, setOtpError] = useState("");
  const [mobileError, setMobileOtpError] = useState("");
  const [buttonPress, setbuttonPress] = useState(false);

  const OtpChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    setOtpValues(value);
  };



  const verifyOtp = async (e) => {
    e.preventDefault();
    setbuttonPress(true);

    if (otpValues.length === 6) {
      if (otpValues === otp) {
        const validateNumber = await dispatch(validatePhoneNumber(mob));
        if (validateNumber.payload.success === true) {
          const userData = validateNumber.payload.admin;
       
          const authenticForm = {
            ...userData,
            isAuth: true,
          };
       
          await dispatch(authActions.signin(authenticForm));
          navigate("/admin");
        } else {
          setMobileOtpError("Unauthorized access: Admin rights required.");
        }
      } else {
        setMobileOtpError("Unauthorized access: Admin rights required.");
        setOtpError("Please enter correct otp");
      }
    }
  };

  return (
    <>
      <div className="hold-transition register-page">
        <div className="register-box col-sm-5">
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <Link to="." className="h1">
                <b>EWShopping Super Admin</b>
              </Link>
            </div>
            <div className="card-body">
              <p className="login-box-msg d-flex justify-content-center">
                Sign in to start your session
              </p>
              <form onSubmit={verifyOtp}>
                <label htmlFor="CustomerEmail" className="d-block">
                  Enter Otp <span className="required">*</span>
                </label>

                <div className="input-group mb-3">
                  <input
                    type="text"
                    className={
                      otpError !== ""
                        ? "form-control error_class"
                        : "form-control"
                    }
                    placeholder="Enter otp"
                    minLength={6}
                    maxLength={6}
                    value={otpValues}
                    onChange={(e) => OtpChange(e)}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <AiFillLock />
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <p style={{ color: "red", fontWeight: 700 }}>{mobileError}</p>
                </div>

                <div className="row">
                  <div className="col-2">
                    <button type="submit" className="btn btn-primary ">
                      Login
                    </button>
                  </div>
                  <div className="mt-2">
                    <p style={{ color: "red", fontWeight: 700 }}>
                      {otpError}
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpVerify;
