
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom'
import Footer from '../Footer'
import Header from '../Header';
import Menu from '../Menu';

const MainLayout = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };


  useEffect(() => {
    if (isAuth === false) {
      navigate("/");
    }
  }, [isAuth]);

  return (
    <>
      <Header toggleCollapsed={toggleCollapsed} />
      <Menu collapsed={collapsed} />
      <Outlet />
      <Footer />
    </>
  )
}

export default MainLayout;