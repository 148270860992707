import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { AiFillLock } from "react-icons/ai";
import axios from "axios";
import { Baseurl } from "../config/BaseUrl";
import { authActions } from "../redux/Athentication/AuthSlice";
import { useSelector, useDispatch } from "react-redux";
const Login = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordSHow, setPasswordShow] = useState("password");
  const [errormsg, setErrormsg] = useState("");
  const [errormsgClr, setErrormsgclr] = useState("red");
  const [ButtonPress, setButtonPress] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuth === true) {
      navigate("/admin");
    }
  }, [isAuth]);

  useEffect(() => {
    if (ButtonPress === true) {
      if (email === "") {
        setEmailError("Required");
      } else {
        setEmailError("");
      }
      if (password === "") {
        setPasswordError("Required");
      } else {
        setPasswordError("");
      }
    }
  }, [ButtonPress, email, password]);

  const loginSubmit = async (e) => {
    e.preventDefault();
    if (email === "") {
      setEmailError("Required");
    } else {
      setEmailError("");
    }
    if (password === "") {
      setPasswordError("Required");
    } else {
      setPasswordError("");
    }

    if (email !== "" && password !== "") {
      setErrormsgclr("green");
      setErrormsg("Validating email or password..");
      try {
        const { data } = await axios.post(`${Baseurl}/api/v1/admin/login`, {
          email: email,
          password: password,
          // role: "admin",
        });

        if (data.success) {
          setErrormsgclr("green");
          setErrormsg("Validated your account");
          dispatch(
            authActions.signin({
              token: data.token,
              isAuth: true,
              name: data.name,
              email: data.email,
              admin: data,
            })
          );
          navigate("/admin");
          // window.location.reload(false);
        } else {
          setErrormsgclr("red");
          setErrormsg("Invalid email or password");
          setEmailError("Required");
          setPasswordError("Required");
        }
      } catch (error) {
        setErrormsgclr("red");
        setErrormsg("Invalid email or password");
        setEmailError("Required");
        setPasswordError("Required");
      }
    } else {
      setButtonPress(true);
    }
  };

  return (
    <>
      <div className="hold-transition register-page">
        <div className="register-box col-sm-5">
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <Link to="." className="h1">
                <b>EWShopping Super Admin</b>
              </Link>
            </div>
            <div className="card-body">
              <p className="login-box-msg d-flex justify-content-center">
                Sign in to start your session
              </p>
              <form onSubmit={loginSubmit}>
                <div className="input-group mb-3">
                  <input
                    type="email"
                    className={
                      emailError !== ""
                        ? "form-control error_class"
                        : "form-control"
                    }
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <BsFillEnvelopeFill />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className={
                      passwordError !== ""
                        ? "form-control error_class"
                        : "form-control"
                    }
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <AiFillLock />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <button type="submit" className="btn btn-primary ">
                      Login
                    </button>
                  </div>
                  <div className="mt-2">
                    <p style={{ color: errormsgClr, fontWeight: 700 }}>
                      {errormsg}
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
