import React, { useState } from "react";
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { Button, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineLibraryAdd } from "react-icons/md";

import { FaHome } from "react-icons/fa";
import { BiCategoryAlt } from "react-icons/bi";
import { RiUserSettingsFill } from "react-icons/ri";
import { TbBrandReactNative } from "react-icons/tb";
import { MdDoNotDisturbOff } from "react-icons/md";
import logo from "../img/EwsfLogo (1).png";
import { GiKnightBanner } from "react-icons/gi";
import { MdTextFields } from "react-icons/md";
import { FaShoppingBag } from "react-icons/fa";
import { AiTwotoneWarning } from "react-icons/ai";
import { MdDoNotDisturbOn } from "react-icons/md";
import { VscSymbolField } from "react-icons/vsc";
import { GrStatusUnknownSmall } from "react-icons/gr";
import { FaReact } from "react-icons/fa";
import { FaUserCog } from "react-icons/fa";
import { MdAddHome } from "react-icons/md";
import { PiCirclesThreeFill } from "react-icons/pi";
import { RiFolderAddFill } from "react-icons/ri";
import { RiFileListFill } from "react-icons/ri";
import { PiListFill } from "react-icons/pi";
import { MdLibraryAdd } from "react-icons/md";
import { TbBrandBootstrap } from "react-icons/tb";
import { RiProductHuntFill } from "react-icons/ri";
import { ImPriceTags } from "react-icons/im";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { BsCheckCircleFill } from "react-icons/bs";

const Menu_Item = ({ collapsed }) => {
  const navigate = useNavigate();
  const [stateOpenKeys, setStateOpenKeys] = useState(["2", "23"]);

  const items = [
    {
      key: "1",
      icon: <AppstoreOutlined />,
      label: "Dashboard",
      routes: "/admin",
    },

    {
      key: "sub2",
      label: "Add-Edit",
      icon: <FaUserCog />,
      children: [
        // {
        //   key: "sub3",
        //   label: "Super Category",
        //   icon: <BiSolidCategoryAlt />,
        //   children: [
        //     {
        //       key: "11",
        //       label: "Add Super Category",
        //       icon: <MdLibraryAdd />,
        //       routes: "/admin/addsupercategory",
        //     },
        //     {
        //       key: "12",
        //       label: "List Super Category",
        //       icon: <PiListFill />,
        //       routes: "/admin/listsupercategory",
        //     },
        //   ],
        // },
        {
          key: "sub4",
          label: "Category",
          icon: <BiSolidCategoryAlt />,
          children: [
            {
              key: "13",
              label: "Add category",
              icon: <MdLibraryAdd />,
              routes: "/admin/addcategory",
            },
            {
              key: "14",
              label: "List category",
              icon: <PiListFill />,
              routes: "/admin/listcategory",
            },
          ],
        },
        {
          key: "sub5",
          label: " Sub Category",
          icon: <BiSolidCategoryAlt />,
          children: [
            {
              key: "15",
              label: "Add Sub-category",
              icon: <MdLibraryAdd />,
              routes: "/admin/addsubcategory",
            },
            {
              key: "16",
              label: "List Sub-category",
              icon: <PiListFill />,
              routes: "/admin/listsubcategory",
            },
          ],
        },
        {
          key: "sub6",
          label: "Brand",
          icon: <TbBrandBootstrap />,
          children: [
            {
              key: "17",
              label: "Add Brand",
              icon: <MdLibraryAdd />,
              routes: "/admin/addbrand",
            },
            {
              key: "18",
              label: "List Brand",
              icon: <PiListFill />,
              routes: "/admin/listbrand",
            },
          ],
        },
        {
          key: "sub7",
          label: "Product",
          icon: <RiProductHuntFill />,
          children: [
            {
              key: "19",
              label: "Add Product",
              icon: <MdLibraryAdd />,
              routes: "/admin/add-product",
            },
            // {
            //   key: "20",
            //   label: "Clothing Bulk Product",
            //   icon: <PiListFill />,
            //   routes: "/admin/clothing-bulk-product",
            // },
            // {
            //   key: "21",
            //   label: "Electronic Bulk Product",
            //   icon: <PiListFill />,
            //   routes: "/admin/electronic-bulk-product",
            // },
          ],
        },
        {
          key: "sub8",
          label: "Category Tags",
          icon: <ImPriceTags />,
          children: [
            {
              key: "22",
              label: "Add  Category Tags",
              icon: <MdLibraryAdd />,
              routes: "/admin/add-category-tags",
            },
            {
              key: "23",
              label: "List Category Tags",
              icon: <PiListFill />,
              routes: "/admin/list-category-tags",
            },
          ],
        },
      ],
    },
    {
      key: "sub1",
      label: "Banners",
      icon: <GiKnightBanner />,
      children: [
        {
          key: "sub11",
          label: "Home Slider",
          icon: <MdAddHome />,
          children: [
            {
              key: "28",
              label: "Add Home Slider",
              icon: <MdLibraryAdd />,
              routes: "/admin/add-home-slider",
            },
            {
              key: "29",
              label: "List Home Slider",
              icon: <MdLibraryAdd />,
              routes: "/admin/list-home-slider",
            },
          ],
        },
        {
          key: "sub12",
          label: " Three Slider",
          icon: <PiCirclesThreeFill />,
          children: [
            {
              key: "30",
              label: "Add Three Slider",
              icon: <MdLibraryAdd />,
              routes: "/admin/add-three-slider",
            },
            {
              key: "31",
              label: "List Three Slider",
              icon: <MdLibraryAdd />,
              routes: "/admin/list-three-slider",
            },
          ],
        },
      ],
    },
    {
      key: "sub13",
      label: "Fields",
      icon: <MdTextFields />,
      children: [
        {
          key: "sub9",
          label: "Fields",
          icon: <VscSymbolField />,
          children: [
            {
              key: "24",
              label: "Add  Fields",
              icon: <RiFolderAddFill />,
              routes: "/admin/add-fields",
            },
            {
              key: "25",
              label: "List Fields",
              icon: <RiFileListFill />,
              routes: "/admin/list-fields",
            },
          ],
        },
        {
          key: "sub10",
          label: " Fields Status",
          icon: <GrStatusUnknownSmall />,
          children: [
            {
              key: "26",
              label: "Add  Fields Status",
              icon: <RiFolderAddFill />,
              routes: "/admin/add-fields-status",
            },
            {
              key: "27",
              label: "List Fields",
              icon: <RiFileListFill />,
              routes: "/admin/list-fields",
            },
          ],
        },
      ],
    },
    {
      key: "sub14",
      label: "Shop",
      icon: <FaShoppingBag />,
      children: [
        {
          key: "30",
          label: "Approved Shops",
          routes: "/admin/approved-shop",
          icon: <BsCheckCircleFill />,
        },
        {
          key: "sub15",
          label: "Rejected Shops",
          icon: <AiTwotoneWarning />,
          routes: "/admin/rejected-shop",
        },
        {
          key: "sub16",
          label: "Pending Shops",
          icon: <MdDoNotDisturbOff />,
          routes: "/admin/not-approved-shop",
        },
      ],
    },
    {
      key: "sub17",
      label: "Orders",
      icon: <FaShoppingBag />,
      routes: "/admin/pending-orders",
      // children: [
      //   {
      //     key: "pendorder",
      //     label: "Orders",
      //     routes: "/admin/pending-orders",
      //     icon: <BsCheckCircleFill />,
      //   },
      // ],
    },

    // {
    //   key: "sub18",
    //   label: "Change Vendor Password",
    //   icon: <FaShoppingBag />,
    //   routes: "/admin/change-seller-password",
    // },
    {
      key: "sub19",
      label: "Seller Products",
      icon: <FaShoppingBag />,
      routes: "/admin/seller-products",
    },
  ];

  const getLevelKeys = (items1) => {
    const key = {};
    const func = (items2, level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };
    func(items1);
    return key;
  };
  const levelKeys = getLevelKeys(items);

  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1
    );
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
      setStateOpenKeys(
        openKeys
          // remove repeat key
          .filter((_, index) => index !== repeatIndex)
          // remove current level all child
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      );
    } else {
      // close
      setStateOpenKeys(openKeys);
    }
  };

  const navigationclick = (value) => {
    // console.log(value.item.props.routes, "value");
    const nav_link = value.item.props.routes;
    navigate(`${nav_link}`);
  };

  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <Link to="/admin" className="brand-link">
          <img
            src={logo}
            alt="Admin"
            className="brand-image img-circle elevation-3 "
            style={{ opacity: ".8", height: "50px", width: "35px" }}
          />
          <span className="brand-text font-weight-light">Admin</span>
        </Link>
        {/* Sidebar */}
        <div className="sidebar">
          <nav className="mt-2">
            {/*================================ Dashboard ==========================*/}
            <div
              style={
                {
                  // width: 256,
                }
              }
            >
              {/* <Button
                type="primary"
                onClick={toggleCollapsed}
                style={{
                  marginBottom: 16,
                }}
              >
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </Button> */}
              <Menu
                mode="inline"
                defaultSelectedKeys={["231"]}
                openKeys={stateOpenKeys}
                onOpenChange={onOpenChange}
                onClick={(e) => navigationclick(e)}
                style={{
                  // width: 256,
                  background: "#000066 !important",
                  // background: "red"
                }}
                items={items}
                // inlineCollapsed={collapsed}
              />
            </div>
          </nav>
        </div>
      </aside>
    </div>
  );
};
export default Menu_Item;
