import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import {
  brandPost,
  brandImages,
  validatebrandSlugUrl,
  removeBrandImages,
} from "../../../redux/brand/brandSlice";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useToasts } from "react-toast-notifications";
import { TextField } from "@mui/material";

const AddBrand = () => {
  const { desktopimage, imageLoading, mobileimage } = useSelector(
    (store) => store.brand
  );
  const { superCatTotal } = useSelector((store) => store.superCategory);

  const { addToast } = useToasts();

  const [brand, setBrand] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [show, setShow] = useState(Boolean(1));
  const [supercat, setSupercat] = useState("");
  const [supercatid, setSupercatid] = useState("");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");

  const [buttonPress, setbuttonPress] = useState(false);
  const [buttonLoading, setbuttonLoading] = useState(false);
  const [supcategroyError, setsupcategroyError] = useState("");
  const [slugUrlError, setSlugUrlError] = useState("");
  const [imageError, setimageError] = useState("");
  useEffect(() => {
    if (buttonPress === true) {
      if (supercatid === "") {
        setsupcategroyError("Required");
      } else {
        setsupcategroyError("");
      }
      if (checkslugUrl === "") {
        setSlugUrlError("Required");
      } else {
        setSlugUrlError("");
      }
      if (mobileimage === "" || desktopimage === "") {
        setimageError("Required");
      } else {
        setimageError("");
      }
    }
  }, [buttonPress, supercatid, checkslugUrl, mobileimage, desktopimage]);

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setBrand(value);
    setCheckSlugUrl("");
    const brandSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(brandSlug);

    if (brandSlug !== "") {
      let responce = await dispatch(validatebrandSlugUrl(brandSlug));
      if (responce.payload.success) {
        setError("Brand alredy exist");
        setErrorcolor("red");
      } else if (brandSlug.length <= 2) {
        setErrorcolor("red");
        setError("minimum 3 letters");
        setCheckSlugUrl("");
      } else if (brandSlug.length >= 3) {
        setErrorcolor(" ");
        setError("");
        setCheckSlugUrl(brandSlug);
      }
    }
  };
  const dispatch = useDispatch();

  const selectsuperCategory = (evt) => {
    const { value } = evt.target;
    setSupercatid(value);
    const textValue = superCatTotal.find((indCat) =>
      indCat._id === evt.target.value
    )
    if (textValue) {
      setSupercat(textValue.name);
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setbuttonPress(true);
    setbuttonLoading(true);
    if (supercatid === "") {
      setsupcategroyError("Required");
    } else {
      setsupcategroyError("");
    }
    if (checkslugUrl === "") {
      setSlugUrlError("Required");
    } else {
      setSlugUrlError("");
    }
    if (mobileimage === "" || desktopimage === "") {
      setimageError("Required");
    } else {
      setimageError("");
    }
    if (
      checkslugUrl !== "" &&
      supercatid !== "" &&
      desktopimage !== "" &&
      mobileimage !== ""
    ) {
      const formData = {
        name: brand.charAt(0).toUpperCase() + brand.slice(1).toLowerCase(),
        slugUrl: checkslugUrl,
        desktopImage: desktopimage,
        mobileImage: mobileimage,
        superCategory: supercat,
        superCategoryId: supercatid,
        show: show,
      };
      const data = await dispatch(brandPost(formData));
      if (data.payload.success) {
        alert("Brand Added")
        addToast("Brand Added", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
          placement: "bottom-right",
          style: { zIndex: 9999999999 },
        });
        setbuttonPress(false);
        setBrand("");
        setSlugUrl("");
        setSupercatid("");
        setCheckSlugUrl("");
        setsupcategroyError("");
        setSlugUrlError("");
        setimageError("");
        dispatch(removeBrandImages());

      } else {
        setbuttonLoading(false);
      }
    } else {
      setbuttonLoading(false);
    }
  };

  const imageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(brandImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Add Brand</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Add Brand</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md >
                      <div className="space-y-1">
                        {/* <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: '700', color: 'rgb(0, 0, 102)', fontSize: '22px' }}
                        >
                          Super Category
                        </label> */}
                        <FormControl fullWidth className="textfield">
                          <InputLabel id="demo-simple-Case-Type" className="inputcenter">Super Category</InputLabel>
                          <Select
                            labelId="demo-simple-Case-Type"
                            id="demo-simple-select"
                            // value={superCatTotal._id}
                            value={supercatid}
                            label="Super Category"
                            onChange={(e) => selectsuperCategory(e)}
                            style={{ height: '38px' }}
                            inputProps={{ style: { textAlign: 'center' } }}
                            className={supcategroyError !== "" ? "error_class" : ""}
                          >
                            {superCatTotal.map((test, index) =>
                              <MenuItem value={test._id} key={index} selected={supercatid === test._id}  >{test.name}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </Col>
                    <Col md>
                      <div className="space-y-1">
                        {/* <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: '500', color: 'rgb(0, 0, 102)' }}
                        >
                          Name *
                        </label> */}
                        <div className="textfieldcontainer">
                          <TextField
                            label="Enter Name"
                            variant="outlined"
                            className={slugUrlError !== "" ? "error_class" : ""}
                            style={{ width: '100%' }}
                            type="text"
                            id="text"
                            value={brand}
                            onChange={(e) => verifyslugurl(e)}
                          // className={classes.textfield}
                          />
                        </div>
                      </div>
                    </Col>

                  </Row>
                  <Row className="g-2 m-2">
                    <Col md>
                      <Form.Label style={{ color: "rgb(0, 0, 102)" }}>Brand Thumbnail Image</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={imageChange}
                        className={imageError !== "" ? "error_class" : ""}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 480px * 360px
                      </p>
                      <div>
                        {imageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={desktopimage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                    <Col md>
                    </Col>

                  </Row>

                  <Row className="g-2  m-2">
                    <Col className="d-flex ">
                      <div className="me-4">
                        <input
                          type="checkbox"
                          className="me-1"
                          value="show"
                          checked={show === true}
                          onChange={(e) => {
                            setShow(e.target.checked);
                          }}
                        />
                        <Form.Label>Show</Form.Label>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  {buttonLoading ? (
                    <button className="btn btn-primary" style={{ background: "#000066", borderColor: "#000066" }}>Add Brand</button>
                  ) : (
                    <button className="btn btn-primary" type="submit" style={{ background: "#000066", borderColor: "#000066" }}>
                      Add Brand
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddBrand;
