import React, { useEffect } from "react";
import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { read, utils } from "xlsx";
import {
  productPost,
  validateProductSlugUrl,
} from "../../../redux/product/productSlice";

const ClothingBulkUploade = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [uploadedProductName, setUploadedProductName] = useState("");
  const handlefile = async (e) => {
    const file = e.target.files[0];
    const f = await file.arrayBuffer();
    const wb = read(f);
    const worksheetname = wb.SheetNames[0];
    const worksheet = wb.Sheets[worksheetname];
    const datas = utils.sheet_to_json(worksheet);
    setData(datas);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    let numbers = 0;
    for (let i in data) {
      let myrow = data[i];
      const Discount = Math.round(
        ((Number(myrow.Mrp) - Number(myrow.Price)) * 100) / Number(myrow.Mrp)
      );
      const formData = {
        name: myrow.ItemName,
        slugUrl: myrow.SlugUrl,
        superCategoryId: "660bfc7e6403b203c0a2a8a6",
        superCategory: "Clothing",
        category: "Men",
        categoryId: "661a74380e9ead508d47d363",
        SubCategory: "Fashion accessories",
        SubCategoryId: "661a7da50e9ead508d47d551",
        CategoryTag: "Wallets",
        CategoryTagId: "6637baae90b2cef8a01bcb1a",
        PrimeryTag: "Fashion accessories",
        PrimeryTagId: "661e45b4b0d14c25fb78e2db",
        brand: myrow.Brand,
        CountryofOrigin: "India",
        metaTitle: myrow.Description,
        metaKeyword: myrow.Description,
        metaDesc: myrow.Description,
        multi: false,
        multiVender: true,
        shopName: "EWSHOPPING",
        shopNumber: "8447282606",
        shopEmail: "info@ewshopping.com",
        shopId: "EWS100001",
        productDetails: "",
        NumberofItems: "1",
        packageContainer: "",
        designDetails: [
          {
            designImage: "",
            designHeader: "",
            designContent:
              "Maroon and Navy Blue checked casual shirt, has a spread collar, long sleeves, button placket, curved hem, and 1 patch pocket",
          },
        ],
        SizeFit: ["Regular Fit", "The model (height 6') is wearing a size 40"],
        MaterialCare: ["Material: 100% Cotton", "Machine Wash"],
        clothing: {
          DesignDetails: "",
          topDesign: "",
          bottomDesign: "",
          SizeAndFit: "",
          MaterialAndCare: "",
          SleeveLength: "Long Sleeves",
          SleeveStyling: "",
          Shape: "",
          Neck: "",
          PrintorPatternType: "Other Checks",
          DesignStyling: "",
          Length: "Regular",
          Hemline: "Curved",
          WeavePattern: "Regular",
          WeaveType: "",
          Occasion: "Casual",
          CompleteTheLook: "Expand your collection of casual staples with this hip shirt from Roadster. You'll love putting this maroon piece on with some trainers and chinos for the perfect outdoor activity with your friends.",
          Type: "",
          Transparency: "Opaque",
          MainTrend: "New Basics",
          Ornamentation: "",
          Border: "",
          DupattaBorder: "",
          blouseBorder: "",
          Blouse: "",
          Fabric: "Cotton",
          BlouseFabric: "",
          dupattaFabric: "",
          BottomType: "",
          BottomPattern: "",
          TopPattern: "",
          Dupatta: "",
          DupattaPattern: "",
          topClosure: "Zip",
          washcare: "",
          fit: "Regular Fit",
          patternCoverage: "",
          multipackset: "",
          sustainable: "",
          Collar: "Spread Collar",
          NumberofPockets: "1",
          SlitDetail: "",
          distress: "",
          waistRise: "",
          fade: "",
          shade: "",
          strech: "",
          reversible: "",
          waistband: "",
          BottomFabric: "",
          BottomClosure: "",
          topStitch: "",
          bottomStitch: "",
          BrandFitName: "",
          Placket: "Button Placket",
          PlacketLength: "Full",
          Placket_type: "",
          cuff: "Button",
          surfaces_styling: "Chest Pocket",
          features: "",
          border_Fabric: "",
          tyepe_of_distress: "",
          fly_Type: "",
          assorted: "",
          knighr_or_woven: "",
          bottom_length: "",
          bottom_border_fabric: "",
          type_of_pleat: "",
          front_Style: "",
          padding: "",
          seam: "",
          colourfamily: "",
          hexa_Code: "",
          finish: "",
          formulation: "",
          preferance: "",
          concern: "",
          key_ingredient: "",
          skin_type: "",
          stone_Type: "",
          puriety: "",
        },
        packSize: [
          {
            packSizeName: "Red",
            PsPrime: true,
            enabled: false,
            OutOfStack: true,
            thumbnail: myrow.thumbnail,
            slider: [myrow.thumbnail, myrow.thumbnail],
            PackSizeOptions: [
              {
                packSizeOptionName: "xl",
                PsnPrime: true,
                enabled: false,
                Mrp: myrow.Mrp,
                Price: myrow.Price,
                OutOfStack: false,
                Discount: myrow.Discount,
              },
            ],
          },
        ],
      };
      // console.log(formData, "formData");
      try {
        const verify_url = await dispatch(
          validateProductSlugUrl(formData.slugUrl)
        );
        if (verify_url.payload.success === false) {
          const product_uploade = await dispatch(productPost(formData));
     
          if (product_uploade.payload.success) {
            // numbers = numbers + 1;
            // console.log(numbers, "numbers");
            setUploadedProductName(`  ${myrow.ItemName}  upload successfull`);
          }
        }
      } catch (error) {}
    }
  };

  return (
    <div className="content-wrapper">
      <div className="employeesContainer">
        <form onSubmit={handlesubmit}>
          <div className="card m-4">
            <div className="card-footer">
              <h5>Clothing Product Bulk Upload</h5>
            </div>
            <div className="card-body">
              <h6 className="ps-3">Clothing Product Excel File *</h6>
              <Row className="g-2  m-2">
                <Col md>
                  <Form.Label htmlFor="disabledTextInput"></Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="enter name....."
                    onChange={(e) => handlefile(e)}
                  />
                </Col>
                <Col md>
                  <button
                    className="btn btn-primary me-2 mt-4 ml-5 "
                    type="submit"
                  >
                    Clothing Product Bulk Upload
                  </button>
                </Col>
              </Row>
              <p style={{ color: "green" }} className="mt-2">
                {uploadedProductName}
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ClothingBulkUploade;
