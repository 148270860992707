import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import {
  validateSlugUrl,
  categoryImages,
  updateCatDeskimage,
  updateCatMobileImage,
  categoryUpdate,
} from "../../../../redux/category/categorySlice";
import { useToasts } from "react-toast-notifications";
import { useNavigate, useParams } from "react-router-dom";
import { Baseurl } from "../../../../config/BaseUrl";
import axios from "axios";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

const UpdateCategory = () => {
  const {
    mobileimage,
    desktopimage,
    isCatmobileImageLoading,
    isDeskImageLoading,
    checkSlugurl,
  } = useSelector((store) => store.category);
  const { superCatShow } = useSelector((store) => store.superCategory);
  const { fieldShow } = useSelector((store) => store.field);


  const { addToast } = useToasts();
  const navigate = useNavigate();
  const params = useParams();
  const [supcategroyError, setsupcategroyError] = useState("");
  const [categroy, setCategroy] = useState("");
  const [slugUrlError, setSlugUrlError] = useState("");
  const [imageError, setimageError] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [supercat, setSupercat] = useState("");
  const [supercatid, setSupercatid] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const [result, setResult] = useState([]);
  const [buttonPress, setbuttonPress] = useState(false);
  const [buttonLoading, setbuttonLoading] = useState(false);
  const [current_id, setCurrent_id] = useState("");
  const [fieldBySupcatShow, setfieldBySupcatShow] = useState([]);
  const [selectedfield, setselectedfield] = useState([]);
  const [distinctfield, setdistinctfield] = useState([]);
  const [fieldError, setfieldError] = useState("");
  useEffect(() => {
    const getCategory = async () => {
      const url = `${Baseurl}/api/v1/category/slugurl/${params.category_url}`;
      const resp = await axios.get(url);
      const findCategory = resp.data.category;
      setCurrent_id(findCategory._id);
      setMetatitle(findCategory.metaTitle);
      setMetakeyword(findCategory.metaKeyword);
      setMetadesc(findCategory.metaDesc);
      setSlugUrl(findCategory.slugUrl);
      setCheckSlugUrl(findCategory.slugUrl);
      setCategroy(findCategory.name);
      setSupercat(findCategory.superCategory);
      setSupercatid(findCategory.superCategoryId);
      dispatch(updateCatDeskimage(findCategory.desktopImage));
      dispatch(updateCatMobileImage(findCategory.mobileImage));
      const fildbysupercat = fieldShow.filter(
        (cat) => cat.superCategoryId === findCategory.superCategoryId
      );
      setfieldBySupcatShow(fildbysupercat);
    };
    getCategory();
  }, [params.category_url, fieldShow]);

  useEffect(() => {
    if (superCatShow.length === 1) {
      setSupercat(superCatShow[0].name);
      setSupercatid(superCatShow[0]._id);
    }
  }, [superCatShow]);
  useEffect(() => {
    if (buttonPress === true) {
      if (supercatid === "") {
        setsupcategroyError("Required");
      } else {
        setsupcategroyError("");
      }
      if (checkslugUrl === "") {
        setSlugUrlError("Required");
      } else {
        setSlugUrlError("");
      }
      if (mobileimage === "" && desktopimage === "") {
        setimageError("Required");
      } else {
        setimageError("");
      }
      if (selectedfield.length === 0) {
        setfieldError("Plesae select at least one field");
      } else {
        setfieldError("");
      }
    }
  }, [
    buttonPress,
    supercatid,
    checkslugUrl,
    mobileimage,
    desktopimage,
    selectedfield,
  ]);


  const handleFielSelectPress = (evt) => {
    if (selectedfield.includes(evt.target.value)) {
      setselectedfield(
        selectedfield.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setselectedfield((selectedfield) => [...selectedfield, evt.target.value]);
    }
  };

  const handleFielSelectHandlePress = (e, evt) => {
    if (selectedfield.includes(evt)) {
      setselectedfield(
        selectedfield.filter((e) => {
          return e !== evt;
        })
      );
    } else {
      setselectedfield((selectedfield) => [...selectedfield, evt]);
    }
  };

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setCategroy(value);
    setMetatitle(value);
    setMetakeyword(value);
    setMetadesc(value);
    // setErrorcolor("green");
    setCheckSlugUrl("");
    // setError("category available");
    const catSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(catSlug);

    if (catSlug !== "") {
      let responce = await dispatch(validateSlugUrl(catSlug));
      if (responce.payload.success) {
        setError("category alredy exist");
        setErrorcolor("red");
      } else if (catSlug.length <= 2) {
        setErrorcolor("red");
        setError("minimum 3 letters");
        setCheckSlugUrl("");
      } else if (catSlug.length >= 3) {
        setErrorcolor("");
        setError("");
        setCheckSlugUrl(catSlug);
      }
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setbuttonPress(true);
    setbuttonLoading(true);
    if (supercatid === "") {
      setsupcategroyError("Required");
    } else {
      setsupcategroyError("");
    }
    if (checkslugUrl === "") {
      setSlugUrlError("Required");
    } else {
      setSlugUrlError("");
    }
    if (mobileimage === "" && desktopimage === "") {
      setimageError("Required");
    } else {
      setimageError("");
    }

    if (selectedfield.length === 0) {
      setfieldError("Plesae select at least one field");
    } else {
      setfieldError("");
    }
    if (
      checkslugUrl !== "" &&
      supercatid !== "" &&
      mobileimage !== "" &&
      desktopimage !== "" &&
      selectedfield.length > 0
    ) {
      let fieldarray = [];
      let finalfieldarray = [];
      for (let i = 0; i < selectedfield.length; i++) {
        let element = selectedfield[i];

        let obje = { [element]: "", field_Name: element, status: true };
        fieldarray = [...fieldarray, obje];
      }
      fieldarray = fieldarray;
      let unselectedData = [];
      const notMatched = distinctfield.filter(
        (item) => !selectedfield.includes(item)
      );
      for (let j = 0; j < notMatched.length; j++) {
        let element = notMatched[j];
        let obje = { [element]: "", field_Name: element, status: false };
        unselectedData = [...unselectedData, obje];
      }
      unselectedData = unselectedData;

      finalfieldarray = [...fieldarray, ...unselectedData];

      const formData = {
        name: categroy,
        slugUrl: checkslugUrl,
        superCategoryId: supercatid,
        superCategory: supercat,
        metaTitle: metatitle,
        metaKeyword: metakeyword,
        metaDesc: metadesc,
        mobileImage: mobileimage,
        desktopImage: desktopimage,
        catid: current_id,
        selectedFields: finalfieldarray,
      };
      const data = await dispatch(categoryUpdate(formData));
      if (data.payload.success) {
        addToast("Category updated", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
          placement: "bottom-right",
          style: { zIndex: 99999 },
        });
        setbuttonPress(false);
      } else {
        setbuttonLoading(false);
      }
    } else {
      setbuttonLoading(false);
    }
  };

  const selectSuperCat = (evt) => {
    const { value } = evt.target;
    setSupercatid(value);
    const textValue = superCatShow.find((indSuper) =>
      indSuper._id === value
    )
    if (textValue) {
      setSupercat(textValue.name);
    }
  };

  const dispatch = useDispatch();

  const categoryImageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(categoryImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card me-2 ms-2">
                <div className="card-footer">
                  <h5>Updated Category</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2 ">
                    {superCatShow.length > 1 && (
                      <Col md>
                        <Form.Label htmlFor="disabledTextInput">
                          Super Category
                        </Form.Label>

                        <Form.Select
                          aria-label="Floating label select example"
                          onChange={(e) => {
                            selectSuperCat(e);
                          }}
                          value={superCatShow._id}
                          name={superCatShow.name}
                          className={
                            supcategroyError !== "" ? "error_class" : ""
                          }
                        >
                          <option selected hidden>
                            {"Select Super Category"}
                          </option>
                          {superCatShow.map((data) => (
                            <option
                              key={data._id}
                              value={data._id}
                              name={data.name}
                              selected={supercatid === data._id}
                            >
                              {data.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    )}
                    <Col md>
                      <div className="space-y-1">
                        <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: '500', color: 'rgb(0, 0, 102)' }}
                        >
                          Category Name
                        </label>
                        <div className="textfieldcontainer">
                          <TextField
                            label="Enter  Category "
                            variant="outlined"
                            style={{ width: '100%', textTransform: "capitalize" }}
                            type="text"
                            id="text"
                            value={categroy}
                            onChange={(e) => verifyslugurl(e)}
                            className={slugUrlError !== "" ? "error_class" : ""}
                          // className={classes.textfield}
                          />
                        </div>
                      </div>
                    </Col>


                  </Row>

                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Desktop Image</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={categoryImageChange}
                        className={imageError !== "" ? "error_class" : ""}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 500px * 500px
                      </p>
                      <div>
                        {isDeskImageLoading ? (
                          <div></div>
                        ) : (
                          <img src={desktopimage} height={100} alt="img" />
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <div className="space-y-1">
                        <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: '500', color: '#000066' }}
                        >
                          Meta Descriptrion
                        </label>
                        <div className="textfieldcontainer">
                          <TextField
                            label="Enter Meta Descriptrion"
                            variant="outlined"
                            style={{ width: "100%" }}
                            type="text"
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            // defaultValue="Default Value"
                            value={metadesc}
                            onChange={(e) => setMetadesc(e.target.value)}
                          // className={classes.textfield}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row className="g-2  m-2">


                    <Col>
                      <div className="space-y-1">
                        {/* <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: '500', color: '#000066' }}
                        >
                          Meta Title
                        </label> */}
                        <div className="textfieldcontainer">
                          <TextField
                            label="Enter Meta Title"
                            variant="outlined"
                            style={{ width: "100%" }}
                            type="text"
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            // defaultValue="Default Value"
                            value={metatitle}
                            onChange={(e) => setMetatitle(e.target.value)}
                          // className={classes.textfield}
                          />
                        </div>
                      </div>

                    </Col>

                    <Col>
                      <div className="space-y-1">
                        {/* <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: '500', color: '#000066' }}
                        >
                          Meta Keyword
                        </label> */}
                        <div className="textfieldcontainer">
                          <TextField
                            label="Enter Meta Keyword "
                            variant="outlined"
                            style={{ width: "100%" }}
                            type="text"
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            // defaultValue="Default Value"
                            value={metakeyword}
                            onChange={(e) => setMetakeyword(e.target.value)}
                          // className={classes.textfield}
                          />
                        </div>
                      </div>


                    </Col>

                  </Row>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      marginTop: "0.2vh",
                    }}
                  >
                    {fieldBySupcatShow.map((data, index) => (
                      <div
                        className="me-4 d-flex"
                        style={{ width: "22%" }}
                        key={index}
                      >
                        <input
                          type="checkbox"
                          className="me-1"
                          value={data.name}
                          checked={selectedfield.includes(data.name)}
                          onChange={(e) => handleFielSelectPress(e, data.name)}
                          style={{ cursor: "pointer" }}
                        />
                        <Form.Label
                          className="mt-2 ml-2"
                          onClick={(e) =>
                            handleFielSelectHandlePress(e, data.name)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {index + 1}) {data.name}
                        </Form.Label>
                      </div>
                    ))}
                  </div>
                  <Row className="g-2  m-2">
                    <p style={{ color: "red" }}> {fieldError}</p>
                  </Row>
                </div>
                <div className="card-footer ">
                  {buttonLoading ? (
                    <button className="btn btn-primary" style={{ background: "#000066", borderColor: "#000066" }}>Update Category</button>
                  ) : (
                    <button className="btn btn-primary" type="submit" style={{ background: "#000066", borderColor: "#000066" }}>
                      Update Category
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default UpdateCategory;
