import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  threeSliderstotal: localStorage.getItem("threeSliderstotal")
    ? JSON.parse(localStorage.getItem("threeSliderstotal")).sort((a, b) =>
        a.createdAt > b.createdAt ? 1 : -1
      )
    : [],
  mobileimage: "",
  desktopimage: "",
  ismobileImageLoading: true,
  isDeskImageLoading: true,
  isThreeSliderLoading: true,
  deleteSliderLoading: true,
};

export const sliderPost = createAsyncThunk(
  "slider/sliderpost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/threeslider/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Slider Not create");
    }
  }
);

export const getThreeSlider = createAsyncThunk(
  "slider/getThreeSlider",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/threeslider/all`;
      const resp = await axios(url);
      return resp.data.threeSliders;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const sliderImages = createAsyncThunk(
  "slider/sliderImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/threeslider/threesliderimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Slider not create");
    }
  }
);

export const validateSlugUrl = createAsyncThunk(
  "slider/validateSlugUrl",
  async (slugurl, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/threeslider/verify-slugurl/${slugurl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const threeSliderDelete = createAsyncThunk(
  "threeSlider/threeSliderDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/threeslider/delete/${id}`;
      const resp = await axios.delete(url, id, config);
      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("threeslider Not create");
    }
  }
);

const ThreeSliderSlice = createSlice({
  name: "threeSlider",
  initialState,
  reducers: {
    resetSlider: (state) => {
      state.desktopimage = "";
      state.mobileimage = "";
      state.ismobileImageLoading = true;
      state.isDeskImageLoading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getThreeSlider.pending, (state) => {
        state.isThreeSliderLoading = true;
      })
      .addCase(getThreeSlider.fulfilled, (state, action) => {
        // state.threeSliderstotal = action.payload.sort((a, b) =>
        //   a.createdAt > b.createdAt ? 1 : -1
        // );
        state.threeSliderstotal = action.payload;
        localStorage.setItem(
          "threeSliderstotal",
          JSON.stringify(state.threeSliderstotal)
        );
        state.isThreeSliderLoading = false;
      })
      .addCase(getThreeSlider.rejected, (state) => {
        state.isThreeSliderLoading = true;
      });
    builder
      .addCase(sliderImages.pending, (state) => {
        state.isDeskImageLoading = true;
      })
      .addCase(sliderImages.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.desktopimage = action.payload.desktopImages;
          state.mobileimage = action.payload.mobileimages;
        }
        state.isDeskImageLoading = false;
        state.ismobileImageLoading = false;
      })
      .addCase(sliderImages.rejected, (state) => {
        state.isDeskImageLoading = true;
      });
    builder
      .addCase(threeSliderDelete.pending, (state) => {
        state.deleteSliderLoading = true;
      })
      .addCase(threeSliderDelete.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.threeSliderstotal = state.threeSliderstotal
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .filter((slider) => slider._id !== action.payload.id);

          localStorage.setItem(
            "threeSliderstotal",
            JSON.stringify(state.threeSliderstotal)
          );
        }
        state.deleteSliderLoading = false;
      })
      .addCase(threeSliderDelete.rejected, (state) => {
        state.deleteSliderLoading = true;
      });
  },
});

export const { resetSlider } = ThreeSliderSlice.actions;
export default ThreeSliderSlice.reducer;
